import styled, { css } from 'styled-components';
import iconCheck from '../../../assets/svg/common/checkmark.svg';
import iconCancel from '../../../assets/svg/common/cancel.svg';
import iconMinus from '../../../assets/svg/common/minus.svg';
import iconPlus from '../../../assets/svg/common/plus.svg';
import iconSettings from '../../../assets/svg/common/settings.svg';
import iconTrash from '../../../assets/svg/common/trash.svg';

const groupBlue = [ "check", "plus", "settings" ];
const groupRed = [ "cancel", "trash" ];
const groupYellow = [ "minus" ];

const getColorIcon = (theme, icon, state, defaultColor = '#fff') => {
  switch (state) {
  case 'default':
    return theme.colors.lineGrey;
  case 'hover': 
    if (groupBlue.includes(icon))
      return theme.colors.link;
    else if (groupRed.includes(icon)) 
      return theme.colors.alerts.red;
    else if (groupYellow.includes(icon))
      return theme.colors.alerts.orange;
    else 
      return defaultColor;
  case 'active':
    if (groupBlue.includes(icon))
      return theme.colors.text.blueMore;
    else if (groupRed.includes(icon)) 
      return theme.colors.error;
    else if (groupYellow.includes(icon))
      return theme.colors.orangeMore;
    else 
      return defaultColor;
  case 'disabled':
    return theme.colors.text.grey4;
  default:
    return defaultColor;
  }
};

const getIcon = (icon, state, defaultColor = '#fff') => {
  switch (icon) {
  case 'check':
    return iconCheck;
  case 'cancel':
    return iconCancel;
  case 'minus':
    return iconMinus;
  case 'plus':
    return iconPlus;
  case 'settings':
    return iconSettings;
  case 'trash':
    return iconTrash;  
  default:
    return null;
  }
};

export const StyledButton = styled.button`
  padding: 5px 8px;
  outline: none !important;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background.white};
  border: none;
  background-color: transparent;

  ${props => props.withBorder && css`
    border: 2px solid ${(props) => props.theme.colors.lineGrey};
    border-radius: 6px;
  `}; 

  ${props => !props.withBorder && css`
    border: none;
    background-color: transparent;
  `};
  
  .text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    color: ${(props) => props.theme.colors.text.darkGrey};
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.lessDarkGrey};
    font-size: ${(props) => props.theme.fontSizes.lg};
    width: 20px;
  }

  .icon {
    height: 21px;
    width: 100%;
    padding: 0;
    padding-bottom: 92%;
    background-color: ${(props) => getColorIcon(props.theme, props.icon, 'default')};
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url(${(props) => getIcon(props.icon)});
  }

  &:hover {
    ${props => props.withBorder && css`
        border-color: ${(props) => getColorIcon(props.theme, props.icon, 'hover')};
    `};
    .icon {
      background-color: ${(props) => getColorIcon(props.theme, props.icon, 'hover')};
    }
  }

  &:active {
    ${props => props.withBorder && css`
        border-color: ${(props) => getColorIcon(props.theme, props.icon, 'active')};
    `};
    .icon {
      background-color: ${(props) => getColorIcon(props.theme, props.icon, 'active')};
      
    }
  }

  &:disabled {
    ${props => props.withBorder && css`
        border-color: ${(props) => getColorIcon(props.theme, props.icon, 'disabled')};
    `};
    .icon {
      background-color: ${(props) => getColorIcon(props.theme, props.icon, 'disabled')};
    }
    .text {
      color: ${(props) => props.theme.colors.secondaryDisabled};
    }
  }
`;
