import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import {
  CreateContactContainer,
  CreateContractBox,
  CreateContractTitle,
} from '../styles/styled-components/CreateContract';
import noSpacesIcon from '../../assets/svg/spaces/no-spaces.svg';

const CreateContract = () => {
  return (
    <CreateContactContainer>
      <CreateContractBox>
        <img src={noSpacesIcon} alt='' className='mb-3' />
        <CreateContractTitle className='mt-2'>
          <Trans i18nKey='create_contract_header'>
            Sign a new contract, <br /> to create a new space
          </Trans>
        </CreateContractTitle>
        <p className='font-sm'>
          <Trans i18nKey='create_contract_description'>
            You don't have any contracts <br /> <Link to='/new_contract'>click</Link> to sign a new contract.
          </Trans>
        </p>
      </CreateContractBox>
    </CreateContactContainer>
  );
};

export default CreateContract;
