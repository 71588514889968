/**
 * __useAuthorizationHeader examples:
 * - axiosInstance.get('/endpoint') - 
 *     __useAuthorizationHeader is set to 'always' by default, so token is attached to the header if login is possible, 
 *     if api returns 401 and login is possible response interceptor tries to obtain new token.
 *  
 * - axiosInstance.get('/endpoint', { __useAuthorizationHeader: 'onlyForLoggedIn' }) - 
 *     token is attached to request header if user is logged in, if api returns 401, rejected promise is returned.
 * 
 * Custom config options:
 * - __useAuthorizationHeader: string (default: 'always') ('always', 'onlyForLoggedIn', 'never'))
 * - __retryCount:  object (default: { '500': 0, '400': -1 })
 * - __silentFor: array of objects - turn off notifications for specific errors
 * - __forceSilent: boolean - turn off all notifications
 * - __refetchToken: boolean (default: false)
 *
 * Examples:
 *  * 1. GET
 * axiosInstance.get(
 *   '/endpoint',
 *  {
 *   __useAuthorizationHeader: 'never',
 *   __retryCount: { '500': 3, '400': -1 },
 *   __silentFor: [
 *   { data: { message: 'error' }, status: 404, statusText: 'Not found' }
 *   ],
 *   ...<other_options> }
 * );
 *
 * 2. POST
 * axiosInstance.post(
 *   '/endpoint',
 *   body,
 *   {
 *     __retryCount: { },
 *     __silentFor: [{ status: 409 }],
 *     ...<other_options>
 *    }
 *  );
 */
import axios from 'axios';
import _ from 'lodash';

import AccessTokenProvider from './accessTokenProvider';
import * as notify from '../notify';
import i18n from '../i18nConfigProvider';
import { getLogger } from '../logger';
import { __env } from '@envloader';
import { checkIfLoginIsPossible } from '../authUtils';
import { UseAuthorizationHeader } from '../../constants';

let store;

const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  __useAuthorizationHeader: __env.USE_AUTHORIZATION_HEADER || UseAuthorizationHeader.ALWAYS,
  __retryCount: { '500': 0, '400': -1 },
  __silentFor: [],
  __forceSilent: false,
  __refetchToken: false,
});

export const accessTokenProvider = (function () {
  let instance;

  return {
    getInstance: function () {
      if (instance == null) {
        instance = new AccessTokenProvider();
      }

      return instance;
    }
  };
})();

axiosInstance.interceptors.request.use(async (config) => {
  const isLoggedIn = store.getState().login.get('loginData');
  if (shouldAttachToken(config, isLoggedIn)) {
    const token = await accessTokenProvider.getInstance().obtainToken(config.__refetchToken);
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response) {    
    const config = error.config;
    const isLoggedIn = store.getState().login.get('loginData');

    if (error.response.status === 401) {
      if (shouldAttachToken(config, isLoggedIn)) {
        const token = await accessTokenProvider.getInstance().obtainToken(true);
        config.headers.Authorization = `Bearer ${token}`;
        return new Promise((resolve) => {
          setTimeout(resolve.bind(null, {}), 1000 * config.__retryCount['400']);
        }).then(() => {
          config.__retryCount['400'] += 1;
          return axiosInstance(config);
        });
      }
      else {
        notify.error(i18n.t('common:error'), error.message);
        return Promise.reject(error);
      }
    }
    else if (
      error.response.status >= 500 &&
      error.response.status <= 599 &&
      config.__retryCount['500'] < __env.RETRY_REQUEST_INTERVALS.length &&
      (config.method === 'get' || config.method === 'head')
    ) {
      return new Promise((resolve) => {
        setTimeout((resolve.bind(null, {})), __env.RETRY_REQUEST_INTERVALS[config.__retryCount["500"]]);
      }).then(() => {
        config.__retryCount['500'] += 1;
        return axiosInstance(config);
      });
    }
    else if (
      error.response.status >= 500 &&
      error.response.status <= 599 &&
      config.__retryCount['500'] < __env.RETRY_REQUEST_INTERVALS.length
    ) {
      if (!isSilent(config, error.response,)) {
        notify.error(i18n.t('common:server_error'), error.message);
      }
      return Promise.reject(error);
    }
    else {
      if (!isSilent(config, error.response)) {
        notify.error(i18n.t('common:error'), error.message);
      }
      return Promise.reject(error);
    }
  }
  else {
    notify.error(i18n.t('common:error'), error.message);
    getLogger({ loggerName: 'axios-utils' }).error(error);
    return Promise.reject(error);
  }
});

const shouldAttachToken = (config, isLoggedIn) =>
  checkIfLoginIsPossible() &&
  (config.__useAuthorizationHeader === UseAuthorizationHeader.ALWAYS ||
    (config.__useAuthorizationHeader === UseAuthorizationHeader.ONLY_FOR_LOGGED_IN && isLoggedIn));

const isSilent = (config, errorResponse) => {
  const { __forceSilent, __silentFor } = config;

  if (__silentFor === 'all') return true;

  if (__forceSilent) return true;

  return __silentFor.some((condition) =>
    Object.keys(condition).every((field) =>
      _.isEqual(condition[field], errorResponse[field])
    )
  );
};

export { shouldAttachToken, injectStore, isSilent };
export default axiosInstance;
