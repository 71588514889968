import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Filters from './Filters.jsx';
import Search from './Search';
import Calendar from './Calendar.jsx';
import ActiveFiltes from './ActiveFilters.jsx';

const FiltersPanel = ({ settlements }) => {
  const uniqueSettlements = [ ...new Set(settlements?.map(el => el?.serviceName)) ];

  const [ isInputActive, setIsInputActive ] = useState(false);

  return (
    <Col className='px-0'>
      <Row className="pb-3 px-3">
        <Col className="pr-2 pl-0 d-flex w-100">
          <Filters uniqueSettlements={uniqueSettlements} isInputActive={isInputActive}/>
          <Search setIsInputActive={setIsInputActive}/>
        </Col>
        <Calendar />
      </Row>
      <ActiveFiltes />
    </Col>
  );
};

export default FiltersPanel;

FiltersPanel.propTypes = {
  settlements: PropTypes.array
};