import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Loading from '../../main/components/loading';
import AffiliationCategory from '../components/AffiliationCategory';
import { __env } from '../../envloader';
import useAxios from '../hooks/useAxios';
import { TileParentRow } from '../styles/styled-components/TileCard';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { compose } from 'redux';
const AffiliationCategories = () => {
  const { 
    data, 
    isLoading, 
    error 
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories`);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page_titles.new_affiliation')}</title>
      </Helmet>
      <section>
        <header className='mb-5'>
          <h3 className='mb-3 font-xl2 fonWeight-medium'>{t('affiliation_categories.header')}</h3>
          <p className='w-50 font-sm'>{t('affiliation_categories.description')}</p>
        </header>
        {isLoading ? (
          <Loading />
        ) : (
          error ? (
            <div className='py-2'>
              <Alert color='danger'>{t('affiliation_categories.api_error')}</Alert>
            </div>
          ) : (
            <TileParentRow>
              {data.items.map((contract) => <AffiliationCategory key={contract.id} affiliation={contract} />)}
            </TileParentRow>
          )
        )}
      </section>
    </>

  );
};

export default compose(
  ErrorBoundary((props) => props.t('affiliation_categories.error_boundary'))
)(AffiliationCategories);