import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../utils/notify';
import i18n from '../utils/i18nConfigProvider';
import { clearSS } from '../utils/sessionStorageProvider';

@ErrorBoundary(props => props.t('common:error_boundary.components.LogoutCallback'), false)
export default class LogoutCallback extends Component {

  componentDidMount() {
    //Clear all fields in localStorage
    clearSS();
    notify.info(i18n.t('common:logout_success_notification_title'), i18n.t('common:logout_success_notification_body'));
  }

  render() {
    return (
      <Redirect to="/"/>
    );
  }
}
