import { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { initialState } from '../reducers/useAxiosWithReduxReducer';
import { sendRequest } from '../actions/useAxiosWithReduxActions';

const selectJsState = createSelector(
  (state) => state.useAxios,
  (_, path) => path,
  (useAxios, path) => useAxios.get(path) ? 
    useAxios.get(path).toJS() : 
    initialState
);

const useAxiosWithRedux = (url, path, { method = 'get', data = null, params = null, sendOnInit = true, sendOnChange = true } = {}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => selectJsState(state, path));

  const sendOnInitRef = useRef((state && state.isLoading) ? false : sendOnInit);

  const fetchData = useCallback(() => {
    dispatch(sendRequest(path, { method: method, url: url, data: data, params: params }));
  }, [ path, method, url, data, params, dispatch ]);

  useEffect(() => {
    if (sendOnChange) {
      if (sendOnInitRef.current)
        fetchData();
      else
        sendOnInitRef.current = true;
    }
    else {
      if (sendOnInitRef.current)
        fetchData();
      else
        sendOnInitRef.current = false;
    }
  }, [ fetchData, sendOnChange ]);

  return { ...state, refetch: fetchData };
};

export default useAxiosWithRedux;