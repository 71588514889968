import axiosInstance from '../../main/utils/axios/axiosInstance';

export const openFile = (url) => {
  axiosInstance.get(url, { responseType: 'blob' })
    .then(res => {
      const file = new Blob([ res.data ], { type: res.data?.type });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    })
    .catch(err => alert('An error occurred: ' + err.message));
};