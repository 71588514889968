import { useCallback, useEffect } from 'react';

const usePopover = ({ ref, onClose, onUpdateCoords, entered }) => {
  const handleClose = useCallback((e) => {
    if (ref && !ref.current.contains(e.target)) {
      onClose();
    }
  }, [ onClose, ref ]);

  const handleKeyDown = useCallback((e) => {
    switch (e.keyCode) {
    // Enter 
    case 13:
      onClose();
      break;
    default:
      break;
    }
  }, [ onClose ]);

  useEffect(() => {
    if (entered) {
      window.addEventListener('click', handleClose);
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('click', handleClose);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [ handleClose, entered, handleKeyDown ]);

  useEffect(() => {
    window.addEventListener('resize', onUpdateCoords);
    return () => window.removeEventListener('resize', onUpdateCoords);
  }, [ onUpdateCoords ]);
};

export default usePopover;
