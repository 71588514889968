import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import SpaceRegulation from '../../../../assets/svg/regulations/spaceRegulation.svg';
import useAxios from '../../../hooks/useAxios';
import { __env } from '../../../../envloader';
import Loading from '../../../../main/components/loading';
import backendTranslation from './../../../utils/backendTranslation';

const Regimens = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoading: state.services.servicesList.get('isLoading'),
  });
  const { services, isLoading } = useSelector(selectDates);
  const {
    data: spaceUserOffersData
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${match.params.id}/user_offers`);

  const uniqueServicesId = [ ...new Set(services?.map(service => service?.parameters?.bpm_params_offer_id?.value)) ];
  const selectedOffers = spaceUserOffersData?.items
    .filter(offer => uniqueServicesId.includes(offer?.id) && offer?.regulations?.items?.length > 0 && offer?.regulations?.items )
    .slice(0, 3);

  if (!spaceUserOffersData || isLoading || !services) {
    return <Loading />;
  }

  return (
    <>
      <h5 className="mb-3">{t("nav_items.regimens")}</h5>
      <Card className="my-3">
        <CardBody className="p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                <img src={SpaceRegulation} alt="space regulation"/>
              </div>
              <div className="pl-3">
                <p className="m-0 font-xs">
                  {t("regimen.statistics_regulation_type")}
                </p>
                <div className="d-flex align-items-center flex-wrap">
                  <h4 className="font-md m-0">
                    {t("favorite.space")}
                  </h4>
                  {selectedOffers.length > 0 && selectedOffers.map(offer => (
                    <div className="d-flex align-items-center">
                      <div className='ml-1'>•</div>
                      <h4 className="font-md ml-1 m-0">{backendTranslation(offer?.offer__name)}</h4>
                    </div>
                  ))}
                  {selectedOffers.length > 3 && "..."}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Link to={`/spaces/${match.params.id}/regimens`} className="d-flex justify-content-end">
        {t("show")}
      </Link>
    </>
  );
};

export default Regimens;
