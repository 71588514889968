import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${({ size }) => size === 'sm' ? '22px' : '35px'};
  height: ${({ size }) => size === 'sm' ? '22px' : '35px'};
  ${({ xs }) => xs && css`
    width: 19px;
    height: 19px;
  `};
  background: ${({ checked, outline, theme }) => (checked && !outline) ? theme.colors.secondary : '#fff'};
  border-radius: 6px;
  border: 1px solid ${({ theme, checked, outline }) => (checked && !outline) ? theme.colors.secondary : theme.colors.secondaryDisabled};
  transition: all 150ms;

  ${HiddenCheckbox}:disabled + & {
    background: #F1F2F2;

    svg {
      color: ${({ theme }) => theme.colors.secondaryDisabled}
    }
  }

  svg {
    color: ${({ theme, outline }) => outline ? theme.colors.secondary : theme.colors.background.white};
    font-size: ${({ size, theme }) => size === 'sm' ? theme.fontSizes.xl : theme.fontSizes.xl3};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;