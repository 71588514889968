import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

export const AccordionHeader = styled.div`
  padding: 0.6rem 0.8rem;
  cursor: pointer;
  border-bottom: 0px !important;
`;

export const ChevronIcon = styled(FaChevronDown)`
  font-size: 1rem;
  transition: transform .5s ease;
  transform: ${(props) => props.open ? 'rotate(180deg)' : 'rotate(0)'};
  color: ${(props) => props.color === 'grey' ? props.theme.colors.text.grey4 : props.theme.colors.text.darkGrey};
`;

export const ChevronIconPermament = styled(FaChevronDown)`
  color: ${(props) => props.theme.colors.primary};
`;