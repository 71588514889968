import { UserRole } from '../constants/roles';
import { FilterValue } from '../constants/usersFilters';

export const filtersAreEmpty = (filters) => {
  for (let key in filters) {
    if (filters[key] !== FilterValue.ANY) {
      return false;
    }
  }
  return true;
};

export const mergeUsersWithRoles = (rolesResponse, usersResponse) => {
  let usersWithRoles = [];

  if (usersResponse.matching_count !== 0) {
    usersWithRoles = usersResponse.items.map(user => ({
      ...user,
      roles: rolesResponse.items
        .filter(role => role.user_id === user.id)
        .map(({ role }) => role)
    }));
  }

  return usersWithRoles;
};

export const filterUsers = (filters, allUsers) => {
  return allUsers
    .filter(user => {
      if (filters.financial_admin === FilterValue.YES) {
        return user.roles.includes(UserRole.FINANCIAL_ADMIN);
      }
      else if (filters.financial_admin === FilterValue.NO) {
        return !user.roles.includes(UserRole.FINANCIAL_ADMIN);
      }
      else {
        return true;
      }
    })
    .filter(user => {
      if (filters.end_user === FilterValue.YES) {
        return user.roles.includes(UserRole.END_USER);
      }
      else if (filters.end_user === FilterValue.NO) {
        return !user.roles.includes(UserRole.END_USER);
      }
      else {
        return true;
      }
    })
    .filter(user => {
      if (filters.user_admin === FilterValue.YES) {
        return user.roles.includes(UserRole.USER_ADMIN);
      }
      else if (filters.user_admin === FilterValue.NO) {
        return !user.roles.includes(UserRole.USER_ADMIN);
      }
      else {
        return true;
      }
    });
};

export const searchUsers = (searchFilter, allUsers) => {
  const filter = searchFilter.toLowerCase();
  return allUsers.filter(user => {
    if (
      user.user_name.toLowerCase().includes(filter) ||
      user.first_name.toLowerCase().includes(filter) ||
      user.surname.toLowerCase().includes(filter) ||
      user.email.toLowerCase().includes(filter)
    ) {
      return true;
    }
    else {
      return false;
    }
  });
};

export const hasLowerRole = (user1, user2) => {
  if (user1.roles.includes(UserRole.OWNER))
    return -1;
  else if (user2.roles.includes(UserRole.OWNER))
    return 1;
  else if (
    (user1.roles.includes(UserRole.FINANCIAL_ADMIN) || user1.roles.includes(UserRole.USER_ADMIN))
    && !user2.roles.includes(UserRole.FINANCIAL_ADMIN)
    && !user2.roles.includes(UserRole.USER_ADMIN)
  )
    return -1;
  else if (
    (user2.roles.includes(UserRole.FINANCIAL_ADMIN) || user2.roles.includes(UserRole.USER_ADMIN))
    && !user1.roles.includes(UserRole.FINANCIAL_ADMIN)
    && !user1.roles.includes(UserRole.USER_ADMIN)
  )
    return 1;
  else
    return 0;
};

export const getUsersCountByRole = (users, role) => {
  if (users.length) {
    return users.filter(({ roles }) => roles.includes(role)).length;
  }
  return 0;
};

export const paramsAreEmpty = (params) => {
  return Array.from(params.keys()).length === 0;
};

export const mapFiltersToParams = (filters) => {
  return Object.keys(filters).map(role => `${role}=${filters[role]}`).join('&');
};

export const hasOnlyAnyValues = (params) => 
  Array.from(params.keys()).every(param => params.get(param) === FilterValue.ANY);

export const anyValuesCount = (params) => 
  Array.from(params.keys()).filter(role => params.get(role) === FilterValue.ANY).length;
