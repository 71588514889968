import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { multiLangType } from '../../types';
import { Table, Th } from '../../styles/styled-components/Affiliations';
import SettlementsRow from './SettlementsRow';

const SettlementsTable = ({ settlements = [] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <Th>{t("settlements.table_head.date")}</Th>
            <Th>{t("settlements.table_head.billing_period")}</Th>
            <Th>{t("settlements.table_head.name")}</Th>
            <Th>{t("settlements.table_head.report")}</Th>
            <Th>{t("settlements.table_head.due_date")}</Th>
            <Th>{t("settlements.table_head.status")}</Th>
          </tr>
        </thead>
        <tbody>
          {settlements.map((item) => (
            <SettlementsRow
              key={item.id}
              settlement={item}
            />
          ))}
        </tbody>
      </Table>
      {settlements.length === 0 && (
        <div className="w-100 text-center">
          <h6>{t("settlements.empty")}</h6>
        </div>
      )}
    </>
  );
};

SettlementsTable.propTypes = {
  settlements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      files: PropTypes.string,
      name: multiLangType,
      serviceId: PropTypes.number,
      serviceName: PropTypes.string,
      state: PropTypes.string,
      creation_date: PropTypes.string,
      due_date: PropTypes.string,
      billing: PropTypes.shape({
        refusal_comment: PropTypes.string,
        refusal_reason: PropTypes.string,
        status: PropTypes.string,
        report: PropTypes.object,
      }),
    })
  ),
};

export default SettlementsTable;
