import React, { useState } from 'react';
import { ModalFooter, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  StyledBody,
  StyledButton,
  StyledIconButton,
} from '../../styles/styled-components/ReportModal';
import { ReactComponent as CrossIcon } from '../../../assets/svg/common/cross.svg';

const FileDeleteModal = ({ removeFile }) => {
  const [ deleteFileModal, setDeleteFileModal ] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    setDeleteFileModal(false);
  };

  return (
    <>
      <StyledIconButton
        onClick={(e) => {
          e.preventDefault();
          setDeleteFileModal(true);
        }}
      >
        <CrossIcon />
      </StyledIconButton>
      <Modal isOpen={deleteFileModal} toggle={close} size="sm">
        <StyledBody>{t("reports.delete_text")}</StyledBody>
        <ModalFooter className="justify-content-between">
          <StyledButton
            form="report-form"
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            {t("reports.cancel")}
          </StyledButton>
          <StyledButton
            form="report-form"
            color="danger"
            onClick={(e) => {
              e.preventDefault();
              removeFile();
              close();
            }}
          >
            {t("reports.delete")}
          </StyledButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

FileDeleteModal.propTypes = {
  removeFile: PropTypes.func,
};

export default FileDeleteModal;
