import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import Offer from '../../../components/Offer';
import useCurrentSpace from '../../../hooks/useCurrentSpace';
import Loading from '../../../../main/components/loading';
import { useSpaceOffers } from '../../../hooks/useSpaceOffers';
import backendTranslation from '../../../utils/backendTranslation';
import { TileParentCol, TileCardShowAll, TileTitileLink } from '../../../styles/styled-components/TileCard';
import { TileParentRow } from '../../../styles/styled-components/TileCard';
import { BtnTextBlue } from '../../../styles/styled-components/Button';

const OffersList = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const { offers, isLoading, error } = useSpaceOffers({
    space,
    spaceId: match.params.id,
  });

  const limit = 3;
  return (
    <>
      <h5 className="mb-3">
        {t("available_offers")}
        {offers.length !== 0 && (
          <>
            : <span className="text-primary">{offers?.length}</span>
          </>
        )}
      </h5>
      {error ? (
        <Alert color="danger">{t("offers.api_error")}</Alert>
      ) : isLoading ? (
        <Loading />
      ) : offers.length === 0 ? (
        <Alert color="primary">{t("offers.empty_list_of_offers")}</Alert>
      ) : (
        <TileParentRow>
          {offers.slice(0, limit).map(({ id, offer__name, description, offer__tile_icon }) => (
            <Offer
              id={id}
              key={id}
              name={backendTranslation(offer__name)}
              description={backendTranslation(description)}
              preview={true}
              icon={offer__tile_icon}
            />
          ))}
          {offers.length > limit && (
            <TileParentCol>
              <TileCardShowAll>
                <TileTitileLink to={`/spaces/${match.params.id}/offers`}>
                  <BtnTextBlue $fweight $fontSizeLg>{t("see_all")} {">"}</BtnTextBlue>
                </TileTitileLink>
              </TileCardShowAll>
            </TileParentCol>
          )}
        </TileParentRow>
      )}
    </>
  );
};

export default OffersList;
