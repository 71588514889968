import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

export const Circle = styled.div`
  background-color: ${({ theme, type }) => type === 'active' ? theme.colors.primary : theme.colors.text.grey4};
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;

  & > ${Circle} {
    margin: 0 5px;
  }

  & > ${Circle}:first-of-type {
    margin-left: 0;
  }

  & > ${Circle}:last-of-type {
    margin-right: 0;
  }
`;

export const ArrowIcon = styled(BsArrowRight)`
  color: ${({ theme, type }) => type === 'active' ? theme.colors.primary : theme.colors.text.grey4};
  font-size: ${({ theme }) => theme.fontSizes.xl3};
`;

export const NumberLabel = styled.span`
  color: ${({ theme }) => theme.colors.background.white};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
