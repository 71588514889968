import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconButton from './IconButton';

const AdminActions = ({ onGrantRoleClick, onRevokeRoleClick, onDeleteUserClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className='d-flex align-items-center justify-content-end p-2 px-3'>
      <IconButton 
        onClick={onGrantRoleClick} 
        className='mr-3' 
        icon='plus'
      >
        {t('user_admin_actions.grant_end_user_role')}
      </IconButton>
      <IconButton 
        onClick={onRevokeRoleClick} 
        className='mr-3' 
        icon='minus'
      >
        {t('user_admin_actions.revoke_end_user_role')}
      </IconButton>
      <IconButton 
        onClick={onDeleteUserClick} 
        icon='trash'
      >
        {t('user_admin_actions.delete_user')}
      </IconButton>
    </div>  
  ); 
}; 

AdminActions.propTypes = {
  onGrantRoleClick: PropTypes.func.isRequired,
  onRevokeRoleClick: PropTypes.func.isRequired,
  onDeleteUserClick: PropTypes.func.isRequired
};

export default AdminActions;
