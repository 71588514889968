import React from 'react';
import PropTypes from 'prop-types';
import { BsCheck } from 'react-icons/bs';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  IconWrapper
} from '../styles/styled-components/Checkbox';

const Checkbox = ({ checked, outline, size, xs=false, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} outline={outline} size={size} xs={xs}>
      <IconWrapper>
        {checked ? <BsCheck /> : null}
      </IconWrapper>
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.defaultProps = {
  size: 'sm',
  outline: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  outline: PropTypes.bool,
  size: PropTypes.oneOf([ 'sm', 'md' ]),
  xs: PropTypes.bool
};

export default Checkbox;
