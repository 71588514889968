const colors = [ '#7E88EF', '#7F37EA', '#2A881B', '#0096F7', '#106BEF', '#76BE4A', '#0049B1', '#F29201', '#0265B0', '#2DB3BA' ];
  
export const stringToColor = (string) => {
  let color = 0;
  if (string === "")
    return colors[3];
  else {
    for (let i = 0; i < string.length; i++) {
      color += string.charCodeAt(i);
    }
    return colors[color % colors.length];
  }
};