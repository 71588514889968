import { Map, List } from 'immutable';

import { 
  FETCH_USER_PREFERENCES_REQUEST,
  FETCH_USER_PREFERENCES_SUCCESS,
  FETCH_USER_PREFERENCES_SUCCESS_FAVORITES,
  FETCH_USER_PREFERENCES_ERROR
} from '../actions/userPreferencesActions';

const initialState = Map({
  isLoading: false,
  favorites: Map({
    offers: List(),
    services: List(),
    spaces: List()
  }),
  error: null
});

export const userPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_USER_PREFERENCES_REQUEST:
    return state.set('isLoading', true);
  case FETCH_USER_PREFERENCES_SUCCESS_FAVORITES:
    return state
      .set('favorites', action.payload);
  case FETCH_USER_PREFERENCES_SUCCESS:
    return state.set('isLoading', false);
  case FETCH_USER_PREFERENCES_ERROR:
    return state.set('error' , action.payload).set('isLoading', false);
  default:
    return state;
  }
};