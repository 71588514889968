import React from 'react';
import { Alert, Card, CardBody } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import Loading from '../../../../main/components/loading';
import useAxios from '../../../hooks/useAxios';
import { __env } from '../../../../envloader';
import UserAvatar from '../../../components/UserAvatar';
import { Link } from 'react-router-dom';

const InvitationsList = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const {
    isLoading: isLoadingHandling,
    error: errorHandling,
    data: dataHandling,
  } = useAxios(
    `${__env.BPM_API_URL}spaces/invitations/public/handling/${match.params.id}`
  );

  const { isLoading, error, data } = useAxios(
    `${__env.BPM_API_URL}spaces/invitations/private?spaceId=${match.params.id}`
  );

  return (
    <>
      <h5 className="mb-3">{t("invitations")}</h5>
      {error ? (
        <Alert color="danger">{t("space_invitations.api_error")}</Alert>
      ) : isLoading ? (
        <Loading />
      ) : (
        <Card className="my-3">
          <CardBody className="p-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <UserAvatar
                  username={""}
                  width="3rem"
                  height="3rem"
                />
                <div className="pl-3">
                  <p className="m-0 font-xs">
                    {t("private_invitations.header")}
                  </p>
                  <h4 className="mr-2 font-md m-0">
                    <Trans
                      i18nKey="invitation.private_count"
                      values={{ count: dataHandling?.length ?? 0 }}
                      components={{ span: <span className="text-primary" /> }}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      {errorHandling ? (
        <Alert color="danger">{t("space_invitations.api_error")}</Alert>
      ) : isLoadingHandling ? (
        <Loading />
      ) : (
        <Card className="my-3">
          <CardBody className="p-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <UserAvatar
                  username={""}
                  width="3rem"
                  height="3rem"
                />
                <div className="pl-3">
                  <p className="m-0 font-xs">
                    {t("public_invitations.header")}
                  </p>
                  <h4 className="mr-2 font-md m-0">
                    <Trans
                      i18nKey="invitation.public_count"
                      values={{ count: data?.length }}
                      components={{ span: <span className="text-primary" /> }}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      <div className="text-right mb-3">
        <Link to={`/spaces/${match.params.id}/invitations`}>
          {t("go_to_invites")} {">"}
        </Link>
      </div>
    </>
  );
};

export default InvitationsList;
