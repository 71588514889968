import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse, Col, Row } from 'reactstrap';

import { __env } from '../../../../envloader';
import Checkbox from '../../../components/Checkbox';
import { BasicButton } from '../../../styles/styled-components/GlobalStyles';
import IconButton from '../../../components/IconButton';
import Loading from '../../../../main/components/loading';
import axios from '../../../../main/utils/axios/axiosInstance';
import UserRow from '../../../components/invitations/public/UserRow';

const InvitedUsers = ({ isLoading, error, data, refetchInvites, invitationData }) => {
  const [ allUsersSelected, setAllUsersSelected ] = useState(false);
  const [ selectedUsers, setSelectedUsers ] = useState([]);
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ isSending, setIsSending ] = useState(false);
  const { t } = useTranslation();

  const sendRequestForSelectedInvites = (decision) => {
    setIsSending(true);
    Promise.all(data.filter(invite => selectedUsers.includes(invite.invitation_id)).map(invite => {
      return axios.put(`${__env.BPM_API_URL}spaces/invitations/public/handling/${invite.invitation_id}/${decision}?secret=${invitationData.secret}`,
        {
          user: invite.triggering_user_userId,
        },
      );
    })).finally(() => {
      setIsSending(false);
      refetchInvites();
    });
  };

  const onCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(prev => prev.filter(id => id !== userId));
    }
    else {
      setSelectedUsers(prev => [ ...prev, userId ]);
    }
  };

  const handleSelectAllUsers = (e) => {
    setAllUsersSelected(e.target.checked);
    if (e.target.checked) {
      setSelectedUsers(data.map(({ invitation_id }) => invitation_id));
    } 
    else {
      setSelectedUsers([]);
    }
  };

  return (<>{
    (isLoading && !data) 
      ? <Loading />
      : !error && data && data.length && 
        <div>
          <div>
            <Row className="mb-4 align-items-center justify-content-between">
              <Col xs='8'>
                <span className='font-sm fontWeight-medium'>
                  {t('public_invitations.waiting_for_admin_approval')}
                </span>
              </Col>
              {isSending ? <Loading/> : selectedUsers.length > 0 && (
                <Col>
                  <div className='d-flex align-items-center justify-content-end'>
                    <IconButton onClick={() => sendRequestForSelectedInvites('accept')} icon='check' className='mr-3'>
                      {t('public_invitations.accept')}
                    </IconButton>
                    <IconButton onClick={() => sendRequestForSelectedInvites('reject')} icon='cancel'>
                      {t('public_invitations.reject')}
                    </IconButton>
                  </div>
                </Col>
              )}
            </Row>
            <Row className='mb-4'>
              <Col>
                <div className="d-flex align-items-center">
                  <label className='mb-0 mr-2'>
                    <Checkbox
                      checked={allUsersSelected}
                      onChange={handleSelectAllUsers}
                    />
                  </label>
                  <label className='m-0 font-sm fontWeight-medium'>
                    {t('public_invitations.select_all')}
                  </label>
                </div>
              </Col>
              <Col>
                <span className='font-sm fontWeight-medium'>
                  {t('public_invitations.email')}
                </span>
              </Col>
              <Col className='text-right'>
                <span className='font-sm fontWeight-medium'>
                  {t('public_invitations.invitations')}
                </span>
              </Col>
            </Row>
          </div>
          { 
            data.slice(0,3).map((user) => 
              <UserRow 
                key={user.invitation_id} 
                user={user} 
                onCheckboxChange={onCheckboxChange} 
                checked={selectedUsers.includes(user.invitation_id)} 
                invitationData={invitationData} 
                refetch={refetchInvites} 
              />)
          }
          {
            data.slice(3).length > 0 && 
            <>
              <Collapse isOpen={isExpanded}>
                {data.slice(3).map((user) => 
                  <UserRow 
                    key={user.invitation_id} 
                    user={user} 
                    onCheckboxChange={onCheckboxChange} 
                    checked={selectedUsers.includes(user.invitation_id)} 
                    invitationData={invitationData} 
                    refetch={refetchInvites} />)}
                <hr className='w-100'/>
              </Collapse>
              <div className="text-center">
                {isExpanded ? (
                  <BasicButton color='blue' onClick={() => setIsExpanded(false)}>
                    {t('private_invitations.collapse_list')}
                  </BasicButton>
                ) : (
                  <BasicButton color='blue' onClick={() => setIsExpanded(true)}>
                    {t('private_invitations.expand_list')}
                  </BasicButton>
                )}
              </div>
            </>
          }
        </div>
  }</>);
};

InvitedUsers.propTypes = {
  invitationData: PropTypes.shape({
    secret: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.array,
  refetchInvites: PropTypes.func,
};

export default InvitedUsers;
