import React from 'react';
import { useTranslation } from 'react-i18next';

import { __env } from '../../envloader';
import {
  Container,
  Logo,
  LogoImg,
  Menu,
  MenuLink,
  Info
} from '../styles/styled-components/Footer';
import LogoMinImg from './../../assets/svg/landing/prace-lab_logo.svg';


const Footer = () => {
  const { t } = useTranslation();

  const renderNavItem = (env, i18nKey) => {
    return env && (
      <MenuLink href={env} target="_blank" rel="noreferrer">{t(`nav_footer.${i18nKey}`)}</MenuLink>
    );
  };

  return (
    <Container>
      <Logo>
        <LogoImg src={LogoMinImg} />
      </Logo>
      <Menu>
        { renderNavItem(__env.FOOTER_INFORMATION_URL, "information") }
        { renderNavItem(__env.FOOTER_ACCESSIBILITY_URL, "accessibility") }
        { renderNavItem(__env.FOOTER_HANDBOOK_URL, "handbook") }
        { renderNavItem(__env.FOOTER_SITEMAP_URL, "sitemap") }
        { renderNavItem(__env.FOOTER_REGULATIONS_URL, "regulations") }
        { renderNavItem(__env.FOOTER_PRIVACY_POLICY_URL, "privacy_policy") }
      </Menu>
      <Info>Copyright © 2023. Pracelab. All rights reserved.</Info>
    </Container>
  );
};

export default Footer;