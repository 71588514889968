import React from 'react';
import PropTypes from 'prop-types';

import { __env } from '../../envloader';
import { checkLanguage } from '../utils/componentsLocal';
import { InstitutionLogoImg } from '../styles/styled-components/InstitutionLogo';

const InstitutionLogo = ({ withName }) => {
  const lang = checkLanguage();
  const filename = `institution-logo${withName ? '-and-name-' : '-'}${lang}.svg`;
  const InstitutionLogoFromIbis = `${__env.IBIS_TENANT_URL}themes/${filename}`;
  return (
    <InstitutionLogoImg
      src={InstitutionLogoFromIbis}
      onError={ e =>
        e.target.src = `/institution/${filename}`
      }
      alt='logo'
      big={withName}
    />
  );
};

InstitutionLogo.propTypes = {
  withName: PropTypes.bool,
};

export default InstitutionLogo;
