import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { CaretIcon, FilterButton, FilterButtonText } from '../../styles/styled-components/SearchUsers.js';
import Portal from '../Portal.jsx';
import SettlementsFiltersPopover from './settlementsFiltersPopover.jsx';
import { caretIcon } from '../../../assets/svg/settlements/icons.js';

export const Filters = ({ isInputActive, uniqueSettlements }) => {
  const btnRef = useRef();
  const { t } = useTranslation();

  const [ coords, setCoords ] = useState({});
  const [ filtersPopoverOpen, setFiltersPopoverOpen ] = useState(false);
  const [ popoverEntered, setPopoverEntered ] = useState(false);

  const handleOpen = () => {
    updatePopoverCoords();
    setFiltersPopoverOpen(prev => !prev);
  };

  const updatePopoverCoords = useCallback(() => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x,
      top: rect.y + window.scrollY + 45
    });
  }, []);

  const onCloseFiltersPopover = useCallback(() => setFiltersPopoverOpen(false), []);

  return (
    <>
      <FilterButton onClick={handleOpen} open={filtersPopoverOpen} active={isInputActive} ref={btnRef}>
        <FilterButtonText>{t('filters')}</FilterButtonText>
        <CaretIcon open={filtersPopoverOpen} src={caretIcon}/>
      </FilterButton>
      <Portal>
        <CSSTransition
          in={filtersPopoverOpen}
          timeout={300}
          classNames='filters-popover'
          onEntered={() => setPopoverEntered(true)}
          onExited={() => setPopoverEntered(false)}
          mountOnEnter 
          unmountOnExit
        >
          <SettlementsFiltersPopover 
            coords={coords}
            entered={popoverEntered}
            onClose={onCloseFiltersPopover}
            updatePopoverCoords={updatePopoverCoords}
            uniqueSettlements={uniqueSettlements}
          />
        </CSSTransition>
      </Portal>
    </>
  );
};

export default Filters;

Filters.propTypes = {
  isInputActive: PropTypes.func,
  uniqueSettlements: PropTypes.array
};