import styled from 'styled-components';
import { Button, Input, Popover } from 'reactstrap';

export const InvitationHeader = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const SpaceName = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;

export const InvitationDataHeader = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const InvitationData = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const DecisionButton = styled(Button)`
    max-width: 200px;
`;

export const InputDate = styled(Input)`
  width: 180px;
  height: 30px;
  &:focus {
    border-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.text.blackFocus};
    outline: none;
    box-shadow: none;
  }
`;

export const PopoverElem = styled(Popover)`
  width: 350px;
`;

export const InputDiv = styled.div`
  height: 36px;
`;

export const CustomSwitch = styled.div`
  width: 40px;
  height: 22px;
  border: 1px solid ${props => props.theme.colors.secondaryDisabled};
  border-radius: 17px;
  padding: 3px 4px;
  display: flex;
  align-items: center;
  padding-left: ${props => props.active ? "19px" : "4px"};
  transition: all 0.2s;
`;

export const CustomSwitchRound = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 13px;
  transition: all 0.2s;
  background-color: ${ ({ active, theme }) => active 
    ? theme.colors.secondary
    : theme.colors.secondaryDisabled};
`;