import { Map } from 'immutable';
import { combineReducers } from 'redux';
import { 
  FETCH_CONTRACT_BILLING_ERROR,
  FETCH_CONTRACT_BILLING_REQUEST,
  FETCH_CONTRACT_BILLING_SUCCESS,
  FETCH_SPACE_CONTRACT_REQUEST,
  FETCH_SPACE_CONTRACT_SUCCESS,
  FETCH_SPACE_CONTRACT_ERROR,
  FETCH_CONTRACT_EVENTS_ERROR,
  FETCH_CONTRACT_EVENTS_REQUEST,
  FETCH_CONTRACT_EVENTS_SUCCESS
} from '../actions/contractActions';

const initialState = Map({
  isLoading: false,
  data: null,
  error: null
});

const contractDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SPACE_CONTRACT_REQUEST:
    return state.set('isLoading', true).set('data', null);
  case FETCH_SPACE_CONTRACT_SUCCESS:
    return state.set('isLoading', false).set('data', action.payload);
  case FETCH_SPACE_CONTRACT_ERROR:
    return state.set('isLoading', false).set('error', action.payload);
  default:
    return state;
  }
};

const billingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CONTRACT_BILLING_REQUEST:
    return state.set('isLoading', true).set('data', null);
  case FETCH_CONTRACT_BILLING_SUCCESS:
    return state.set('isLoading', false).set('data', action.payload);
  case FETCH_CONTRACT_BILLING_ERROR:
    return state.set('isLoading', false).set('error', action.payload);
  default:
    return state;
  }
};

const contractEventsReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CONTRACT_EVENTS_REQUEST:
    return state.set('isLoading', true).set('data', null);
  case FETCH_CONTRACT_EVENTS_SUCCESS:
    return state.set('isLoading', false).set('data', action.payload);
  case FETCH_CONTRACT_EVENTS_ERROR:
    return state.set('isLoading', false).set('error', action.payload);
  default:
    return state;
  }
};

export const contractReducers = combineReducers({
  contractDetails: contractDetailsReducer,
  billingDetails: billingDetailsReducer,
  contractEvents: contractEventsReducer
});