import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

import Loading from '../../../main/components/loading';
import useAxios from '../../hooks/useAxios';
import { __env } from '../../../envloader';
import ServiceInProgress from '../../components/ServiceInProgress';
import { useTranslation } from 'react-i18next';
import { TileParentRow } from '../../styles/styled-components/TileCard';
import OfferIcon from '../../../assets/svg/offers/offer-1.svg';
import { useDispatch } from 'react-redux';
import { refreshServices } from '../../actions/servicesActions';

const ServicesInProgress = ({ spaceId, withoutServices, setServicesInProgressExists }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: servicesInProgress,
    isLoading: servicesInProgressLoading,
    error: servicesInProgressError,
    refetch
  } = useAxios(__env.BPM_API_URL + 'offers/' + spaceId);

  const { data: iconsProgress } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${spaceId}/user_offers_current`);
  const icons = iconsProgress?.items.map(el => [ el.id, el.offer__tile_icon || OfferIcon ]);
  let filteredServicesInProgress = servicesInProgress?.filter(
    (service) => 
      service?.current_step !== service?.total_steps || 
      service?.final_step_result !== "COMPLETED" 
  );

  useEffect(() => {
    if (servicesInProgress?.length !== undefined && setServicesInProgressExists !== undefined) setServicesInProgressExists(servicesInProgress?.length > 0);
  }, [ servicesInProgress, servicesInProgressLoading, setServicesInProgressExists ]);

  useEffect(() => {
    const refresh = setInterval(() => dispatch(refreshServices(spaceId, refetch)), 30000);
    return () => clearInterval(refresh);
  }, [ dispatch, spaceId, refetch ]);

  return (!withoutServices || filteredServicesInProgress?.length > 0) ?
    <section>
      <div className="my-5">
        <h5>{t('offers.services_in_progress.header')}</h5>
      </div>
      { servicesInProgressError ? (
        <Alert color='danger'>{t('offers.services_in_progress.api_error')}</Alert>
      ) : (
        servicesInProgressLoading ? (
          <Loading />
        ) : (
          filteredServicesInProgress.length === 0 ? (
            <Alert color='primary'>{t('offers.services_in_progress.empty_list')}</Alert>
          ) : (
            <TileParentRow>
              {iconsProgress && filteredServicesInProgress
                .map(({ processName, offer_id, offer_name, offer_details, short_description, step_name, current_step, total_steps }) => (
                  <ServiceInProgress
                    key={processName}
                    name={offer_name}
                    step_name={step_name}
                    space_id={spaceId}
                    process_id={processName}
                    current_step={current_step}
                    total_steps={total_steps}
                    offer_id={offer_id}
                    icons={icons}
                    shortDescription={short_description}
                    description={offer_details}
                  />                
                ))}
            </TileParentRow>
          )
        )
      )}
    </section>
    :
    <></>;
};

ServicesInProgress.propTypes = {
  spaceId: PropTypes.string.isRequired,
  withoutServices: PropTypes.bool,
  setServicesInProgressExists: PropTypes.func
};

export default ServicesInProgress;
