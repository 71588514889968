import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import Summary from '../../components/settlements/Summary';
import Awaiting from './settlements/Awaiting';
import Reports from './settlements/Reports';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import useSpaceBillings from '../../hooks/useSpaceBillings';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { useSelector } from 'react-redux';
import Breadcrumbs  from './Breadcrumbs';
import FiltersPanel from '../../components/settlements/FiltersPanel';

const SpaceSettlements = ({ match }) => {
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const location = useLocation();

  const [ filteredBillings, setFilteredBillings ] = useState([]);

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.settlements') }
  ]), [ space?.name, space?.id, t ]);

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoadingServices: state.services.servicesList.get('isLoading'),
  });

  const { services, isLoadingServices } = useSelector(selectDates);
  const { data: billings, isLoading: billingsIsLoading } = useSpaceBillings(services);

  useEffect(() => {
    setFilteredBillings(billings);
  }, [ billings ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filtersValues = searchParams.getAll('filter');
    const searchValue = searchParams.get('search')?.toLowerCase();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const convertStartDate = startDate ? new Date(startDate) : null;
    const convertEndDate = endDate ? new Date(endDate) : null;

    if (!filtersValues.length && !searchValue && !startDate && !endDate) {
      return setFilteredBillings(billings);
    }
    else {
      const filteredData = billings?.filter(billing => {
        const matchesSearchValue = searchValue ? billing?.serviceName?.toLowerCase().includes(searchValue) : true;
        const matchesFilterParams = filtersValues.length ? filtersValues.includes(billing?.serviceName) : true;
        const matchStartDateValue = convertStartDate ? new Date(billing?.creation_date) >= convertStartDate : true;
        const matchEndDateValue = convertEndDate ? new Date(billing?.creation_date) <= convertEndDate : true;

        return matchesSearchValue && matchesFilterParams && matchStartDateValue && matchEndDateValue;
      });
  
      setFilteredBillings(filteredData);
    }
  }, [ location.search, billings ]);
  
  if (!space || isLoadingServices || billingsIsLoading) {
    return (
      <>
        <Breadcrumbs dataLocations={dataLocations} />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Helmet>
        <title>
          {t("page_titles.spaces.settlements", { space: space.name })}
        </title>
      </Helmet>
      <Card className="p-4 mt-5">
        <FiltersPanel settlements={billings} />
        <Summary servicesLen={services.length} settlements={filteredBillings} />
        <Awaiting settlements={filteredBillings} />
        <Reports settlements={filteredBillings} />
      </Card>
    </>
  );
};

SpaceSettlements.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(SpaceSettlements);