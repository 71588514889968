import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { StarIcon, FullStarIcon } from '../styles/styled-components/GlobalStyles';
import { StarButton } from '../styles/styled-components/FavoriteButton';
import { removeFromFavorite, addToFavorite } from '../actions/userPreferencesActions';

const FavoriteButton = ({ spaceId, id, type, alwaysActive = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const favoritesData = useSelector((state) => state.userPreferences.get('favorites'));
  // Removing blinking when updating
  const [ active, setActive ] = useState(alwaysActive ? 1 : 0);
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  useEffect(() => {
    if (alwaysActive) {
      setActive(1);
    } 
    else {
      if (type === "spaces") {
        setActive(!!favoritesData[type]?.find(obj => obj.id === id.toString()) ? 1 : 0);
      } 
      else {
        const isFavorite = favoritesData[type]?.find(ele => ele?.id === id?.toString() && ele?.space === spaceId?.toString());
        setActive(isFavorite);
      }
    }
  }, [ favoritesData, id, type, alwaysActive, spaceId ]);

  const favoriteCheck = () => {
    active ?
      dispatch(removeFromFavorite(type, spaceId, id))
      :
      dispatch(addToFavorite(type, spaceId, id));
  };

  return (
    <>
      <StarButton type="button" onClick={() => favoriteCheck()} id={`Tooltip-${id}-${spaceId}`}>
        {
          active ?
            <FullStarIcon/>
            :
            <StarIcon/>
        }
      </StarButton>
      <Tooltip 
        className="tooltip-normal"
        placement="top"
        isOpen={tooltipOpen}
        target={`Tooltip-${id}-${spaceId}`}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {active ? t('favorite.remove_from_favorites') : t('favorite.add_to_favorites')}
      </Tooltip>
    </>
  );
};

FavoriteButton.propTypes = {
  spaceId: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  active: PropTypes.number,
  favoritesUpdate: PropTypes.func,
  alwaysActive: PropTypes.bool
};

export default FavoriteButton;