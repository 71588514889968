import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';
import offerIcon from '../../assets/svg/offers/offer-1.svg';

const IconProgress = (props) => { 
  const { icon, progress, sizeImg, sizeProgress } = props;

  return (
    <div style={{ width: sizeProgress, height: sizeProgress }}>
      <CircularProgressbarWithChildren 
        value={progress} 
        strokeWidth={7} 
        styles={buildStyles({
          pathColor: '#106BEF',
          trailColor: '#E0E0E0'
        })}>
        <img
          style={{ borderRadius: 999, width: sizeImg, height: sizeImg }}
          src={icon || offerIcon}
          alt="new"
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default IconProgress;

IconProgress.propTypes = {
  imgUrl: PropTypes.string,
  progress: PropTypes.number,
  sizeImg: PropTypes.number,
  sizeProgress: PropTypes.number,
  icon: PropTypes.string
};