import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { UserRole } from '../../constants/roles';
import { selectUsersCountByRole } from '../../selectors';
import { Th, UsersCount } from '../../styles/styled-components/SpaceUsers';
import { getUsersCountByRole } from '../../utils/users';
import useCurrentSpace from '../../hooks/useCurrentSpace';

const UsersTableHeader = ({ filteredUsers, showFilteredUsers }) => {
  const endUsersCount = useSelector((state) => selectUsersCountByRole(state, UserRole.END_USER));
  const userAdminsCount = useSelector((state) => selectUsersCountByRole(state, UserRole.USER_ADMIN));
  const financialAdminsCount = useSelector((state) => selectUsersCountByRole(state, UserRole.FINANCIAL_ADMIN));
  const filterdEndUsersCount = getUsersCountByRole(filteredUsers, UserRole.END_USER);
  const filtereduserAdminsCount = getUsersCountByRole(filteredUsers, UserRole.USER_ADMIN);
  const filteredFinancialAdminsCount = getUsersCountByRole(filteredUsers, UserRole.FINANCIAL_ADMIN);
  const match = useRouteMatch();
  const { roles } = useCurrentSpace(match);

  const { t } = useTranslation();

  return (
    <tr>
      <Th>
        <div className="text-left">
          {t('space_users.user_roles_table_header')}
          <br/>&nbsp;
        </div>
      </Th>
      <Th>
        <div>{t('roles.end_user')}</div>
        <UsersCount data-testid='end-users-count'>
          {showFilteredUsers ? (
            <span>({filterdEndUsersCount}/{endUsersCount})</span>
          ) : (
            <span>({endUsersCount})</span>
          )}
        </UsersCount>
      </Th>
      <Th>
        <div>{t('roles.user_admin')}</div>
        <UsersCount data-testid='user-admins-count'>
          {showFilteredUsers ? (
            <span>({filtereduserAdminsCount}/{userAdminsCount})</span>
          ) : (
            <span>({userAdminsCount})</span>
          )}
        </UsersCount>
      </Th>
      <Th>
        <div>{t('roles.financial_admin')}</div>
        <UsersCount data-testid='financial-admins-count'>
          {showFilteredUsers ? (
            <span>({filteredFinancialAdminsCount}/{financialAdminsCount})</span>
          ) : (
            <span>({financialAdminsCount})</span>
          )}
        </UsersCount>
      </Th>
      {roles?.includes('USER_ADMIN') &&
        <Th>
          {t('space_users.deleting')}
          <br/>&nbsp;
        </Th>
      }
    </tr>
  );
};

UsersTableHeader.defaultProps = {
  filteredUsers: [],
  showFilteredUsers: false
};

UsersTableHeader.propTypes = {
  filteredUsers: PropTypes.array.isRequired,
  showFilteredUsers: PropTypes.bool.isRequired
};

export default UsersTableHeader;
