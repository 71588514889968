import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { __env } from '../../envloader';

const TicketsButton = ({ auth }) => {
  const { t } = useTranslation();

  const renderButton = context => (
    <a
      className='btn btn-outline-secondary mr-4'
      href={ auth ? __env.TICKETS_UI_URL + 'newTicket' + context : __env.TICKETS_UI_URL + 'newTicketUnregistered'}
    >
      {t('topbar.create_ticket')}
    </a>
  );

  return (
    <Switch>
      <Route path='/spaces/:id/:view' render={({ match }) => (renderButton(`?context=space&context1=${match.params.id}&context2=${match.params.view}`))} />
      <Route path='/affiliations/:view' render={({ match }) => (renderButton(`?context=affiliation`))} />
      <Route path='/spaces/:id/' render={({ match }) => (renderButton(`?context=space&context1=${match.params.id}`))} />
      <Route render={() => (renderButton(''))} />
    </Switch>
  );
};

TicketsButton.propTypes = {
  auth: PropTypes.object
};


export default TicketsButton;
