const Roles = Object.freeze([ 'financial_admin', 'end_user', 'user_admin' ]);

const FilterValue = Object.freeze({
  ANY: 'any',
  YES: 'yes',
  NO: 'no'
});

const InitialFilters = Object.freeze({
  financial_admin: FilterValue.ANY,
  user_admin: FilterValue.ANY,
  end_user: FilterValue.ANY
});

export {
  Roles,
  InitialFilters,
  FilterValue
};

