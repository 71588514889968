import styled from 'styled-components';

export const PermissionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const Thead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.grey5};
`;

export const Th = styled.th`
  text-align: left;
  padding: 0.7rem 1.5rem;
  color: ${({ theme }) => theme.colors.text.grey3};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Td = styled.td`
  padding: 0.8rem 1.5rem 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;