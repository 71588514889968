import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import * as notify from '../../../../main/utils/notify'; 
import backendTranslation from '../../../utils/backendTranslation';

import { Container } from '../../../styles/styled-components/GlobalStyles';
import { InvitationHeader, SpaceName, InvitationData, DecisionButton, InvitationDataHeader } from '../../../styles/styled-components/Invitation';
import { __env } from '../../../../envloader';
import axios from '../../../../main/utils/axios/axiosInstance';
import Loading from '../../../../main/components/loading';
import useAxios from '../../../hooks/useAxios';
import { Link } from 'react-router-dom';


const PublicInvitation = ({ match, location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [ isSending, setIsSending ] = useState(false);
  const loginData = useSelector(state => state.login.get('loginData'));
  const spaceList = useSelector((state) => state.spaces.spaceList.get('data'));
  const spaceListLoading = useSelector((state) => state.spaces.spaceList.get('isLoading'));
  const [ wasStartSpaceLoading, setWasStartSpaceLoading ] = useState(false); //spaceListLoading is starting with delay
  const [ isSpaceAlreadyExistInUserData, setIsSpaceAlreadyExistInUserData ] = useState(-1);

  const otherOptions = useMemo(() => (
    { __silentFor: [ { status: 400 } ] }
  ), []);

  const { data, isLoading, error } = useAxios(`${__env.BPM_API_URL}spaces/invitations/public/${match.params.id}/info${location.search}`,
    {
      sendOnInit: loginData ? true : false, 
      sendOnChange: loginData ? true : false,
      otherOptions
    });


  const sendRequest = () => {
    setIsSending(true);
    axios.post(
      `${__env.BPM_API_URL}spaces/invitations/public/handling/${match.params.id}${location.search}`,
      {},
      {
        __silentFor: 'all'
      }).then(() => {
      notify.success(
        t(`invitation.notification.success`),
        t(`invitation.notification.wait_for_approval`)
      );
      history.push('/');
    }).catch((err) => {
      const errMsg = err.response.data?.message
        ? backendTranslation(err.response.data.message)
        : t('invitation.error_send_ask_for_approval');

      notify.error("", errMsg);
      setIsSending(false);
    });
  };

  useEffect(() => {
    if (!error) {
      if (spaceListLoading) {
        setWasStartSpaceLoading(true);
      }
      if (!isLoading && wasStartSpaceLoading && !spaceListLoading) {
        setIsSpaceAlreadyExistInUserData((spaceList.find(({ id }) => id === data.space_id) !== undefined));
      }
    }
  }, [ data, error, isLoading, isSpaceAlreadyExistInUserData, spaceList, spaceListLoading, wasStartSpaceLoading ]);

  const onBtnClick = () => {
    if (isSpaceAlreadyExistInUserData) {
      return history.push('/spaces/'+data?.space_id);
    }
    else {
      return sendRequest();
    }
  };

  const getBtnText = () => {
    if (isSpaceAlreadyExistInUserData) {
      return "go_to_space";
    }
    else {
      return "ask_for_access";
    }
  };

  return (
    <Container>
      <InvitationHeader>{t('invitation.header_public')}</InvitationHeader>
      <Card>
        {(isLoading && !error) ? 
          <div className='m-3 d-flex justify-content-center'>
            <Loading />
          </div> : 
          !loginData ? 
            <div className='p-4 d-flex justify-content-center'>
              <InvitationHeader>{t('invitation.login_to_access')}</InvitationHeader>
            </div> :
            error ? 
              <div className='p-4'>
                <p>{t('invitation.error')}</p>
                <Link to='/' className='alert-link'>{t('back_to_main_page')}</Link>
              </div> : 
              <>
                <CardHeader className='p-4'>
                  <Row className='m-0'>
                    <SpaceName>{t('invitation.space_name')}: {data.space_name}</SpaceName>
                  </Row>
                  <Row className='m-0 pt-3'>
                    <Col>
                      <InvitationDataHeader>{t('invitation.inviting_user')}</InvitationDataHeader>
                      <InvitationData><b>{t('invitation.name')}: </b>{data.inviting_name}</InvitationData>
                      <InvitationData><b>{t('invitation.email')}: </b>{data.inviting_email}</InvitationData>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {isSending ? <div className='d-flex justify-content-center'>
                    <Loading />
                  </div> :
                    <Row className='m-0'>
                      <Col className='d-flex justify-content-center'>
                        <DecisionButton 
                          color='primary' 
                          block
                          onClick={() => onBtnClick()}
                        >
                          {t('invitation.'+getBtnText())}
                        </DecisionButton>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </>
        }
      </Card>
    </Container>
  );
};

PublicInvitation.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PublicInvitation;