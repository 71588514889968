import React, { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { searchIcon } from '../../../assets/svg/settlements/icons.js';
import { RoundedInput } from '../../styles/styled-components/GlobalStyles.js';
import {
  Icon,
  InputContainer,
} from '../../styles/styled-components/Settlements.js';

export const Search = ({ setIsInputActive }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const toggleIsInputActive = useCallback(() => setIsInputActive(prev => !prev), [ setIsInputActive ]);

  const handleSearchChange = (value) => {
    if (value) {
      searchParams.set("search", value);
    }
    else {
      searchParams.delete("search");
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <InputContainer>
      <RoundedInput
        type="text"
        value={!!searchParams.size ? searchParams.get("search") : ""}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder={t('settlements.search')}
        onFocus={toggleIsInputActive}
        onBlur={toggleIsInputActive}
        icon
        style={{ borderRadius: "0 6px 6px 0", borderLeft: "none", height: "100%" }}
      />
      <Icon src={searchIcon} alt="search" />
    </InputContainer>
  );
};

export default Search;

Search.propTypes = {
  setIsInputActive: PropTypes.func
};