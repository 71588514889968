import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import i18n from '../../main/utils/i18nConfigProvider';

const namespace = '@PRACELAB_USER_PREFERENCES/';

export const FETCH_USER_PREFERENCES_REQUEST = namespace + 'FETCH_USER_PREFERENCES_REQUEST';
export const FETCH_USER_PREFERENCES_SUCCESS = namespace + 'FETCH_USER_PREFERENCES_SUCCESS';
export const FETCH_USER_PREFERENCES_SUCCESS_FAVORITES = namespace + 'FETCH_USER_PREFERENCES_SUCCESS_FAVORITES';
export const FETCH_USER_PREFERENCES_ERROR = namespace + 'FETCH_USER_PREFERENCES_ERROR';

export const getUserPreferences = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_PREFERENCES_REQUEST });

    const { data } =
      await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/users/ui-preferences/`);

    if (Object.keys(data).length !== 0) {

      if (data.favorites) {
        dispatch({ type: FETCH_USER_PREFERENCES_SUCCESS_FAVORITES, payload: data.favorites });
      }

    } 

    dispatch({ type: FETCH_USER_PREFERENCES_SUCCESS });  
  }
  catch (err) {
    dispatch({
      type: FETCH_USER_PREFERENCES_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

const updateUserPreferences = (data) => async (dispatch) => {
  dispatch({ type: FETCH_USER_PREFERENCES_REQUEST });
  axios
    .put(`${__env.USERS_DATA_API_URL}api/users-data/users/ui-preferences/`, data)
    .then(() => dispatch(getUserPreferences()));
};

export const addToFavorite = (type, spaceId, id) => (dispatch, getState) => {

  const favoritesData = getState().userPreferences.get('favorites');

  const result = {
    favorites: {
      ...favoritesData,
      [type]: [
        ...(favoritesData[type] || []),
        type !== 'spaces' ?
          {
            id,
            space: spaceId
          } : {
            id
          }
      ]
    }
  };

  dispatch(updateUserPreferences(result))
    .then(() => notify.success("", i18n.t("favorite.add_success")))
    .catch(() => notify.error("", i18n.t("favorite.add_success")));
};

export const removeFromFavorite = (type, spaceId, id) => (dispatch, getState) => {

  const favoritesData = getState().userPreferences.get('favorites');

  let result = {
    favorites: {
      ...favoritesData
    }
  };
  let tempData = [ ...favoritesData[type] ];

  if (type === "spaces") {
    result.favorites[type] = tempData.filter(obj => obj.id !== id);
  } 
  else {
    result.favorites[type] = tempData.filter(obj => obj.id !== id || obj.space !== spaceId);
  } 

  dispatch(updateUserPreferences(result))
    .then(() => notify.success("", i18n.t("favorite.remove_success")))
    .catch(() => notify.error("", i18n.t("favorite.remove_success")));
};
