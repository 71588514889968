import React, { useMemo } from 'react';
import { Alert } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import useAxios from '../../../hooks/useAxios';
import { __env } from '../../../../envloader';
import Loading from '../../../../main/components/loading';
import { Link } from 'react-router-dom';
import ServiceRow from '../ServiceRow';
import { TileParentRow, TileParentCol, TileCardShowAll, TileTitileLink } from '../../../styles/styled-components/TileCard';
import InfoBox from '../../../components/InfoBox';
import IconInfo from '../../../../assets/svg/common/blue-info.svg';
import { useSelector } from 'react-redux';
import { BtnTextBlue } from '../../../styles/styled-components/Button';

const ServicesList = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoading: state.services.servicesList.get('isLoading'),
    error: state.services.servicesList.get('error'),
  });
  const { services, isLoading, error } = useSelector(selectDates);
  
  const {
    data: spaceUserOffersData
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${match.params.id}/user_offers`);

  const { activeServices } = useMemo(() => {
    const enrichedServices = services?.map(service => {
      const foundItem = spaceUserOffersData?.items?.find(ele => ele.id === service.parameters.bpm_params_offer_id?.value);
      
      const offerData = {
        offer__tile_icon: foundItem?.offer__tile_icon,
        short_description: foundItem?.short_description
      };
    
      return {
        ...service,
        offerData
      };
    });

    const active = enrichedServices?.filter( service => ![ "TERMINATED", "EXPIRED" ].includes(service.state));

    return { activeServices: active };
  }, [ services, spaceUserOffersData ]);
  
  const limit = 3;
  return (
    <>
      <h5 className="mb-3">
        {t("active_services")}
        {activeServices?.length !== 0 && (
          <>
            : <span className="text-primary">{activeServices?.length}</span>
          </>
        )}
      </h5>
      {error ? (
        <Alert color="danger">{t("services.api_error")}</Alert>
      ) : isLoading ? (
        <Loading />
      ) : activeServices?.length === 0 ? (
        <InfoBox color="blue" iconPath={IconInfo} iconAlt="info">
          <Trans i18nKey='services.without_services_list'> 
            nie posiada <Link to={`/spaces/${match.params.id}/offers/`}>Ofert</Link> aby aktywowac
          </Trans>
        </InfoBox>
      ) : (
        <TileParentRow>
          {activeServices
            .slice(0, limit)
            .map(({ id, name, offerData, parameters: { bpm_params_offer_id } }) => (
              <ServiceRow
                key={id}
                id={id}
                offerData={offerData}
                offer_id={bpm_params_offer_id?.value}
                preview={true}
                name={name}
              />
            ))}
          {activeServices.length > limit && (
            <TileParentCol>
              <TileCardShowAll>
                <TileTitileLink to={`/spaces/${match.params.id}/services`}>
                  <BtnTextBlue $fweight $fontSizeLg>{t("see_all")} {">"}</BtnTextBlue>
                </TileTitileLink>
              </TileCardShowAll>
            </TileParentCol>
          )}
        </TileParentRow>
      )}
    </>
  );
};

export default ServicesList;
