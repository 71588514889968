import styled, { css } from 'styled-components';
import { getElemWeight, getFontSize } from './TextElem';

const baseTextSmMedium = css`
  font-weight: ${(props) => getElemWeight(props.theme, 'medium')};
  font-size: ${(props) => getFontSize(props.theme, 'sm')};
`;

const SharedStyle = css`
  cursor: pointer;
  font-size: ${({ theme, $fontSizeLg }) => $fontSizeLg ? theme.fontSizes.lg : theme.fontSizes.sm};
  font-weight: ${({ theme, $fweight }) => $fweight ? theme.fontWeights.medium : 'inherit'};
`;

export const BtnTextGrey = styled.p`
  ${SharedStyle};
  color: ${({ theme }) => theme.colors.text.lessDarkGrey};
  &:hover { color: ${({ theme }) => theme.colors.text.greyHover}; };
  &:active { color: ${({ theme }) => theme.colors.text.darkGrey}; } 
  &:disabled { color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

export const BtnTextBlue = styled.p`
${SharedStyle};
  color: ${({ theme }) => theme.colors.link};
  &:hover { color: ${({ theme }) => theme.colors.text.blueMore}; };
  &:active { color: ${({ theme }) => theme.colors.text.blueMost}; } 
  &:disabled { color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

export const BtnTextRed = styled.p`
  ${SharedStyle};
  color: ${({ theme }) => theme.colors.alerts.red};
  &:hover { color: ${({ theme }) => theme.colors.error}; };
  &:active { color: ${({ theme }) => theme.colors.text.blueMost}; } 
  &:disabled { color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

const btnBase = css`
  padding: 9px 19px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  height: 36px;
  ${baseTextSmMedium }
`;

export const BtnBlue = styled.button`
  ${btnBase }
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 2px solid ${(props) => props.theme.colors.secondary};
  &:hover { background-color: ${({ theme }) => theme.colors.secondaryHover}; };
  &:active { background-color: ${({ theme }) => theme.colors.secondaryActive}; } 
  &:disabled { background-color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

export const BtnRed = styled.button`
  ${btnBase}
  color: ${({ theme }) => theme.colors.button.danger};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.alerts.red};
  transition: all 0.3s;
  &:hover { 
    ${({ theme }) => {
    const { redHover } = theme.colors.alerts;
    return `
      border-color: ${redHover};
      color: ${redHover};
    `;
  }}
  };
  &:active { 
    color: ${({ theme }) => theme.colors.error};
    border-color: ${({ theme }) => theme.colors.error};
  }; 
  &:disabled { 
    border: 1px solid ${({ theme }) => theme.colors.secondaryDisabled};
    color: ${({ theme }) => theme.colors.secondaryDisabled}; 
  } 
`;

export const BtnBlueOutline = styled.button`
  ${btnBase }
  color: ${({ theme }) => theme.colors.secondary};;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.secondary};
  &:hover { color: ${({ theme }) => theme.colors.secondaryHover}; };
  &:active { color: ${({ theme }) => theme.colors.secondaryActive}; } 
  &:disabled { color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

export const SquareButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 500;
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #B7B7B7;
  border-radius: 6px;
`;