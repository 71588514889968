import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { 
  ActiveFiltersLabel,
  FilterItem,
  RemoveFilterButton,
  ClearFiltersButton
} from '../../styles/styled-components/ActiveFilters';
import { paramsAreEmpty, hasOnlyAnyValues, anyValuesCount } from '../../utils/users';
import { Roles, FilterValue } from '../../constants/usersFilters';
import xIcon from '../../../assets/svg/common/x-icon.svg';

const ActiveFilters = () => {
  const [ showFilters, setShowFilters ] = useState(false);
  const [ activeFilters, setActiveFilters ] = useState([]);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      params.delete('search');

      if (!hasOnlyAnyValues(params)) {
        const newFilters = Array.from(params.keys()).map(role => {
          const value = params.get(role);
          return { role, value };
        });

        setShowFilters(true);
        setActiveFilters(newFilters);
      }
      else {
        setActiveFilters([]);
        setShowFilters(false);
      }
    }
    else {
      setActiveFilters([]);
      setShowFilters(false);
    }
  }, [ location ]);


  const handleDeleteFilter = (role) => {
    const params = new URLSearchParams(location.search);

    
    if (anyValuesCount === 2) {
      Roles.forEach(role => params.delete(role));
    }
    else {
      params.set(role, FilterValue.ANY);
    }

    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleClearFilters = () => {
    const params = new URLSearchParams(location.search);

    Roles.forEach(role => params.delete(role));

    if (paramsAreEmpty(params)) {
      history.push(location.pathname);
    }
    else {
      history.push(`${location.pathname}?${params.toString()}`);
    }
  };

  if (!showFilters) {
    return null;
  }
  
  return (
    <div className='px-3 py-2'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <div className='d-flex align-items-center'>
          <ActiveFiltersLabel className='mr-3'>{t('active_filters.active_filters_label')}:</ActiveFiltersLabel>
          <div className='d-flex'>
            {activeFilters.filter(({ value }) => value !== FilterValue.ANY).map(({ role, value }) => (
              <div key={role} className='d-flex align-items-center mr-3'>
                <FilterItem className='mr-1'>
                  {t(`roles.${role}`)}{' '}({t(`active_filters.${value}`)})
                </FilterItem>
                <RemoveFilterButton onClick={() => handleDeleteFilter(role)}>
                  <img src={xIcon} alt='delete icon' />
                </RemoveFilterButton>
              </div>
            ))}
          </div>
        </div>
        <ClearFiltersButton onClick={handleClearFilters}>
          {t('active_filters.clear_filters_button')}
        </ClearFiltersButton>
      </div>
      <hr className='w-100' />
    </div>
  );
};

export default ActiveFilters;
