import { UserRole } from './roles';
import contractIcon from '../../assets/svg/sidebar/contract.svg';
import contractActiveIcon from '../../assets/svg/sidebar/contract-active.svg';
import invitationsIcon from '../../assets/svg/sidebar/invitations.svg';
import invitationsActiveIcon from '../../assets/svg/sidebar/invitations-active.svg';
import offersIcon from '../../assets/svg/sidebar/offers.svg';
import offersActiveIcon from '../../assets/svg/sidebar/offers-active.svg';
import regimenIcon from '../../assets/svg/sidebar/regimen.svg';
import regimenActiveIcon from '../../assets/svg/sidebar/regimen-active.svg';
import servicesIcon from '../../assets/svg/sidebar/services.svg';
import servicesActiveIcon from '../../assets/svg/sidebar/services-active.svg';
import usersIcon from '../../assets/svg/sidebar/users.svg';
import usersActiveIcon from '../../assets/svg/sidebar/users-active.svg';
import settlementsIcon from '../../assets/svg/sidebar/settlements.svg';
import settlementsActiveIcon from '../../assets/svg/sidebar/settlements-active.svg';
import permissionsIcon from '../../assets/svg/sidebar/permissions.svg';
import permissionsActiveIcon from '../../assets/svg/sidebar/permissions-active.svg';

export const navItems = [
  {
    label: 'nav_items.contract',
    path: 'contract',
    allowedRoles: [ UserRole.OWNER, UserRole.FINANCIAL_ADMIN ],
    icon: contractIcon,
    activeIcon: contractActiveIcon
  },
  {
    label: 'nav_items.services',
    path: 'services',
    allowedRoles: [ UserRole.EVERYONE ],
    icon: servicesIcon,
    activeIcon: servicesActiveIcon
  },
  {
    label: 'nav_items.offers',
    path: 'offers',
    allowedRoles: [ UserRole.EVERYONE ],
    icon: offersIcon,
    activeIcon: offersActiveIcon
  },
  {
    label: 'nav_items.users',
    path: 'users',
    allowedRoles: [ UserRole.USER_ADMIN, UserRole.FINANCIAL_ADMIN, UserRole.OWNER ],
    icon: usersIcon,
    activeIcon: usersActiveIcon
  },
  {
    label: 'nav_items.settlements',
    path: 'settlements',
    allowedRoles: [ UserRole.OWNER, UserRole.FINANCIAL_ADMIN ],
    icon: settlementsIcon,
    activeIcon: settlementsActiveIcon,
    allowedSpaceTypes: [ 'science' ],
  },
  {
    label: 'nav_items.regimens',
    path: 'regimens',
    allowedRoles: [ UserRole.EVERYONE ],
    icon: regimenIcon,
    activeIcon: regimenActiveIcon
  },
  {
    label: 'nav_items.invitations',
    path: 'invitations',
    allowedRoles: [ UserRole.USER_ADMIN ],
    icon: invitationsIcon,
    activeIcon: invitationsActiveIcon
  },
  {
    label: 'nav_items.permissions',
    path: 'permissions',
    allowedRoles: [ UserRole.END_USER ],
    icon: permissionsIcon,
    activeIcon: permissionsActiveIcon
  },
];

export const paths = navItems.map(({ path }) => path);
