import styled from 'styled-components';
import { Row, Badge } from 'reactstrap';
import { BiCheckCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import NotificationStateValues from '../../constants/notifications';
import { ReactComponent as NotificationRing } from '../../../assets/svg/notifactions/notification-ring.svg';

export const AttachmentBadge = styled(Badge)`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  border-width: 1px;
  vertical-align: middle;
  line-height: normal;
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const StyledIcon = styled.svg`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  path {
    stroke: ${({ theme }) => theme.colors.link};
  };
`;

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  gap: 7px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  ${({ theme }) =>  `
    font-size: ${theme.fontSizes.sm};
    color: ${theme.colors.link};
    font-weight: ${theme.fontWeights.medium};
    background-color: ${theme.colors.white};
  `};

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
    ${StyledIcon} {
      path {
        stroke: ${({ theme }) => theme.colors.linkHover};
      };
    };
  }

  &:active {
    color: ${({ theme }) => theme.colors.text.blueMost};
    ${StyledIcon} {
      path {
        stroke: ${({ theme }) => theme.colors.text.blueMost};
      };
    };
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.veryDarkGray};
    ${StyledIcon} {
      path {
        stroke: ${({ theme }) => theme.colors.text.veryDarkGray};
      };
    };
  }
`;

export const NotificationsHeader = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const NotificationsText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-bottom: .5rem;
`;

export const UnreadNotficationsCount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const getBackgroundColorValue = (status, defaultColor = '#fff') => {
  switch (status) {
  case NotificationStateValues.CHECKED:
    return 'rgba(15, 161, 255, 0.1)';
  case NotificationStateValues.UNREAD:
    return '#F4F7F9';
  case NotificationStateValues.READ:
    return '#fff';
  default:
    return defaultColor;
  }
};

export const NotificationRow = styled(Row)`
  background-color: ${({ status }) => getBackgroundColorValue(status)};
`;

export const BlueCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: ${({ size }) => size === 'sm' ? '18px' : '64px' };
  height: ${({ size }) => size === 'sm' ? '18px' : '64px' };
  border-radius: 50%;
`;

export const CheckIcon = styled(BiCheckCircle)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.text.grey4};
`;

export const NotificationStatus = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.darkGrey};
`;

export const NotificationDate = styled.small`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.grey2};
`;

export const NotficationTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.black};
`;

export const NotficationSender = styled.h3`
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.primary};
`;

export const UndecoratedLink = styled(Link)`
  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

export const NotificationIndicator = styled.div`
    position: absolute;
    margin-left: -13px;
    margin-top: -2px;
    height: 18px;
    width: 18px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.colors.alerts.red};
    color: white;
    font-size: 12px;
    border-radius: 50%;
`;

export const NotificationIndicatorContainer = styled.span`
  height: 30px;
`;

export const CheckboxCol = styled.div`
  flex-basis: 4%;
`;

export const LogoCol = styled.div`
  flex-basis: 8%;
`;

export const TextCol = styled.div`
  flex-basis: 55%;
  cursor: pointer;
`;

export const ButtonCol = styled.div`
  flex-basis: 12%;
`;

export const ChevronCol = styled.div`
  flex-basis: 9%;
`;

export const LoadMoreButton = styled.button`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: none;
  padding: 20px;
  color: ${(props) => props.theme.colors.primary};
  background-color: white;
  font-size: 14px;
`;

export const NotificationsButton = styled(NotificationRing)`
  height: 30px;
  width: 30px;
  color: ${(props) => props.theme.colors.text.black};
  ${({ theme, disabled }) => disabled && `
    color: ${theme.colors.text.grey1_5}
  `}
`;

export const DividerElem = styled.div`
  height: 1px;
  width: 96%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.text.greyDivider};
`;


export const EmptyNotis = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 61px;
`;

export const ImgElem = styled.img`
   width: 100%;
   max-width: 320px;
   margin: 0 auto;
`;

export const TitleText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.lessDarkGrey};
  margin-top: 25px;
  margin-bottom: 6px;
`;

export const SubTitleText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.grey1_5};
`;

export const LinkButton = styled.button`
  border: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0;
`;
