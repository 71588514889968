import styled from 'styled-components';
import { Col, Card } from 'reactstrap';

export const StyledCol = styled(Col)`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 25%;
`;

export const StyledCard = styled(Card)`
  border-radius: 5px; 
  min-height: 700px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1), 8px 5px 14px rgba(196, 196, 196, 0.2);
  margin-bottom: 1rem;
`;

export const ContractIconContainer = styled.div`
  height: 140px;
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ContractIcon = styled.img`
  margin: 1rem 0;
  max-width: 140px;
  max-height: 140px;
`;

export const ActionBlock = styled.div`
  height: 3.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledHeader = styled.h2`
  height: 30px;
  color: ${(props) => props.disabled ? props.theme.colors.text.grey1_5 : props.theme.colors.text.black};
`;

export const GreyContainer = styled.div`
  color: ${(props) => props.disabled ? props.theme.colors.text.grey1_5 : props.theme.colors.text.black};
  padding: 0 1rem;
`;

export const DisabledMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.background.blue};
  width: 100%;
  padding: 1rem 0;
`;

export const DiabledMessageText = styled.p`
  margin-bottom: 0;
  margin-left: 1rem;
  color: ${props => props.theme.colors.text.blue};
`;
