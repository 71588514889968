import styled from 'styled-components';

import { BasicButton } from './GlobalStyles';

export const Popover = styled.div`
  position: fixed;
  top: ${({ top }) => `${top}px`};
  right: ${({ right }) => `${right}px`};
  width: 230px;
  height: auto;
  background-color: ${({ theme }) => theme.colors.background.white};
  z-index: 1100;
  border-radius: 5px;
`;

export const Username = styled.h6`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Email = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.grey3};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;

export const MenuLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.text.grey3};
`;

export const MenuItem = styled.li`
  a {
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin: 0px -24px   ;
    padding: 0.4rem 24px;
    display: block;
    text-decoration: none;
    &:hover { 
        background: ${({ theme }) => theme.colors.background.greyHover}; 
      }
    &:active { 
        background: ${({ theme }) => theme.colors.background.greyActive}; 
      }
  }
`;

export const LogoutButton = styled(BasicButton)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.veryDarkGray};
  padding: 0;
  &:hover { 
        text-decoration: underline; 
      }
`;
