import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { ClampedTextField, StyledButton } from '../styles/styled-components/ClampedTextField';

const CollapsableTextField = ({ children, numOfLines }) => {
  const { t } = useTranslation();
  const [ clamped, setClamped ] = useState(true);
  const [ showButton, setShowButton ] = useState(true);
  const containerRef = useRef(null);
  const handleClick = () => setClamped(!clamped);

  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        const hadClampClass = containerRef.current.classList.contains("clamp");
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Clamp class must be added to check if the text is longer than set number of lines
        // and thus it is necessary to show the button.
        // This is to deal with resizing of the window.
        setShowButton(hasClamping(containerRef.current));
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [ containerRef ]);

  return (
    <>
      <ClampedTextField
        alwaysClamped={false}
        numOfLines={numOfLines}
        className={clamped && 'clamp'}
        ref={containerRef}
      >
        {children}
      </ClampedTextField>
      <StyledButton onClick={handleClick} isHidden={showButton ? 0 : 1}>
        {t(`read_${clamped ? "more" : "less"}`)}
      </StyledButton>
    </>
  );
};

CollapsableTextField.propTypes = {
  children: PropTypes.node.isRequired,
  numOfLines: PropTypes.number.isRequired,
};

export default CollapsableTextField;