import { createSelector } from 'reselect';

import { getUsersCountByRole } from '../utils/users';

export const selectUsersCountByRole = createSelector(
  (state) => state.users.get('allUsers').get('data'),
  (_, role) => role,
  (users, role) => {
    return getUsersCountByRole(users.items, role);
  }
);

export const selectFilteredUsersCountByRole = createSelector(
  (state) => state.users.get('filteredUsers'),
  (_, role) => role,
  (users, role) => {
    return users.filter(({ roles }) => roles.includes(role)).size;
  }
);

export const selectSpaceById = createSelector(
  (state) => state.spaces.spaceList.get('data'),
  (_, spaceId) => spaceId,
  (spaces, spaceId) => {
    const space = spaces.find(({ id }) => id === spaceId);
    return space ? space : null;
  }
);

export const selectRolesBySpaceId = createSelector(
  (state) => state.spaces.ownRoles.get('data') || [],
  (_, spaceId) => spaceId,
  (ownRoles, spaceId) => {
    return ownRoles.filter(({ space_id }) => space_id === spaceId).map(({ role }) => role);
  }
);
