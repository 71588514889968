import styled from 'styled-components';

export const getElemColor = (theme, color, defaultColor = theme.colors.text.black) => {
  switch (color) {
  case 'primary':
    return theme.colors.primary; //#0FA1FF
  case 'grey2':
    return theme.colors.text.grey2; //#808080
  case 'lessDarkGrey':
    return theme.colors.text.lessDarkGrey; //#585858
  case 'greyTitle':
    return theme.colors.text.greyTitle; //#848484
  case 'blueLink':
    return theme.colors.link; //#0078D4
  case 'blueLink2':
    return theme.colors.text.blueMore; //#006FC4
  case 'red':
    return theme.colors.alerts.red; //#D20724
  case 'disableText':
    return theme.colors.text.grey1_5; //#777676
  default:
    return defaultColor; //#000000
  }
};

export const getElemWeight = (theme, fweight, defaultWeight = theme.fontWeights.medium) => {
  switch (fweight) {
  case 'normal':
    return theme.fontWeights.normal; //400
  case 'bold':
    return theme.fontWeights.bold; //700
  default:
    return defaultWeight; //500
  }
};

export const getFontSize = (theme, fsize, defaultSize = theme.fontSizes.md) => {
  switch (fsize) {
  case 'xs':
    return theme.fontSizes.xs; //12px
  case 'sm':
    return theme.fontSizes.sm; //14px
  case 'lg':
    return theme.fontSizes.lg; // 18px
  case 'xl':
    return theme.fontSizes.xl; // 20px
  default:
    return defaultSize; //16px
  }
};

export const ElemDiv = styled.div`
  font-weight: ${(props) => getElemWeight(props.theme, props.fweight)};
  color: ${(props) => getElemColor(props.theme, props.fcolor)};
  font-size: ${(props) => getFontSize(props.theme, props.fsize)};
`;

export const TextP = styled.p`
  font-weight: ${(props) => getElemWeight(props.theme, props.$fweight)};
  color: ${(props) => getElemColor(props.theme, props.$fcolor)};
  font-size: ${(props) => getFontSize(props.theme, props.$fsize)};
`;

export const TextSpan = styled.span`
  font-weight: ${(props) => getElemWeight(props.theme, props.$fweight)};
  color: ${(props) => getElemColor(props.theme, props.$fcolor)};
`;

export const UnderlineText = styled.span`
  text-decoration: underline;
`;