import React from 'react';
import { ModalBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { 
  StyledModal, 
  Username, 
  Email, 
  Label, 
  Value, 
  Button, 
  XIcon
} from '../styles/styled-components/UserDetailsModal';
import UserAvatar from './UserAvatar';
import UserRolesList from './UserRolesList';

const UserDetailsModal = ({ isOpen, toggle, user }) => {
  const { t } = useTranslation();

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} size='xs'>
      <ModalBody>
        <div className='d-flex justify-content-end'>
          <Button onClick={toggle}>
            <XIcon />
          </Button>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='mb-2'>
              <UserAvatar 
                username={`${user.first_name} ${user.surname}`} 
                width='4rem' 
                height='4rem'
                disabledTooltip
              />
            </div>
            <Username>{user.first_name}{' '}{user.surname}</Username>
            <Email>{user.email}</Email>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center mt-4 w-100'>
            <Row className='w-100'>
              <Col xs='5'>
                <Label>{t('user_details_modal.login')}:</Label>
              </Col>
              <Col xs='7'>
                <Value>{user.user_name}</Value>
              </Col>
            </Row>
            <Row className='w-100'>
              <Col xs='5'>
                <Label>{t('user_details_modal.roles')}:</Label>
              </Col>
              <Col xs='7'>
                <Value>
                  <UserRolesList roles={user?.roles}/>
                </Value>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>
    </StyledModal>
  );
};

UserDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserDetailsModal;
