import styled from 'styled-components';
import { Card } from 'reactstrap';

export const StyledCard = styled(Card)`
  background-color: 
    ${({ theme }) => theme.colors.background.white};
  padding: 1.5rem;
`;

export const StyledHr = styled.hr`
  margin-top: 12px;
  margin-bottom: 8px;
`;
