import styled from 'styled-components';

export const CalendarWidgetContainer = styled.div`
  position: relative;
`;

export const CalendarTitle = styled.p`
  margin-bottom: 8px;
`;

export const Icon = styled.img`
  right: 20px;
  position: absolute;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  pointer-events: none;
`;