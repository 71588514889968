import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

export const LandingArea = styled.div`
  min-height: calc(100vh - 128px);
  display: flex;
`;

export const PlaceholderImg = styled.img`
  max-height: calc(100% - 107px);
`;

export const PracelabLogoImg = styled.img`
  max-height: 63px;
  max-width: 202px;
  margin-bottom: 44px;
`;

export const HighRow = styled(Row)`
  flex-grow: 1;
`;

export const LeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const RightCol = styled(Col)`
  background-color: #EDF5FF;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LeftCenteredContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 133px 0 0 111px;
  width: 464px;
  & h1 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.xl5};
    margin-right: 4%;
    line-height: 1.4;
  }
  & h2 {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin: 4% 4% auto 0;
    line-height: 21px;
  }
`;

export const RightCenteredContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Ribbon = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 44px;
`;
