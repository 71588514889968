import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { FixedSidebar } from '../styles/styled-components/Sidebar';
import plusIcon from '../../assets/svg/common/plus-2.svg';

const GlobalSidebar = () => {
  const { t } = useTranslation();
  return (
    <FixedSidebar>
      <Nav vertical className='p-4'>
        <div className='d-flex justify-content-center'>
          <Link to='/new_contract' className='btn btn-secondary text-decoration-none'>
            <div className='flex-center'>
              <div className='flex-center'>
                <img src={plusIcon} alt='plus icon' className='mr-3 w-15' />
                <span>{t('add_space')}</span>
              </div>
            </div>
          </Link>
        </div>
      </Nav>
    </FixedSidebar>
  );
};

export default GlobalSidebar;
