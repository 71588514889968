import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { UserRole } from '../constants/roles';

const UserRolesList = ({ roles }) => {
  const { t } = useTranslation();
  const sortedRoles = Object.keys(UserRole);

  const userRoles = sortedRoles.filter(role => roles.includes(role));

  return (
    <span>
      {
        userRoles.map(role => (<span key={role}>{t(`roles.${role.toLowerCase()}`)}</span>))
          .flatMap((value, index, array) => array.length - 1 !== index ? [ value, ", " ] : value)
      }
    </span>
  );
};

UserRolesList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default UserRolesList;
