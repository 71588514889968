import styled from 'styled-components';
import { Row } from 'reactstrap';

export const LineRow = styled(Row)`
  border-top: 1px solid #dee2e6;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey4};
  font-size: ${props => props.theme.fontSizes.sm};
  padding: 0;
  margin: 0;
`;