import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, Tooltip } from 'reactstrap';
import { GoPrimitiveDot } from 'react-icons/go';
import { FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import folderIcon from '../../assets/svg/common/folder.svg';
import useContractState from '../hooks/useContractState';
import Loading from '../../main/components/loading';
import { getSpaceContract } from '../actions/contractActions';
import { UndecoratedLink } from '../styles/styled-components/Link';
import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';
import backendTranslation from './../utils/backendTranslation';

const ContractDetails = ({ spaceId, link=false }) => {
  const dispatch = useDispatch();

  const {
    contractDetails,
    contractDetailsLoading,
  } = useContractState();

  useEffect(() => {
    dispatch(getSpaceContract(spaceId));
  }, [ dispatch, spaceId ]);

  const { t } = useTranslation();
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  const [ title, setTitle ] = useState(null);

  useEffect(() => {
    if (contractDetails?.type) {
      axios.get(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories/?reference_id=${contractDetails.type}`)
        .then(response => {
          setTitle(response?.data?.items?.[0]?.title);
        });
    }
  }, [ contractDetails?.type ]);


  if (contractDetailsLoading) 
    return <Loading />;

  if (!contractDetails)
    return <p>{t('space_contract.no_contract_for_space')}</p>;

  const {
    number,
    state,
    create_date,
    end_date,
    topic
  } = contractDetails;

  const diffTime = new Date(end_date) - new Date();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  const wrapInLink = (children) => {
    if (link)
      return <UndecoratedLink to={`/spaces/${spaceId}/contract`}>
        {children}
      </UndecoratedLink>;
    return children;
  };

  return wrapInLink(
    <Card className='my-3'>
      <CardBody className='p-4'>
        <div className='d-flex justify-content-between align-items-end'>
          <div className='d-flex align-items-center'>
            <img src={folderIcon} alt='folderIcon' className='mr-3' />
            <div>
              <p className='m-0 font-xs'>{backendTranslation(title)}</p>
              <div className='d-flex align-items-center'>
                <h4 className='mr-2 font-md fontWeight-bold m-0'>
                  {t('contract')} nr {number}
                </h4>
                {diffDays<30 && 
                <>
                  <FiAlertCircle id='tooltip-alert' style={{ fontSize: '1.5rem', color: 'red' }} />
                  <Tooltip 
                    placement='top-start' 
                    isOpen={tooltipOpen} 
                    target={'tooltip-alert'} 
                    toggle={() => setTooltipOpen(prev => !prev)}
                  >
                    {t('space_contract.end_date_alert')}
                  </Tooltip>
                </>}
              </div>
            </div>
          </div>
          <div>
            <span className='font-weight-bold mr-1'>Status:</span>
            <span>
              {t(`contract_states.${state}`)}{' '}
              {state === 'ACTIVE' && (
                <GoPrimitiveDot style={{ color: 'green', fontSize: '1.5rem' }} />
              )}
            </span>
          </div>
        </div>
        <hr className='mx-12' />
        <div>
          <div className='font-sm'>
            <span className='font-weight-bold mr-1'>
              {t('space_contract.duration_of_contract')}:
            </span>
            <span>
              {create_date}/{end_date}
            </span>
          </div>
          <div>
            <span className='font-weight-bold mr-1'>
              {t('space_contract.topic')}:
            </span>
            <span>{topic}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

ContractDetails.propTypes = {
  spaceId: PropTypes.string.isRequired,
  link: PropTypes.bool
};

export default ContractDetails;
