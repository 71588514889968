import styled from 'styled-components';
import { getElemWeight, getFontSize, getElemColor } from './TextElem';

export const TextNoData = styled.p`
    font-weight: ${(props) => getElemWeight(props.theme, 'normal')};
`;

export const SectionNoData = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ImgContainer = styled.img`
    width: 330px;
`;

export const TitleNewData = styled.p`
    font-weight: ${(props) => getElemWeight(props.theme, 'bold')};
    color: ${(props) => getElemColor(props.theme, 'lessDarkGrey')};
    margin-top: 30px;
    margin-bottom: 8px;
    text-align: center;
`;

export const SubTitleNewData = styled.p`
    font-weight: ${(props) => getElemWeight(props.theme, 'normal')};
    font-size: ${(props) => getFontSize(props.theme, 'sm')};
    color: ${(props) => getElemColor(props.theme, 'lessDarkGrey')};
`;