import styled, { css } from 'styled-components';

export const ClampedTextField = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  min-height: ${props => 1.5 * props.numOfLines}rem;
  ${props => !props.alwaysClamped && '&.clamp {'}
    -webkit-line-clamp:  ${props => props.numOfLines}; /* number of lines to show */
    line-clamp:  ${props => props.numOfLines}; 
    -webkit-box-orient: vertical;
  ${props => !props.alwaysClamped && '}'}
`;

ClampedTextField.defaultProps = {
  alwaysClamped: true,
  numOfLines: 3
};

export const StyledButton = styled.button`
  visibility: ${props => props.isHidden ? "hidden" : "visible"};
  padding-left: 0;
  font-size: ${props => props.theme.fontSizes.sm};
  background-color: ${props => props.theme.colors.background.white};
  color: ${props => props.theme.colors.text.grey2};
  border: 0;
`;

export const ClampedTitleCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0;
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  -webkit-box-orient: vertical;
  height: 46px;
  min-width: 210px;
  margin: auto;
`;

export const ClampedTitle = styled.h5`
  ${ClampedTitleCss}
  margin: 0;
  height: auto;
`;