/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Container } from '../styles/styled-components/GlobalStyles';
import { DividerElem, EmptyNotis, ImgElem, NotificationsHeader, NotificationsText, UnreadNotficationsCount, SubTitleText, TitleText, StyledIcon } from '../styles/styled-components/Notifications';
import {
  SearchInput,
  SearchInputContainer,
  SearchForm,
  SearchIcon,
  SearchButton,
} from '../styles/styled-components/SearchUsers';
import { CustomButton, LoadMoreButton } from '../styles/styled-components/Notifications';
import IconButton from '../components/IconButton';
import Notfication from '../components/Notification';
import Checkbox from '../components/Checkbox';
import useAxios from '../hooks/useAxios';
import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';
import Loading from '../../main/components/loading';
import NotificationStateValues from '../constants/notifications';
import useUnreadNotifications from '../hooks/useUnreadNotifications';
import { OutlineButton } from '../styles/styled-components/OutlineButton';
import * as notify from '../../main/utils/notify';
import { ChevronIconPermament } from '../styles/styled-components/Accordion';

import imgNoNotification from '../../assets/svg/notifactions/no-notifications.svg';
import { ReactComponent as ShowAll } from '../../assets/svg/notifactions/showAll.svg';

const Notifications = () => { 
  const count = 10;
  const [ params, setParams ] = useState({
    first: 1,
    max: count,
    sort: 'created:desc'
  });
  const [ notifications, setNotifications ] = useState([]);
  const [ openedNotifications, setOpenedNotifications ] = useState([]);
  const [ find, setFind ] = useState('');
  const [ selectAll, setSelectAll ] = useState(false);
  const [ selectedNotfications, setSelectedNotifications ] = useState([]);
  const [ isUpdating, setIsUpdating ] = useState(false);
  const [ showAllNoti, setShowAllNoti ] = useState(true);
  const [ rededNotification, setRededNotifications ] = useState(0);
  const [ noRefresh, setNoRefresh ] = useState(1);
  const { t } = useTranslation();

  const { isLoading, data } = useAxios(`${__env.USERS_DATA_API_URL}api/users-data/notifications/`, { params });
  const [ dataMatchingCount, setDataMatchingCount ] = useState();

  const unreadNotifications = useUnreadNotifications();
  

  const unreadNotificationsCount = unreadNotifications.data ? 
    unreadNotifications.data.matching_count : 
    unreadNotifications.isLoading ?
      t('notifications.loading') :
      t('notifications.error'); 

  const onClickShowAllUnreadNoti = (isClickShowAll) => {
    if (isClickShowAll === showAllNoti) {
      return null;
    }
    setShowAllNoti(isClickShowAll);
    setSelectedNotifications([]);
    setNoRefresh(1);
    setRededNotifications(0);
    setOpenedNotifications([]);
    if (isClickShowAll) {
      setParams(params => {
        const { state, ...rest } = params;
        return { ...rest, first: 1, max: 10 };
      });
    }
    else {
      setParams(params => ({
        ...params,
        first: 1,
        max: 10,
        state: 'UNREAD'
      }));
      if (unreadNotificationsCount === 0) {
        notify.info("", t('notifications.empty_unread'));
      }
    }
  };
  
  const onCollapseToggle = useCallback((id, state) => {
    if (openedNotifications.includes(id)) {
      setOpenedNotifications(prev => prev.filter(item => item !== id));
    }
    else {
      if (state === NotificationStateValues.UNREAD) {
        if (!showAllNoti) setRededNotifications(prev => prev + 1);
        axios.put(`${__env.USERS_DATA_API_URL}api/users-data/notifications/${id}/state/`, { read: true }).then(() => {
          setNotifications(prev => 
            prev.map(notification => 
              notification.id === id 
                ? { ...notification, state: NotificationStateValues.READ } 
                : notification
            ));
          unreadNotifications.refetch();
        });
      }
      setOpenedNotifications(prev => [ ...prev, id ]);
    }
  }, [ openedNotifications, showAllNoti, unreadNotifications ]);

  const onOpenAllNotifications = useCallback(() => {
    notifications.forEach(notification => {
      notification.state !== NotificationStateValues.READ && onCollapseToggle(notification.id, notification.state);
    });  
    setOpenedNotifications(() => notifications.map(item => item.id));  
  }, [ notifications, onCollapseToggle ]);

  const refreshNotifications = useCallback(() => {
    setIsUpdating(false);
    unreadNotifications.refetch();
    setParams({
      ...params,
      first: 1,
      max: params.first-1 + params.max,
    });

    setSelectedNotifications([]);
  },[ params, unreadNotifications ] );  

  const updateNotifications = useCallback(async (notifications, newValue) => {
    setIsUpdating(true);
    Promise.all(notifications.map(notificationId => {
      return axios.put(`${__env.USERS_DATA_API_URL}api/users-data/notifications/${notificationId}/state/`, { read: newValue });
    })).then(() => {

      setNotifications(prev => 
        prev.map(item => 
          (notifications.includes(item.id) ? 
            { ...item, state: newValue ? 
              NotificationStateValues.READ : 
              NotificationStateValues.UNREAD 
            } : item)));

      setIsUpdating(false);
      setNoRefresh(1);
      unreadNotifications.refetch();
      if (!showAllNoti && newValue && notifications.length === unreadNotificationsCount) {
        setParams(params => ({
          ...params,
          state: 'UNREAD'
        }));
  
        notify.info("", t('notifications.empty_unread'));
      }
      else {
        refreshNotifications();
      }

      setSelectedNotifications([]);
    }).catch(() => {
      refreshNotifications();
    });
  }, [ refreshNotifications, unreadNotifications, t, unreadNotificationsCount, showAllNoti ]);

  const onChangeNotificationStatus = useCallback((newValue) => {
    setOpenedNotifications([]);
    setRededNotifications(0);
    updateNotifications(selectedNotfications, newValue);
  }, [ selectedNotfications, updateNotifications ]);

  const onNotificationSelectionChange = (e, id) => {
    if (e.target.checked) {
      setSelectedNotifications(prev => [ ...prev, id ]);
    }
    else {
      setSelectedNotifications(prev => prev.filter(item => item !== id));
    }
  };

  const selectAllNotifications = () => {
    if (!selectAll) {
      setSelectedNotifications(() => notifications.map(item => item.id));
    }
    else {
      setSelectedNotifications([]);
    }
  };

  const onDeleteNotification = useCallback((id) => {
    setIsUpdating(true);
    axios.delete(`${__env.USERS_DATA_API_URL}api/users-data/notifications/${id}/`).then(() => {
      refreshNotifications();
    });
  }, [ refreshNotifications ]); 

  const onDeleteNotifications = useCallback(() => {
    setIsUpdating(true);
    Promise.all(selectedNotfications.map(notificationId => {
      return axios.delete(`${__env.USERS_DATA_API_URL}api/users-data/notifications/${notificationId}/`);
    })).finally(() => {
      refreshNotifications();
    });
  }, [ selectedNotfications, refreshNotifications ]);



  const onOpenAllNotificationsRef = useRef(onOpenAllNotifications);

  useEffect(() => {
    if (data) {
      setNotifications(prev => [ ...prev, ...data.items ]);
    }
  }, [ data ]);
  
  useEffect(() => {
    if (params.first === 1 && data && noRefresh){
      setNotifications(data.items);
      setDataMatchingCount(data.matching_count);
    }
  }, [ data, params, noRefresh ]);

  useEffect(() => {
    if (selectedNotfications.length !== notifications.length || selectedNotfications.length === 0)
      setSelectAll(false);
    else 
      setSelectAll(true);
  }, [ selectedNotfications, notifications, setSelectAll ]);

  useEffect(() => {
    onOpenAllNotificationsRef.current = onOpenAllNotifications;
  }, [ onOpenAllNotifications ]);

  return (
    <Container>
      <NotificationsHeader>{t('notifications.notifications_header')}</NotificationsHeader>
      <div className="py-3">
        <NotificationsText>
          {t('notifications.unread_notifications')}: <UnreadNotficationsCount>{unreadNotificationsCount}</UnreadNotficationsCount>
        </NotificationsText>
      </div>
      <div>
        <Card>
          <div className="pt-4 pl-4 pr-4 pb-2">
            <SearchForm onSubmit={e => {
              e.preventDefault();
              setParams({
                first: 1,
                max: count,
                search: find
              });
            }
            }>
              <SearchInputContainer hideBorderLeft={false}>
                <SearchButton disabled={notifications.length===0}>
                  <SearchIcon/>
                </SearchButton>
                <SearchInput disabled={notifications.length===0}
                  onChange={(e) => setFind(e.target.value)}
                  value={find}
                  type='text'
                  placeholder={t('notifications.search_notifications')}
                />
              </SearchInputContainer>
            </SearchForm>            
          </div>
          <div className="d-flex my-3 px-4">
            <OutlineButton 
              className="mr-2" 
              isChecked={showAllNoti} 
              onClick={() => onClickShowAllUnreadNoti(true)}>
              {t('notifications.all')}
            </OutlineButton>
            <OutlineButton 
              isChecked={!showAllNoti} 
              onClick={() => onClickShowAllUnreadNoti(false)}>
              {t('notifications.unread')}
            </OutlineButton>
            <div className='d-flex align-items-center ml-auto' disabled={notifications.length===0}>
              {notifications.length &&
                <CustomButton 
                  onClick={() => onOpenAllNotifications()} 
                  disabled={notifications.length === openedNotifications.length}
                >
                  <StyledIcon as={ShowAll} />
                  {t('notifications.show_all')}
                </CustomButton>
              }
            </div>
          </div>
          <DividerElem />
          <div className="d-flex align-items-center justify-content-between m-4">
            <div className='d-flex py-2'>
              <label className='mb-0 mr-3'>
                <Checkbox 
                  id='check-all'
                  name='check-all'
                  checked={selectAll}
                  onChange={selectAllNotifications}
                  disabled={notifications.length===0}
                />
              </label>
              <Label htmlFor='check-all' className='mb-0 font-sm'>{t('notifications.select_all')}</Label>
            </div>
            <div className='d-flex align-items-center'>
              {selectedNotfications.length > 0 ?
                <div className='d-flex align-items-center'>
                  <IconButton
                    icon='check'
                    className='mr-3'
                    onClick={() => onChangeNotificationStatus(true)}
                  >
                    {t('notifications.mark_as_read')}
                  </IconButton>
                  <IconButton
                    icon='cancel'
                    className='mr-3'
                    onClick={() => onChangeNotificationStatus(false)}
                  >
                    {t('notifications.mark_as_unread')}
                  </IconButton>
                  <IconButton
                    icon='trash'
                    onClick={onDeleteNotifications}
                  >
                    {t('notifications.delete_selected')}
                  </IconButton>
                </div> : null}
            </div>
          </div>
          {(isLoading || isUpdating) && <Loading />}
          { notifications.length > 0 ?
            <ul className='list-unstyled'>
              {notifications.map((notification) => (
                <Notfication 
                  key={notification.id}
                  notification={notification}
                  checked={selectedNotfications.includes(notification.id)}
                  onSelectionChage={onNotificationSelectionChange} 
                  isOpen={openedNotifications.includes(notification.id)}
                  onCollapseToggle={onCollapseToggle}
                  onDelete={onDeleteNotification}
                />
              ))} 
            </ul>
            : 
            <EmptyNotis>
              <ImgElem src={imgNoNotification} alt=""/>
              <TitleText>
                {showAllNoti ? t('notifications.no_notification_title') : t('notifications.no_unreded_notification_title')}
              </TitleText>
              <SubTitleText>{t('notifications.no_notification_subtitle')}</SubTitleText>
            </EmptyNotis> }
          {data && dataMatchingCount > 10 &&
            <LoadMoreButton onClick={ () => {
              setNoRefresh(0);
              setParams({
                ...params,
                first: params.first + (params.max > 10 ? params.max : count) - rededNotification,
                max: count,
              });
              setRededNotifications(0);
              setDataMatchingCount(prev => prev - 10);
            }}>
              <span className='mr-3'>{t('notifications.load_more')}</span>
              <ChevronIconPermament/>
            </LoadMoreButton>
          }
        </Card>
      </div>
    </Container>
  );
};

export default Notifications;