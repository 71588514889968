import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';

import { UserIcon, SpaceName, RolesInfo } from '../styles/styled-components/UserRolesInfo';
import UserRolesList from './UserRolesList';

const UserRolesInfo = ({ roles, spaceName }) => {
  const { t } = useTranslation();

  return (
    <Card className='mb-4'>
      <CardBody>
        <div className='d-flex align-items-center'>
          <div className='mr-4'>
            <UserIcon />
          </div>
          <div>
            <RolesInfo>
              {t('space_users.your_roles')}:{' '}
              <UserRolesList roles={roles}/>
            </RolesInfo>
            <SpaceName>{spaceName}</SpaceName>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

UserRolesInfo.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  spaceName: PropTypes.string
};

export default UserRolesInfo;
