import React, { useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#0fa1ff'
  }
});

const LabelsWidget = (props) => {

  const { id, label, onChange, disabled, placeholder, value, required, uiSchema } = props;

  const { t } = useTranslation();

  const [ data, setData ] = useState([]);

  // Converts data array into string
  const formatData = value => {
    const filteredValue = value.filter(item => item.value.trim() !== '');

    setData(filteredValue);
    onChange(filteredValue?.map(item => item.value).join(', '));
  };

  // Creates data for createable select from string
  const formatValue = useCallback(value => {
    const splitted = value.split(',');

    let result = [];
    for (let i = 0; i < Math.min(splitted.length, uiSchema.maxItems); i++) {
      result.push({
        label: splitted[i],
        value: splitted[i],
        __isNew__: true
      });
    }
    return setData(result);
  }, [ uiSchema.maxItems ]);

  useEffect(() => value && formatValue(value), [ formatValue, value ]);

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '20px',
    })
  };

  const handleBlur = (event) => {
    if (event.target.value) {
      formatData([ ...data, { label: event.target.value, value: event.target.value, __isNew__: true } ]);
    }
  };

  return (
    <div>
      <label htmlFor={id} className='mb-2'>
        {label}
        {required && " *"}
      </label>
      <CreatableSelect 
        formatCreateLabel={value => `${t('process_form.offer.create')} "${value}"`}
        isMulti
        inputId={id}
        isDisabled={disabled}
        placeholder={placeholder}
        value={data}
        onChange={value => formatData(value)}
        noOptionsMessage={() => t('process_form.offer.create_new_one')}
        theme={theme}
        styles={styles}
        onBlur={handleBlur}
      />
    </div>
  );
};

LabelsWidget.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  uiSchema: PropTypes.object
};

export default LabelsWidget;
