import styled, { css }  from 'styled-components';
import { Button, Card } from 'reactstrap';

export const CardOffer = styled(Card)`
  padding: 32px;
`;

export const Info = styled.div`
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.secondaryDisabled};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const OfferButton = styled(Button)`
  margin-top: 30px;
  width: 120px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const OfferName = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 8px;
  line-height: 26px;

  div {
    width: 473px;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 18px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const Text = styled.p`
  font-size: ${({ theme, sm }) => sm ? theme.fontSizes.sm : theme.fontSizes.sm_md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.blackFocus};
  line-height: 21px;
  width: 642px;
  margin-bottom: 16px;
`;

export const ActiveButton = styled.button`
  height: 36px;
  width: 92px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.white};
  ${props => props.disabled 
    ? css`
      background-color: ${props.theme.colors.secondaryDisabled};
    `
    : css`
      background-color: ${props.theme.colors.secondary};
      &:hover{
        background-color: ${props.theme.colors.secondaryHover};
      }
      &:active{
        background-color: ${props.theme.colors.secondaryActive};
      }
    `}
  border-radius: 24px;
  border: none;

`;