import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Thead, Th, Td, PermissionsTable as Table } from '../../styles/styled-components/SpacePermissions';
import { hasLowerRole } from '../../utils/users';

const PermissionsTable = () => {
  const { t } = useTranslation();
  const allUsers = useSelector((state) => state.users.get('allUsers').get('data'));
  return (
    <Table>
      <Thead>
        <tr>
          <Th>{t('space_permissions.table_header.role')}</Th>
          <Th>{t('space_permissions.table_header.user')}</Th>
          <Th>{t('space_permissions.table_header.email')}</Th>
        </tr>
      </Thead>
      <tbody>
        {allUsers.items.sort(hasLowerRole).map(user => {
          return user.roles.map(role =>
            <tr key={`${role}_${user.email}`}>
              <Td>{t(`roles.${role.toLowerCase()}`)}</Td>
              <Td>
                {`${user.first_name} ${user.surname}`}
              </Td>
              <Td>{user.email}</Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PermissionsTable;
