import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';

const loginToAccess = ({ t }) => (
  <Alert color="warning">
    <FiLogIn className="mr-3" />
    {t('common:login_to_access')}
  </Alert>
);

export default withTranslation()(loginToAccess);

loginToAccess.propTypes = {
  t: PropTypes.func, //HOC
};
