import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router-dom';

import {
  FilterButtonText,
  FilterButton,
  SearchInputContainer,
  SearchForm,
  CaretIcon,
  SearchIcon,
  SearchContainer,
  SearchButton,
} from '../../styles/styled-components/SearchUsers';
import FiltersPopover from './FiltersPopover';
import Portal from '../../components/Portal';
import UsersComboBox from '../../components/UsersComboBox';
import { caretIcon } from '../../../assets/svg/settlements/icons';

const SearchUsers = () => {
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ filtersPopoverOpen, setFiltersPopoverOpen ] = useState(false);
  const [ popoverEntered, setPopoverEntered ] = useState(false);
  const [ coords, setCoords ] = useState({});
  const [ isInputActive, setIsInputActive ] = useState(false);
  const { t } = useTranslation();
  const btnRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    params.delete('search');
  
    if (searchQuery !== '') {
      params.set('search', searchQuery);
    }

    history.push(`${location.pathname}${params.toString() !== '' ? `?${params.toString()}` : ''}`);
    setSearchQuery('');
  };

  const updatePopoverCoords = useCallback(() => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x,
      top: rect.y + window.scrollY + 45
    });
  }, []);
  
  const handleOpen = () => {
    updatePopoverCoords();
    setFiltersPopoverOpen(prev => !prev);
  };
  
  const onCloseFiltersPopover = useCallback(() => setFiltersPopoverOpen(false), []);

  const toggleIsInputActive = useCallback(() => setIsInputActive(prev => !prev), []);

  return (
    <>
      <SearchContainer className='p-3'>
        <FilterButton onClick={handleOpen} open={filtersPopoverOpen} active={isInputActive} ref={btnRef}>
          <FilterButtonText>{t('filters')}</FilterButtonText>
          <CaretIcon open={filtersPopoverOpen} src={caretIcon}/>
        </FilterButton>
        <SearchForm onSubmit={handleSubmit}>
          <SearchInputContainer hideBorderLeft active={isInputActive}>
            <SearchButton type='submit' data-testid='search-user-button'>
              <SearchIcon />
            </SearchButton>
            <UsersComboBox 
              onInputChange={(input) => setSearchQuery(input)}
              onFocus={toggleIsInputActive}
              onBlur={toggleIsInputActive}
            />
          </SearchInputContainer>
        </SearchForm>
      </SearchContainer>
      <Portal>
        <CSSTransition
          in={filtersPopoverOpen}
          timeout={300}
          classNames='filters-popover'
          onEntered={() => setPopoverEntered(true)}
          onExited={() => setPopoverEntered(false)}
          mountOnEnter 
          unmountOnExit
        >
          <FiltersPopover 
            onClose={onCloseFiltersPopover}
            coords={coords} 
            entered={popoverEntered}
            updatePopoverCoords={updatePopoverCoords}
          />
        </CSSTransition>
      </Portal>
    </>
  );
};

export default SearchUsers;
