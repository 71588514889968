import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PaidIcon } from '../../../assets/svg/spaces/paid.svg';
import { ReactComponent as RejectedIcon } from '../../../assets/svg/spaces/rejected.svg';
import { ReactComponent as CheckingIcon } from '../../../assets/svg/spaces/checking.svg';

export const billingStatuses = {
  ACTIVE: "ACTIVE",
  IN_REVIEW: "IN_REVIEW",
  UPDATED: "UPDATED",
  ACCEPTED: "ACCEPTED",
  DENIED: "DENIED",
};

export const settlementStates = {
  PAID: "PAID",
  SETTLED: "SETTLED",
  PREPARED: "PREPARED",
  DONE: "DONE",
};

const Badge = ({ billingStatus, state }) => {
  const { t } = useTranslation();

  return (
    <div className="font-sm d-flex">
      {state === settlementStates.DONE && (
        <>
          <PaidIcon className="mr-2" />
          {t("reports.status.settled")}
        </>
      )}

      {billingStatus === billingStatuses.UPDATED && (
        <>
          <CheckingIcon className="mr-2" />
          {t("reports.status.updated")}
        </>
      )}

      {billingStatus === billingStatuses.DENIED && (
        <>
          <RejectedIcon className="mr-2" />
          {t("reports.status.fix_report")}
        </>
      )}

      {billingStatus === billingStatuses.IN_REVIEW && (
        <>
          <CheckingIcon className="mr-2" />
          {t("reports.status.checking")}
        </>
      )}

      {billingStatus === billingStatuses.ACTIVE && (
        <>
          <CheckingIcon className="mr-2" />
          {t("reports.status.submitted")}
        </>
      )}

      {state === settlementStates.PREPARED && !billingStatuses && (
        <>
          <CheckingIcon className="mr-2" />
          {t("reports.status.add_report")}
        </>
      )}
    </div>
  );
};

Badge.propTypes = {
  billingStatus: PropTypes.string,
  state: PropTypes.string,
};

export default Badge;
