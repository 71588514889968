import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Text, HR, CustomCard, CustomCardBody } from '../../styles/styled-components/Regimens';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import backendTranslation from './../../utils/backendTranslation';
import { __env } from '../../../envloader';
import useAxios from '../../hooks/useAxios';
import Loading from '../../../main/components/loading';
import { BtnTextBlue } from '../../styles/styled-components/Button';
import Breadcrumbs  from './Breadcrumbs';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { openFile } from '../../utils/downloadFilesFromIBIS';

const Regimens = () => {
  const { t } = useTranslation();
  const match = useRouteMatch('/spaces/:id/');
  const spaceImage = useSelector(state => state.spaces.spaceImg.get('data'));
  const { space } = useCurrentSpace(match);
  const { data: services, isLoading } = useSelector(state => state?.services?.servicesList?.toJS());
  
  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.regimens') }
  ]), [ space?.name, space?.id, t ]);

  const getRegimens = (type) => {
    const { regulations_url, title } = spaceImage?.find(e => e?.reference_id === type) || {};
    return (
      <>
        <Text>{backendTranslation(title)}</Text>
        <HR/>
        <BtnTextBlue style={{ padding: "1rem 0 0 0" }}>
          <a 
            href="/" 
            onClick={(e) => { e.preventDefault(); openFile(backendTranslation(regulations_url)); }}
            rel="noreferrer"
          >
            {t('regimen.title')}
          </a>
        </BtnTextBlue>
      </>
    );
  };

  const {
    data: spaceUserOffersData
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${match.params.id}/user_offers`);

  const uniqueServices = [ ...new Set(services?.map(item => item?.parameters?.bpm_params_offer_id?.value)) ];

  const renderComponent = (id) => {
    const offer = spaceUserOffersData?.items?.find(o => o?.id === id);

    if (offer?.regulations?.items?.length === 0 || !offer?.regulations?.items) {
      return null;
    }

    return (
      <CustomCardBody key={id}>
        <Text>{offer?.offer__name?.pl}</Text>
        <HR/>
        { offer?.regulations?.items?.map((item, index) => (
          <BtnTextBlue key={index} style={{ padding: "1rem 0 24px 0", margin: "0" }}>
            <a 
              href="/" 
              onClick={(e) => { e.preventDefault(); openFile(item?.url); }}
              rel="noreferrer"
            >
              {backendTranslation(item?.description) || item?.url}
            </a>
          </BtnTextBlue>
        ))  
        }
      </CustomCardBody>
    );
  };

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      {(!isLoading && spaceUserOffersData ) ?
        <>
          <Text className='mt-5'>{t('regimen.general_regulations')}</Text>
          <CustomCard>
            <CustomCardBody>
              {getRegimens(space?.type)}
            </CustomCardBody>
          </CustomCard>
          {uniqueServices?.length > 0 &&
            uniqueServices.some(id => renderComponent(id) !== null) &&
              <>
                <Text className='pt-4'>{t('regimen.conditions_of_services')}</Text>
                <CustomCard>
                  {uniqueServices.map(id =>
                    renderComponent(id)
                  )}
                </CustomCard>
              </>
          }
        </>
        :
        <Loading />
      }
    </>
  );
};
export default compose(
  ErrorBoundary((props) => props.t('regimen.error_boundary'))
)(Regimens);