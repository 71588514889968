import React from 'react';
import PropTypes from 'prop-types';
import { CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { __env } from '../../envloader';
import * as notify from '../../main/utils/notify';
import axios from '../../main/utils/axios/axiosInstance';
import { 
  StyledCol,
  StyledCard,
  ContractIcon,
  ContractIconContainer,
  ActionBlock,
  StyledHeader,
  DisabledMessage,
  DiabledMessageText,
  GreyContainer
} from '../styles/styled-components/ContractCategory';
import backendTranslation from '../utils/backendTranslation';
import CollapsableTextField from './CollapsableTextField';
import { multiLangType } from '../types';
import priceTagIcon from '../../assets/svg/sidebar/services-active.svg';

const ContractCategory = ({ contract }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const disabled = contract?.definition?.disabled ? 1 : 0;

  const onContractChoose = contract_type => () => {
    axios.post(
      __env.BPM_API_URL + 'contracts/contract_requests', 
      { contract_type }
    )
      .then((response) => {
        notify.info(
          t('contract_category.notifications.process_initiated_success_title'),
          t('contract_category.notifications.process_initiated_success_body')
        );
        history.push('/process_form/' + response.data.processName);
      });
  };

  return (
    <StyledCol data-testid='contract-category'>
      <StyledCard>
        <CardBody className='px-0 py-3'>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <StyledHeader disabled={disabled} className='text-center mb-3 font-md fontWeight-bold px-5'>{backendTranslation(contract?.title)}</StyledHeader>
            <ContractIconContainer>
              <ContractIcon src={contract.tile_icon} alt={`${backendTranslation(contract?.title)} - icon`} />
            </ContractIconContainer>
            <ActionBlock>
              {
                disabled ?
                  <DisabledMessage>
                    <img src={priceTagIcon} alt="" />
                    <DiabledMessageText>{t('contract_category.available_soon')}</DiabledMessageText>
                  </DisabledMessage>
                  :
                  <Button
                    color='primary'
                    className='my-1 px-5 py-1'
                    onClick={onContractChoose(contract.reference_id)}
                  >
                    {t('contract_category.create')}
                  </Button>
              }
            </ActionBlock>
          </div>
          <GreyContainer disabled={disabled}>
            <hr />
            <div className='mt-3 mb-3'>
              <div className='font-weight-bold'>{t('contract_category.for_who')}:</div>
              <div>
                <CollapsableTextField numOfLines={2}>
                  {backendTranslation(contract.target_group)}
                </CollapsableTextField>
              </div>
            </div>
            <div className="mb-3">
              <div className="font-weight-bold">{t('contract_category.price')}:</div>
              <div>
                <CollapsableTextField numOfLines={2}>
                  {backendTranslation(contract.payment_type)}
                </CollapsableTextField>
              </div>
            </div>
            <div className="mb-3">
              <div className="font-weight-bold">{t('contract_category.details')}:</div>
              <CollapsableTextField numOfLines={3}>
                {backendTranslation(contract.description)}
              </CollapsableTextField>
            </div>
          </GreyContainer>
        </CardBody>
      </StyledCard>
    </StyledCol>
  );
};

ContractCategory.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    public: PropTypes.bool.isRequired,
    reference_id: PropTypes.string.isRequired,
    title: PropTypes.shape(multiLangType).isRequired,
    target_group: PropTypes.shape(multiLangType).isRequired,
    payment_type: PropTypes.shape(multiLangType).isRequired,
    description: PropTypes.shape(multiLangType).isRequired,
    tile_icon: PropTypes.string.isRequired,
    definition: PropTypes.shape({
      disabled: PropTypes.bool
    })
  }).isRequired
};

export default ContractCategory;

