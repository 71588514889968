import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled(Link)`
  border-left: ${(props) =>
    props.active
      ? `2px solid ${props.theme.colors.link}`
      : `2px solid ${props.theme.colors.lightGrey}`
};
  color: ${(props) =>
    props.active 
      ? `${props.theme.colors.secondaryHover}` 
      : `${props.theme.colors.text.greyHover}`
};
  ${props => props.lengthItem > 36 
    ? css`
      -webkit-box-orient: vertical;
        display: -webkit-box;
    ` : css`
      display: flex;
      align-items: center;
  `};
  font-weight: ${({ theme }) => theme.fontWeights.medium};   
  width: 190px;
  height: 46px;
  margin-left: 50px;
  padding-left: 30px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-line-clamp: 2; 
  line-clamp: 2; 
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondaryHover};
    border-left: 2px solid ${({ theme }) => theme.colors.link};
  }
`;