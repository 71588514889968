import { useState, useEffect } from 'react';

import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';

export const useSpaceOffers = ({ space, spaceId }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ offers, setOffers ] = useState([]);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true);
      if (space?.type) {
        try {
          const {
            data: offersForSpace
          } = await axios.get(`${__env.SERVICE_DATA_API_URL}api/spaces/${spaceId}/user_offers_current`);
          setOffers(offersForSpace?.items);
          setIsLoading(false);
        }
        catch (err) {
          const error = err.response ? err.response.data : err.message;
          setError(error);
          setIsLoading(false);
        }
      }
    };
    fetchOffers();
  }, [ space?.type, spaceId ]);

  return {
    offers,
    isLoading,
    error
  };
};
