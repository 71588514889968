import React, { Fragment } from 'react';
import { compose } from 'redux';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { 
  FixedSidebar, 
  InnerNav, 
  Spacename, 
  IconWrapper, 
} from '../styles/styled-components/Sidebar';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { canAccess } from '../utils/rolesUtils';
import useCurrentSpace from '../hooks/useCurrentSpace';
import { useActiveNavItem } from '../hooks/useActiveNavItem';
import { navItems } from '../constants/sidebar';
import useAxiosWithRedux from '../hooks/useAxiosWithRedux';
import { __env } from '../../envloader';
import { UserRole } from '../constants/roles';
import { useSelector } from 'react-redux';
import ServicesList from '../components/ServicesList';

const SpaceSidebar = () => {

  const { data } = useAxiosWithRedux(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories`, 'contractCategories');
  const location = useLocation();
  const match = useRouteMatch('/spaces/:id/');
  const activeNavItem = useActiveNavItem();
  const spaceId = match.params.id;
  const { t } = useTranslation();
  const { space, roles } = useCurrentSpace(match);
  const spaceImage = useSelector(state => state.spaces.spaceImg.get('data'));
  if (!space && !roles) {
    return <FixedSidebar></FixedSidebar>;
  }

  const usersPerSpace = data?.items?.find(item => item.reference_id === space?.type)?.users_per_contract;
  const filteredNavItems = navItems
    ?.filter(item =>
      !((item.path === 'invitations' || item.path === 'users') && usersPerSpace === 'ONE')
    )
    ?.filter(item =>
      (item.path !== 'permissions') || _.isEqual(roles, [ UserRole.END_USER ]) || roles.length === 0
    );
  const getImgIcon = (type) => {
    return spaceImage.find(e => e.reference_id === type)?.folder_icon;
  };
  return (
    <FixedSidebar>
      <Nav vertical className='pt-2'>
        <NavItem className='w-100'>
          <NavLink tag={RRNavLink} to={`/spaces/${spaceId}`} exact className='pt-3  px-4 pb-3'>
            <div className='d-flex align-items-center'>
              <img src={getImgIcon(space?.type)} alt='blur circle' className='mr-3' width="24" height="24" />
              <Spacename>
                {space && space.name }
              </Spacename>
            </div>
          </NavLink>
        </NavItem>
        <InnerNav vertical>
          {filteredNavItems.map(({ label, path, icon, activeIcon, allowedRoles, allowedSpaceTypes }) => (
            <Fragment key={label}>
              {canAccess(allowedRoles, roles) && 
              (allowedSpaceTypes ? allowedSpaceTypes.includes(space?.type) : true) && 
              (path !== "settlements" || __env.ENABLE_SETTLEMENTS) &&
              (
                <NavItem>
                  <NavLink
                    tag={RRNavLink}
                    to={`/spaces/${spaceId}/${path}`}
                    className='inactive-sidebar-link'
                    activeClassName='active-sidebar-link'
                  >
                    <div className="d-flex align-items-center">
                      <IconWrapper className='mr-4'>
                        <img 
                          src={activeNavItem === path ? activeIcon : icon}
                          alt={path}
                        />                        
                      </IconWrapper>
                      <span>{t(label)}</span>
                    </div>
                  </NavLink>
                </NavItem>
              )}
              <Collapse isOpen={location.pathname.includes("services") && path === "services"}>
                <ServicesList/>
              </Collapse>
            </Fragment>
          ))}
        </InnerNav>
      </Nav>
    </FixedSidebar>
  );
};

export default compose(
  ErrorBoundary((props) => props.t('space_sidebar'))
)(SpaceSidebar);
