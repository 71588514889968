import styled from 'styled-components';
import { Modal } from 'reactstrap';
import { BsX } from 'react-icons/bs';

export const StyledModal = styled(Modal)`
  .modal-content {
    width: 420px;
    margin: auto;
  }
`;

export const Username = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Email = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.grey2};
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Value = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.grey2};
  text-align: left;
`;

export const Button = styled.button`
  background: none;
  border: none;
  outline: none !important;
`;

export const XIcon = styled(BsX)`
  color: ${({ theme }) => theme.colors.text.grey4};
  font-size: ${({ theme }) => theme.fontSizes.xl2};
`;
