import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

export const CustomCard = styled(Card)`
  width: 844px;
`;

export const CustomCardBody = styled(CardBody)`
  padding: 1.875rem 2.25rem 2.75rem 2.25rem;
`;

export const Title = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;

  p {
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.sm};
  }

`);
