import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UserAvatar from '../../../components/UserAvatar';
import { StyledLink } from '../../../styles/styled-components/GlobalStyles';
import Loading from '../../../../main/components/loading';
import { selectSpaceById } from '../../../selectors';
import { Tooltip } from 'reactstrap';
const SpaceUsersInfo = ({ spaceId }) => {
  const { t } = useTranslation();
  const currentSpace = useSelector((state) => selectSpaceById(state, spaceId));
  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const limit = 17;

  return (
    <>
      <h5 className="mb-3">
        {t("space_users.users_in_space")}:{" "}
        <span className="text-primary">
          {currentSpace.users && currentSpace.users.length}
        </span>
      </h5>
      {!currentSpace || !currentSpace.users ? (
        <Loading />
      ) : (
        <div className="d-flex justify-content-end">
          {currentSpace.users &&
            currentSpace.users.slice(0, limit).map((user) => (
              <div className="mr-2" key={user.id}>
                <UserAvatar
                  username={`${user.first_name} ${user.surname}`}
                  spaceID={user.id}
                  width="2.75rem"
                  height="2.75rem"
                /> 
              </div>
            ))}
          {currentSpace.users.length > limit && (
            <>
              <StyledLink 
                $unstyled
                id={`tooltip-${spaceId}_users`}
                to={`${spaceId}/users`}
                style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
              >
                <UserAvatar
                  username={`+${currentSpace.users?.length - limit}`}
                  width="2.75rem"
                  height="2.75rem"
                  fontWeight="700"
                  fontSize="16px"
                  number={true}/>
              </StyledLink>
              <Tooltip
                placement="top-start"
                isOpen={tooltipOpen}
                target={`tooltip-${spaceId}_users`}
                toggle={() => setTooltipOpen((prev) => !prev)}
              >
                {currentSpace.users.slice(3).map((user) => (
                  <div key={user.id} className="mr-2">
                    {user.first_name} {user.surname}
                  </div>
                ))}
              </Tooltip>
            </>
          )}
        </div>
      )}
      <div className="text-right my-3">
        <Link to={`/spaces/${spaceId}/users`}>
          {t("see_more")} {">"}
        </Link>
      </div>
    </>
  );
};

SpaceUsersInfo.propTypes = {
  spaceId: PropTypes.string.isRequired,
};

export default SpaceUsersInfo;
