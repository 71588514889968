import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { CardWarning, StyledNav } from '../../styles/styled-components/SpaceInvitations';
import PrivateInvitations from './private/PrivateInvitations';
import PublicInvitations from './public/PublicInvitations';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import { Invitation } from '../../constants/invitations';
import redCircleWarningIcon from '../../../assets/svg/common/red-circle-warning.svg';
import Breadcrumbs  from '../spaces/Breadcrumbs';

const SpaceInvitations = ({ match }) => {
  const [ activeTab, setActiveTab ] = useState(Invitation.PRIVATE);
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const [ isLinkWithExpiredDate, setIsLinkWithExpiredDate ] = useState(false);
  const dataLocation = [
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.invitations') }
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocation} />
      <Helmet className='mt-5'>
        <title>{space && t('page_titles.spaces.invitations', { space: space.name })}</title>
      </Helmet>
      <section className='mt-5'>
        <p>
          {t('space_invitations.section_title')}
        </p>
        {
          isLinkWithExpiredDate && 
            <CardWarning className='p-4 my-4'>
              <div className='d-flex'>
                <img className="mr-3" height="42px" src={redCircleWarningIcon} alt=""/>
                <span><Trans i18nKey="space_invitations.error_link_expired" /></span>
              </div>
            </CardWarning>
        }
        <Card className='p-4'>
          <CardBody className='p-2'>
            <StyledNav tabs>
              <NavItem>
                <NavLink 
                  onClick={() => toggle(Invitation.PRIVATE)} 
                  active={activeTab === Invitation.PRIVATE}
                >
                  {t('space_invitations.private_invitations')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink 
                  onClick={() => toggle(Invitation.PUBLIC)} 
                  active={activeTab === Invitation.PUBLIC}
                >
                  {t('space_invitations.public_invitations')}
                </NavLink>
              </NavItem>
            </StyledNav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={Invitation.PRIVATE}>
                <div className='pt-4'>
                  <PrivateInvitations spaceId={match.params.id} />
                </div>
              </TabPane>
              <TabPane tabId={Invitation.PUBLIC}>
                <div className='pt-4 px-0'>
                  <PublicInvitations spaceId={match.params.id} setIsLinkWithExpiredDate={setIsLinkWithExpiredDate} />
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </section>
    </>
  );
};

SpaceInvitations.propTypes = {
  match: PropTypes.object.isRequired
};

export default SpaceInvitations;
