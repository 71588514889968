import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FilterName, Pover, PoverContent, PoverContentEl, Title } from '../../styles/styled-components/SettlementsFiltersPopover';
import usePopover from '../../hooks/usePopover';
import Checkbox from '../Checkbox';

const SettlementsFiltersPopover = ({ coords, entered, onClose, updatePopoverCoords, uniqueSettlements }) => {
  const { t } = useTranslation();
  const popoverRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  usePopover({
    ref: popoverRef,
    onClose,
    entered,
    onUpdateCoords: updatePopoverCoords
  });
  
  const handleFilterChange = (filterName, isChecked) => {

    if (isChecked) {
      searchParams.append('filter', filterName);    
    }
    else {
      searchParams.delete('filter', filterName);
    }
  
    history.push({ search: searchParams.toString() });
  };

  return (
    <Pover
      ref={popoverRef} 
      className='shadow' 
      top={coords.top} 
      left={coords.left} 
    >
      <Title>
        {t('settlements.table_head.name')}
      </Title>
      <PoverContent>
        {uniqueSettlements?.map(filterName =>
          <PoverContentEl onChange={(e) => handleFilterChange(filterName, e.target.checked)}>         
            <Checkbox xs checked={searchParams.has('filter', filterName)} />
            <FilterName>{filterName}</FilterName>    
          </PoverContentEl>
        )}
      </PoverContent>
    </Pover>
  );
};

export default SettlementsFiltersPopover;

SettlementsFiltersPopover.propTypes = {
  coords: PropTypes.object.isRequired,
  entered: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updatePopoverCoords: PropTypes.func.isRequired,
  uniqueSettlements: PropTypes.array
};