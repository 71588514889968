import React from 'react';
import PropTypes from 'prop-types';

import { 
  RadioButtonContainer,
  HiddenRadioButton,
  StyledRadioButton,
  CircleWrapper,
  Circle
} from '../styles/styled-components/RadioButton';

const RadioButton = ({ checked, size, ...props }) => {
  return (
    <RadioButtonContainer>
      <HiddenRadioButton checked={checked} {...props} />
      <StyledRadioButton size={size}>
        <CircleWrapper size={size}>
          <Circle size={size} />
        </CircleWrapper>
      </StyledRadioButton>
    </RadioButtonContainer>
  );
};

RadioButton.defaultProps = {
  size: 'sm'
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  size: PropTypes.oneOf([ 'sm', 'md' ])
};

export default RadioButton;