import styled from 'styled-components';

const getColorDefaut = (theme, isChecked) => {
  return isChecked ? theme.colors.secondary : theme.colors.white;
};
const getColorHover = (theme, isChecked) => {
  return isChecked ? theme.colors.secondaryHover : theme.colors.white;
};
const getColorActive = (theme, isChecked) => {
  return isChecked ? theme.colors.secondaryActive : theme.colors.white;
};
const getColorDisabled = (theme, isChecked) => {
  return isChecked ? theme.colors.secondaryDisabled : theme.colors.white;
};

export const OutlineButton = styled.button`
  padding: 9px 19px;
  background-color: ${(props) => getColorDefaut(props.theme, props.isChecked)};
  border-radius: 24px;
  color: ${(props) => getColorDefaut(props.theme, !props.isChecked)};
  border-color: ${(props) => getColorDefaut(props.theme, props.isChecked)};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  
  
  &:hover {
    color: ${(props) => getColorHover(props.theme, !props.isChecked)};
    border-color: ${(props) => getColorHover(props.theme, !props.isChecked)};
    background-color: ${(props) => getColorHover(props.theme, props.isChecked)};
  }
  
  &:active {
    color: ${(props) => getColorActive(props.theme, !props.isChecked)};
    border-color: ${(props) => getColorHover(props.theme, !props.isChecked)};
    background-color: ${(props) => getColorHover(props.theme, props.isChecked)};
  }

  &:disabled {
    color: ${(props) => getColorDisabled(props.theme, !props.isChecked)};
    border-color: ${({ theme }) => theme.colors.secondaryDisabled};
    background-color: ${(props) => getColorDisabled(props.theme, props.isChecked)};
  }
`;
