import styled, { css } from 'styled-components';
import { Card } from 'reactstrap';
import { ClampedTitleCss } from './ClampedTextField';
import { Link } from 'react-router-dom';

const TileCardShareProps = css`
  min-width: 232px;
  @media(min-width: 1440px){
    min-width: 241px;
  };
  @media(min-width: 1536px){
    min-width: 262px;
  };
  @media(min-width: 1920px){
    min-width: 292px;
  };
`;

export const Arrow = styled.div(
  ({ theme, open, active }) => `
    border: solid ${theme.colors.text.lessDarkGrey};
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 0 5px 5px 0;
    transform: rotate(-135deg);
    ${active 
    ? open 
      ? "animation: rotateButton 0.5s ease forwards;" 
      : "animation: rotateButtonBack 0.5s ease forwards;"
    : ""}

    @keyframes rotateButton {
      0% {
        transform: rotate(-135deg);
      }
      100% {
        transform: rotate(-315deg);
      }
    }
    @keyframes rotateButtonBack {
      0% {
        transform: rotate(-315deg);
      }
      100% {
        transform: rotate(-135deg);
      }
    }
  `
);

export const TileParentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
`;

export const TileParentCol = styled.div`
  ${TileCardShareProps};
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 232px;
  margin: 1rem 0;
`;

export const TileCard = styled(Card)`
  ${TileCardShareProps};
  padding: 16px 22px 28px 22px;
`;

export const TileTitile = styled.h5`
  ${ClampedTitleCss}
  text-align: center;
  margin-top: 8px;
  font-size: 1.125rem;
  width: 100%;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.text.black };
  @media(max-width: 1440px){
    min-width: 190px;
  };
`;

export const TileTitileLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.black };
  &:hover{
    color: ${({ theme }) => theme.colors.link };
    text-decoration: none;
  };
`;

export const TileCardLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25vw;
  color: ${({ theme }) => theme.colors.link };
  &:hover{
    text-decoration: none;
  };
`;

export const ImgContainer = styled.div.attrs(() => 
  ({
    className: "d-flex align-items-center justify-content-center py-2"
  }))`
    width: 100px;
    height: 100px;
    margin: ${({ inactive }) => inactive ? "1.5rem auto" : "auto"};
  `;

export const TileCardShowAll = styled(Card)`
  ${TileCardShareProps};
  background: #0FA1FF0D;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 305px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

export const TileMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TileDescription = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.sm_md };
  padding-bottom: 30px;
`;

export const TileStatus = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: 19px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.sm };
`;

export const Status = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm };
  font-weight: ${({ theme }) => theme.fontWeights.normal };
  height: 19px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StatusText = styled.div`
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  width: 100px; 
  @media(min-width: 1440px){
    width: 120px;
  };
  @media(min-width: 1536px){
    width: 140px;
  };
  @media(min-width: 1920px){
    width: 162px;
  };
`;