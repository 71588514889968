import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import offerIcon from '../../assets/svg/offers/offer-1.svg';
import useCurrentSpace from '../hooks/useCurrentSpace';
import { OfferButton } from '../styles/styled-components/Offer';
import FavoriteButton from './FavoriteButton';
import CollapsableTextField from './CollapsableTextField';
import { ImgContainer, TileCard, TileParentCol, TileTitile } from '../styles/styled-components/TileCard';

const Offer = ({ id, name, short_description, preview = false, icon }) => {
  const match = useRouteMatch();
  const { space } = useCurrentSpace(match);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <TileParentCol key={id}>
      <TileCard 
        data-testid='offer'
        preview={preview ? 1 : 0}>
        <div className='d-flex justify-content-end'>
          <FavoriteButton 
            spaceId={space?.id} 
            id={id.toString()} 
            type="offers"
          />
        </div>
        {!preview && 
          <TileTitile>{name}</TileTitile>
        }
        <ImgContainer> 
          <img src={icon || offerIcon} alt='offer icon' className="h-100"/>
        </ImgContainer>
        {preview && 
          <TileTitile>{name}</TileTitile>
        }
        {!preview && 
          <div className='text-center '>
            <CollapsableTextField numOfLines={3}>
              {short_description}
            </CollapsableTextField>
          </div>
        }
        {preview && 
          <div className="text-right mt-5">
            <Link to={`/spaces/${match.params.id}/offers/${id}`}>
              {t("navigate")} {">"}
            </Link>
          </div>
        }
        {!preview && 
        <div className='d-flex justify-content-center'>
          <OfferButton 
            outline 
            block 
            color='primary' 
            onClick={() => history.push('/spaces/' + space.id + '/offers/' + id)}
          >
            {t('offers.details')}
          </OfferButton>
        </div>
        }
      </TileCard> 
    </TileParentCol>
  );
};

//TODO: fixed short_description as required after api return sth
Offer.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  short_description: PropTypes.string,
  preview: PropTypes.bool,
  favoritesUpdate: PropTypes.func,
  icon: PropTypes.string
};

export default Offer;
