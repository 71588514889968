import {
  LOADING,
  SUCCESS,
  ERROR,
} from '../actions/useAxiosWithReduxActions';
import { Map } from 'immutable';

export const initialState = Map({
  isLoading: false,
  error: null,
  data: null
});
  
export const useAxiosWithReduxReducer = (state = Map({}), action) => {
  const newState = state.get(action.path) ? state : state.set(action.path,initialState);
  switch (action.type) {
  case LOADING:
    return newState.setIn([ action.path, 'isLoading' ], true);
  case SUCCESS:
    return newState.setIn([ action.path, 'isLoading' ], false).setIn([ action.path, 'data' ], action.payload);
  case ERROR:
    return newState.setIn([ action.path, 'isLoading' ], false).setIn([ action.path, 'error' ], action.payload);
  default:
    return newState;
  }
};
