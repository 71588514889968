import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useUnreadNotifications from '../hooks/useUnreadNotifications';
import { 
  NotificationIndicator,
  NotificationIndicatorContainer,
  UndecoratedLink,
  NotificationsButton
} from '../styles/styled-components/Notifications';

const NotificationsIcon = () => {
  const { data, refetch } = useUnreadNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    const id = setInterval(refetch, 30000);
    return () => clearInterval(id);
  }, [ refetch ]);

  return (
    <UndecoratedLink
      to='/notifications'
      title={t('go_to_notifications')}
      className='mx-4 pr-3 d-flex align-items-center'
    >
      <NotificationsButton disabled={!data?.matching_count>0}/>
      {
        data?.matching_count>0 && 
          <NotificationIndicatorContainer>
            <NotificationIndicator>
              {data.matching_count}
            </NotificationIndicator>
          </NotificationIndicatorContainer>
      }
    </UndecoratedLink>
  );
};

export default NotificationsIcon;
