import React from 'react';
import DOMPurify from 'dompurify';
import propTypes from 'prop-types';

const SanitizedHTML = ({ html }) => {
  return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}/>;
};

SanitizedHTML.propTypes = {
  html: propTypes.string,
};

export default SanitizedHTML;