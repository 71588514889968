import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../hooks/useAxios';
import { __env } from '../../../../envloader';
import Loading from '../../../../main/components/loading';
import { BtnTextGrey } from '../../../styles/styled-components/Button';
const UserRow = ({ user: { invited_email, id }, refetchInvites }) => {
  const { t } = useTranslation();

  const { refetch: sendRequest, isLoading, data } = useAxios(`${__env.BPM_API_URL}spaces/invitations/private/${id}`, {
    method: 'delete', 
    sendOnChange: false,
    sendOnInit: false
  });

  useEffect(() => {
    //the server resonse is an empty string, and the default value of data is null
    if (data != null){
      refetchInvites();
    }
  }, [ refetchInvites, data ]);

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className='font-sm'>{invited_email}</span>
      {isLoading ? <Loading /> :
        <BtnTextGrey className='p-0' onClick={sendRequest}>
          {t('private_invitations.cancel')}{' >'}
        </BtnTextGrey>}
    </div>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    invited_email: PropTypes.string,
    id: PropTypes.string
  }),
  refetchInvites: PropTypes.func
};

export default UserRow;