import React from 'react';
import { useLocation } from 'react-router-dom';

import SpaceSidebar from './SpaceSidebar';
import GlobalSidebar from './GlobalSidebar';

const SidebarContainer = () => {
  const { pathname } = useLocation();

  if (pathname === '/accessibility') {
    return null;
  }

  if (pathname.startsWith('/spaces')) {
    return <SpaceSidebar />;
  } 
  else {
    return <GlobalSidebar />;    
  }
};

export default SidebarContainer;
