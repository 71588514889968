import React from 'react';
import PropTypes from 'prop-types';

const LinkWidget = ({ options, schema }) => {
  const { displayText, linkAddress } = options;
  
  return (
    <div>
      <div className='mb-2'>{schema.title}</div>
      <a 
        href={linkAddress} 
        target='_blank' 
        rel='noopener noreferrer'
      >
        {displayText}
      </a>
    </div>
  );
};

LinkWidget.propTypes = {
  options: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    linkAddress: PropTypes.string.isRequired
  }).isRequired,
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default LinkWidget;