import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { __env } from '../../../../envloader';
import { BasicButton } from '../../../styles/styled-components/GlobalStyles';
import IconButton from '../../../components/IconButton';
import Loading from '../../../../main/components/loading';
import axios from '../../../../main/utils/axios/axiosInstance';
import UserRow from '../../../components/invitations/private/UserRow';

const InvitedUsers = ({ isLoading, error, data, refetchInvites }) => {
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ isRemoving, setIsRemoving ] = useState(false);

  const { t } = useTranslation();

  const removeInvites = () => {
    setIsRemoving(true);
    Promise.all(data.map(invite => {
      return axios.delete(`${__env.BPM_API_URL}spaces/invitations/private/${invite.id}`);
    })).finally(() => {
      setIsRemoving(false);
      refetchInvites();
    });
  };

  const invitedUsers = data ? data.filter(user => user.invitation_state === 'ACTIVE') : [];

  return (<>{
    (isLoading && !data) ? 
      <Loading />
      : !error && data && data.length ? 
        <div>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <span className='font-sm fontWeight-medium grey'>{t('private_invitations.wating_for_approval')}</span>
            {isRemoving ? 
              <div className='text-center my-4'>
                <Loading />
              </div> : 
              <IconButton icon='cancel' onClick={removeInvites}>
                {t('private_invitations.cancel_all')}
              </IconButton>}
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className='font-sm fontWeight-medium black'>{t('private_invitations.email')}</span>
            <span className='font-sm fontWeight-medium black'>{t('private_invitations.cancellation')}</span>
          </div>
          { 
            invitedUsers.slice(0,3).map((user) => <UserRow key={user.id} user={user} refetchInvites={refetchInvites} />)}
          {
            invitedUsers.slice(3).length > 0 && 
            <>
              <Collapse isOpen={isExpanded}>
                {invitedUsers.slice(3).map((user) => <UserRow key={user.id} user={user} refetchInvites={refetchInvites} />)}
                <hr className='w-100'/>
              </Collapse>
              <div className="text-center">
                {isExpanded ? (
                  <BasicButton color='blue' onClick={() => setIsExpanded(false)}>
                    {t('private_invitations.collapse_list')}
                  </BasicButton>
                ) : (
                  <BasicButton color='blue' onClick={() => setIsExpanded(true)}>
                    {t('private_invitations.expand_list')}
                  </BasicButton>
                )}
              </div>
            </>
          }
        </div> : 
        t('private_invitations.no_invited_users')
  }</>);
};

InvitedUsers.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.array,
  refetchInvites: PropTypes.func,
};

export default InvitedUsers;
