import { Map, List } from 'immutable';

import { 
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  SELECT_ALL_USERS,
  UNSELECT_ALL_USERS,
  SELECT_USER,
  UNSELECT_USER  
} from '../actions/usersActions';

const initialState = Map({
  allUsers: Map({
    isLoading: false,
    data: {
      items: [],
      matching_count: 0
    },
    error: null
  }),
  selectedUsers: List(),
  usersCount: 0,
});

export const usersReducer =  (state = initialState, action) => {
  switch (action.type) {
  case FETCH_USERS_REQUEST:
    return state.setIn([ 'allUsers', 'isLoading' ], true);
  case FETCH_USERS_SUCCESS:
    return state
      .setIn([ 'allUsers', 'data' ], action.payload)
      .setIn([ 'allUsers', 'isLoading' ], false)
      .set('usersCount', action.payload.matching_count);
  case FETCH_USERS_ERROR:
    return state.setIn([ 'allUsers', 'error' ], action.payload).setIn([ 'allUsers', 'isLoading' ], false);
  case SELECT_ALL_USERS:
    const allUsersIds = state.get('allUsers').get('data').items.map(({ id }) => id);
    return state.set('selectedUsers', List(allUsersIds));
  case UNSELECT_ALL_USERS:
    return state.set('selectedUsers', List());
  case SELECT_USER:
    const selectedUsers = state.get('selectedUsers');
    return state.set('selectedUsers', selectedUsers.push(action.payload));
  case UNSELECT_USER:
    const selectedUsersFiltered = state.get('selectedUsers').filter(id => id !== action.payload);
    return state.set('selectedUsers', selectedUsersFiltered);
  default:
    return state;
  }
};