import React from 'react';
import PropTypes from 'prop-types';

import { Loader, Dot } from '../../users-ui/styles/styled-components/Loading';

const Loading = ({ tiny = false }) => {
  const TOTAL_DOTS = 8;
  const ROTATION_DEGREE = 360 / TOTAL_DOTS;

  return (
    <Loader tiny={tiny}>
      {Array.from({ length: TOTAL_DOTS }).map((_, index) => (
        <Dot
          key={index}
          angel={index * ROTATION_DEGREE}
          delay={index * 0.125}
          tiny={tiny}
        />
      ))}
    </Loader>
  );
};

Loading.propTypes = {
  tiny: PropTypes.bool,
};

export default Loading;
