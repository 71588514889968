import styled from 'styled-components';
import { BsX } from 'react-icons/bs';

export const ActiveFiltersLabel = styled.span`
  color: ${props => props.theme.colors.text.grey2};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const FilterItem = styled.span`
  color: ${props => props.theme.colors.text.darkGrey};
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const RemoveFilterButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearFiltersButton = styled.button`
  color: ${props => props.theme.colors.primary};
  background: none;
  border: none;
  outline: none !important;
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const XIcon = styled(BsX)`
  color: ${props => props.theme.colors.text.grey3};
  font-size: ${props => props.theme.fontSizes.md};
`;
