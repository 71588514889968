import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectUser, unselectUser } from '../../actions/usersActions';
import { UsersTable as Table } from '../../styles/styled-components/SpaceUsers';
import UsersTableHeader from './UsersTableHeader';
import UsersTableRow from '../../components/UsersTableRow';
import { filterUsers, searchUsers, hasOnlyAnyValues, hasLowerRole } from '../../utils/users';
import { FilterValue } from '../../constants/usersFilters';

const UsersTable = ({ disabled }) => {
  const [ filteredUsers, setFilteredUsers ] = useState([]);
  const [ showFilteredUsers, setShowFilteredUsers ] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const selectDates = state => ({
    allUsers: state.users.get('allUsers').get('data'),
    selectedUsers: state.users.get('selectedUsers')
  });
  const { allUsers, selectedUsers } = useSelector(selectDates);
 
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      if (!hasOnlyAnyValues(params)) {
        const filters = {
          financial_admin: params.get('financial_admin') || FilterValue.ANY,
          user_admin: params.get('user_admin') || FilterValue.ANY,
          end_user: params.get('end_user') || FilterValue.ANY
        };
        const searchTerm = params.get('search') || '';
  
        const searched = searchUsers(searchTerm, allUsers.items);
        const filtered = filterUsers(filters, searched);
        setFilteredUsers(filtered);
        setShowFilteredUsers(true);
      }
      else {
        setShowFilteredUsers(false);
        setFilteredUsers([]);
      }
    }
    else {
      setShowFilteredUsers(false);
      setFilteredUsers([]);
    }
  }, [ location, allUsers ]);

  const onToggle = (userId) => {
    if (!selectedUsers.includes(userId)) {
      dispatch(selectUser(userId));
    }
    else {
      dispatch(unselectUser(userId));
    }
  };

  if (showFilteredUsers && filteredUsers.length === 0) {
    return (
      <div className='text-center lead mt-2 mb-3'>
        {t('space_users.users_not_found_message')}
      </div>
    );
  }
  else if (showFilteredUsers) {
    return (
      <Table className={disabled && 'disabled'}>
        <tbody>
          <UsersTableHeader filteredUsers={filteredUsers} showFilteredUsers />
          {filteredUsers.sort(hasLowerRole).map((user) =>
            <UsersTableRow
              key={user.id}
              user={user}
              checked={selectedUsers.includes(user.id)}
              toggle={onToggle}
            />
          )}
        </tbody>
      </Table>
    );
  }
  else {
    return (
      <Table className={disabled && 'disabled'}>
        <tbody>
          <UsersTableHeader />
          {allUsers.items.sort(hasLowerRole).map((user) =>
            <UsersTableRow
              key={user.id}
              user={user}
              checked={selectedUsers.includes(user.id)}
              toggle={onToggle}
              disabled={disabled}
            />
          )}
        </tbody>
      </Table>
    );
  }
};

UsersTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default UsersTable;
