import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { FlexCenter, FolderProgressIcon } from '../styles/styled-components/GlobalStyles';
import backendTranslation from '../utils/backendTranslation';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { 
  TileCard, 
  TileParentCol, 
  TileTitile, 
  TileTitileLink, 
  ProgressContainer, 
  TileMain, 
  TileDescription, 
  TileStatus,
  Status,
  StatusText
} from '../styles/styled-components/TileCard';
import CollapsableTextField from './CollapsableTextField';
import IconProgress from './IconProgress';
import SanitizedHTML from './SanitizedHTML';
import { offerIcon } from '../../assets/svg/offers/icons';

const ServiceInProgress = ({ name, step_name, offer_id, progress, shortDescription, description, space_id, process_id, current_step, total_steps, icons }) => {
  const { t } = useTranslation();
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  const icon = (icons.find(el => Number(el[0]) === Number(offer_id)) || [])[1] || offerIcon;

  return (
    <TileParentCol>
      <TileCard data-testid='service-in-progress'> 
        <div>
          <TileTitile>
            <TileTitileLink to={"/spaces/" + space_id + "/offers/purchase_form/" + process_id}>
              {backendTranslation(name)}
            </TileTitileLink>
          </TileTitile>
        </div>
        <ProgressContainer>
          <IconProgress icon={icon} progress={ 100 * current_step / total_steps } sizeImg={34} sizeProgress={57} />
        </ProgressContainer>
        <TileMain>
          <TileDescription>
            <CollapsableTextField numOfLines={3}>
              <SanitizedHTML 
                html={backendTranslation((shortDescription !== undefined && !(Object.keys(shortDescription).length === 0)) ? shortDescription : description)}
              />
            </CollapsableTextField>
          </TileDescription>
        </TileMain>
        <TileStatus>
          <FlexCenter height={21} className='pr-1'>
            <FolderProgressIcon style={{ marginBottom: ".5px" }} />
          </FlexCenter>
          <Status> 
            <strong className='pr-1'>{t('services.status')}:</strong>
            <StatusText id={`tooltip-${process_id}`}>{backendTranslation(step_name)}</StatusText>
            <Tooltip
              placement='top-start' 
              isOpen={tooltipOpen} 
              target={`tooltip-${process_id}`} 
              toggle={() => setTooltipOpen(prev => !prev)}
            >
              {backendTranslation(step_name)}
            </Tooltip>
          </Status>
        </TileStatus>
      </TileCard>
    </TileParentCol>
  );
};

ServiceInProgress.propTypes = {
  name: PropTypes.object.isRequired,
  step_name: PropTypes.object.isRequired,
  offer_id: PropTypes.string,
  progress: PropTypes.number,
  space_id: PropTypes.string.isRequired,
  process_id: PropTypes.string.isRequired,
  current_step: PropTypes.number,
  total_steps: PropTypes.number,
  icons: PropTypes.array.isRequired,
  shortDescription: PropTypes.object,
  description: PropTypes.object.isRequired
};

export default compose(
  ErrorBoundary((props) => props.t('service_in_progress.error_message'))
)(ServiceInProgress);
