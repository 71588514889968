import { UserRole } from '../constants/roles';

export const hasRole = (role, userRoles) => {
  const foundRole = userRoles.find((item) => item.role === role);
  return foundRole ? true : false;
};

export const canAccess = (allowedRoles, rolesInSpace) => 
  allowedRoles.some((role) => {
    if (role === UserRole.EVERYONE) return true;
    return rolesInSpace.includes(role);
  });