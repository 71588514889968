import React from 'react';
import PropTypes from 'prop-types';

import { 
  UsersActionModalHeader, 
  UsersActionModalText, 
  ModalAction,
  ModalBodyAction
} from '../styles/styled-components/UsersActionModal';

import { BtnTextGrey, BtnTextRed  } from '../styles/styled-components/Button';
const UsersActionModal = ({ 
  isOpen, 
  toggle, 
  children, 
  bodyText, 
  cancelButtonText,
  confirmButtonText, 
}) => {
  return (
    <ModalAction isOpen={isOpen} toggle={() => toggle(false)}>
      <ModalBodyAction>
        <div className='d-flex flex-column align-items-center w-100'>
          <UsersActionModalHeader>
            {children}
          </UsersActionModalHeader>
          <UsersActionModalText>
            {bodyText}
          </UsersActionModalText>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <BtnTextGrey onClick={() => toggle(false)} $fweight="500" className='m-0'>
              {cancelButtonText}
            </BtnTextGrey>
            <BtnTextRed onClick={() => toggle(true)} $fweight="500" className='m-0'>
              {confirmButtonText}
            </BtnTextRed>
          </div>
        </div>
      </ModalBodyAction>
    </ModalAction>
  );
};

UsersActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  bodyText: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  confirmButtonColor: PropTypes.oneOf([ 'blue', 'red' ])
};

export default UsersActionModal;
