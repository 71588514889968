import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 48px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Logo = styled.div`
  width: 170px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const LogoImg = styled.img`
  width: 76px;
  height: 22px;
  margin-left: 26px;
`;

export const LogoLink = styled.nav`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.xxxs};
    color: ${theme.colors.text.blueLink};
  `}
`;

export const Menu = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
`;

export const MenuLink = styled.a`
  ${({ theme }) => `
    color: ${theme.colors.text.darkGrey};
    font-size: ${theme.fontSizes.sm_md};
    font-weight: ${theme.fontWeights.normal};
  `}
  line-height: 19px;
`;

export const Info = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
    font-size: ${theme.fontSizes.sm_md};
    color: ${theme.colors.lineGrey};
  `}
  line-height: 19px;
`;