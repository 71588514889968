import React, { useState, useMemo } from 'react';
import { Col, Row, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import Offer from '../../components/Offer';
import ServicesInProgress from './ServicesInProgress'; 
import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import { useSpaceOffers } from '../../hooks/useSpaceOffers';
import backendTranslation from '../../utils/backendTranslation';
import { RoundedInput } from '../../styles/styled-components/GlobalStyles';
import { TileParentRow } from '../../styles/styled-components/TileCard';
import Breadcrumbs  from './Breadcrumbs';

const Offers = ({ match }) => {
  const [ searchQuery, setSearchQuery ] = useState('');
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const { offers, isLoading, error } = useSpaceOffers({ space, spaceId: match.params.id });

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.offers') }
  ]), [ space?.name, space?.id, t ]);

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Helmet>
        <title>{space && t('page_titles.spaces.offers', { space: space.name })}</title>
      </Helmet>
      <section>
        { error ? (
          <Alert color='danger'>{t('offers.api_error')}</Alert>
        ) : (
          isLoading ? (
            <Loading />
          ) : (
            offers.length === 0 ? (
              <Alert color='primary'>{t('offers.empty_list_of_offers')}</Alert>
            ) : (
              <>
                <Row className='mb-3 mt-5'>
                  <Col xs='4'>
                    <label className='sr-only' htmlFor='offer-name'>{t('offers.offer_name')}</label>
                    <RoundedInput
                      type='text'
                      id='offer-name'
                      name='offer-name'
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      placeholder={`${t('offers.find_offer_placeholder')}...`}
                      className='p-3'
                    />
                  </Col>
                </Row>
                <TileParentRow>
                  {offers
                    .filter(offer => backendTranslation(offer.offer__name).includes(searchQuery))
                    .map(({ id, offer__name, offer__tile_icon, short_description }) => (
                      <Offer
                        id={id}
                        key={id}
                        name={backendTranslation(offer__name)}
                        short_description={backendTranslation(short_description)}
                        icon={offer__tile_icon}
                      />
                    ))}
                </TileParentRow>
              </>
            )
          )
        )}
      </section>
      <ServicesInProgress spaceId={match.params.id}/>
    </>
  );
};

Offers.propTypes = {
  match: PropTypes.object.isRequired
};

export default Offers;
