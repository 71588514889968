import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { __env } from '../../../../envloader';
import Loading from '../../../../main/components/loading';
import * as notify from '../../../../main/utils/notify';
import axios from '../../../../main/utils/axios/axiosInstance';
import InvitedUsers from './InvitedUsers';
import useAxios from '../../../hooks/useAxios';
import backendTranslation from '../../../utils/backendTranslation';
import { RoundedInput } from '../../../styles/styled-components/GlobalStyles';
const PrivateInvitations = ({ spaceId='' }) => {
  const [ email, setEmail ] = useState('');
  const [ submitInProgress, setSubmitInProgress ] = useState(false);
  const [ isDisableBtn, setIsDisableBtn ] = React.useState(true);
  const loginData = useSelector(state => state.login.get('loginData'));

  const { isLoading, error, data, refetch } = useAxios(
    `${__env.BPM_API_URL}spaces/invitations/private?spaceId=${spaceId}`);

  useEffect(() => {
    const id = setInterval(refetch, 30000);
    return () => clearInterval(id);
  }, [ refetch ]);

  const { t } = useTranslation();

  const handleTextChange = (e) => {
    let text = e.target.value;
    setEmail(text);
    //email regex complies with HTML5 specification suggests. Added requirement of user to type domain after dot
    // (+ instead * at the end).
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    setIsDisableBtn(!regex.test(text));
  };

  const submit = async () => {
    if (email === loginData._root.entries.find(element => element[0] === "email")[1]) {
      notify.error("",  t('private_invitations.notifications.error_inviting_yourself'));
    }
    else {
      setSubmitInProgress(true);
      axios.post(`${__env.BPM_API_URL}spaces/invitations/private`, 
        {
          space_id: spaceId,
          invited_email: email
        },
        {
          __silentFor: 'all'
        }).then(() => {
        setEmail("");
        setIsDisableBtn(true);
        refetch();
        notify.info(
          t('private_invitations.notifications.success_title'),
          t('private_invitations.notifications.success_body')
        );
      }).catch((e) => {
        const errMsg = e.response.data?.message
          ? backendTranslation(e.response.data.message)
          : t('private_invitations.notifications.error_default');

        notify.error("", errMsg);
        refetch();
      }).finally(()=> {
        setSubmitInProgress(false);
      });
    }
  };

  return (
    <>
      <div>
        <label htmlFor='email' className='mb-3 font-sm fontWeight-medium black'>{t('private_invitations.invite_users')}</label>
        <Row>
          <Col xs='8'>
            <RoundedInput 
              type='email'
              id='email' 
              placeholder={t('private_invitations.give_your_email')}
              value={email} 
              onChange={handleTextChange}
              radius="24px"
            />
          </Col>
          <Col xs='4'>
            {submitInProgress ? 
              <Loading /> :
              <Button 
                color='primary' 
                block
                onClick={submit}
                disabled={isDisableBtn}
              >
                {t('private_invitations.send_invitation')}
              </Button>}
          </Col>
        </Row>
      </div>
      <hr className='mt-4 w-100' />
      <InvitedUsers isLoading={isLoading} error={error} data={data} refetchInvites={refetch} />
    </>
  );
};

PrivateInvitations.propTypes = {
  spaceId: PropTypes.string.isRequired
};

export default PrivateInvitations;
