import useAxiosWithRedux from './useAxiosWithRedux';
import { __env } from '../../envloader';

//this hook exists because useAxiosWithRedux hook needs to be called twice with the same arguments
//this is because we need to refetch data dynamicly in both places rather than fetch it it one place and then read in another
const useUnreadNotifications = () =>  useAxiosWithRedux(
  `${__env.USERS_DATA_API_URL}api/users-data/notifications/?state=UNREAD&max=1`,
  'unreadNotifications'
);

export default useUnreadNotifications;
