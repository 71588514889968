import React from 'react';
import PropTypes from 'prop-types';

import { InfoBoxAlert, InfoText } from '../styles/styled-components/InfoBox';

const InfoBox = ({ children, iconPath, iconAlt, highlighted, color }) => {

  return (
    <InfoBoxAlert className='my-3' highlighted={highlighted ? 1 : 0} color={color}>
      <div className='d-flex align-items-center'>
        <div className='mr-4'>
          <img alt={iconAlt} src={iconPath}/>
        </div>
        <div>
          <InfoText color={color}>
            {children}
          </InfoText>
        </div>
      </div>
    </InfoBoxAlert>
  );
};

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  iconPath: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  color: PropTypes.oneOf([ "blue", "red", "grey" ])
};

export default InfoBox;
