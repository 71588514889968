import styled, { css } from 'styled-components';
import { FaSearch } from 'react-icons/fa';

export const SearchContainer = styled.div`
  display: flex;
`;

export const SearchForm = styled.form`
  width: 100%;
  position: relative;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${props => props.theme.colors.background.white};
  border: 1px solid ${({ open, theme }) => open ? theme.colors.secondary : theme.colors.text.grey3};
  outline: none !important;
  width: 120px;
  ${props => props.active && css`
    border: 1px solid ${props => props.theme.colors.secondary};
    border-right: 1px solid ${props => props.theme.colors.text.grey3};
  `};
`;

export const FilterButtonText = styled.span`
  margin-right: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${props => props.theme.colors.text.darkGrey};
`;

export const SearchButton = styled.button`
  border: 0;
  outline: none !important;
  background: ${({ theme }) => theme.colors.background.white};
`;

export const SearchIcon = styled(FaSearch)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin: 0 10px;
  color: ${({ theme }) => theme.colors.text.grey3};
`;

export const CaretIcon = styled.img`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.text.grey3};
  transition: transform .3s ease;
  transform: ${(props) => props.open ? 'rotate(180deg)' : 'rotate(0)'};
`;

export const SearchInputContainer = styled.div`
  border: 1px solid ${({ theme, active }) => active ? theme.colors.secondary : theme.colors.text.grey3};
  border-left-width: ${({ hideBorderLeft }) => hideBorderLeft ? '0' : '1px' };
  border-radius: ${({ hideBorderLeft }) => hideBorderLeft ? '0' : '6px'};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
`;

export const Menu = styled.ul`
  max-height: 200px;
  width: 400px;
  padding: 0;
  list-style: none;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(127, 135, 141, 0.08), 0px 20px 32px rgba(72, 79, 86, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 1000;
`;

export const MenuItemBody = styled.div`
  border-bottom: 1px solid #DCD6D6;
  padding: 10px 0;
`;

export const MenuItem = styled.li`
  padding: 0 20px;
  background-color: ${({ higlighted }) => higlighted ? 'rgba(15,161,255,0.1)' : 'transparent'};

  &:last-of-type ${MenuItemBody} {
    border-bottom: none;
  }
`;

export const ComboBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const SearchInput = styled.input`
  border: 0;
  outline: none;
  color: ${({ theme }) => theme.colors.text.blackFocus};
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const HiglightedText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
