import PropTypes from 'prop-types';

export const multiLangType = PropTypes.shape({
  multiLangType: PropTypes.shape({
    en: PropTypes.string.isRequired,
    pl: PropTypes.string.isRequired,
  }),
});

export const notificationType = PropTypes.shape({
  created: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  recipient: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  content: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      multiLangType
    ]),),
    message: multiLangType,
    subject: multiLangType,
  }),
});