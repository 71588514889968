import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';

const namespace = '@PRACELAB_CONTRACT/';

export const FETCH_SPACE_CONTRACT_REQUEST = namespace + 'FETCH_SPACE_CONTRACT_REQUEST';
export const FETCH_SPACE_CONTRACT_SUCCESS = namespace + 'FETCH_SPACE_CONTRACT_SUCCESS';
export const FETCH_SPACE_CONTRACT_ERROR = namespace + 'FETCH_SPACE_CONTRACT_ERROR';

export const FETCH_CONTRACT_BILLING_REQUEST = namespace + 'FETCH_CONTRACT_BILLING_REQUEST';
export const FETCH_CONTRACT_BILLING_SUCCESS = namespace + 'FETCH_CONTRACT_BILLING_SUCCESS';
export const FETCH_CONTRACT_BILLING_ERROR = namespace + 'FETCH_CONTRACT_BILLING_ERROR';

export const FETCH_CONTRACT_EVENTS_REQUEST = namespace + 'FETCH_CONTRACT_EVENTS_REQUEST';
export const FETCH_CONTRACT_EVENTS_SUCCESS = namespace + 'FETCH_CONTRACT_EVENTS_SUCCESS';
export const FETCH_CONTRACT_EVENTS_ERROR = namespace + 'FETCH_CONTRACT_EVENTS_ERROR';

export const getSpaceContract = (spaceId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SPACE_CONTRACT_REQUEST });
    const { data } = await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/contracts?space=${spaceId}`);

    if (data.matching_count === 0) {
      dispatch({ type: FETCH_SPACE_CONTRACT_SUCCESS, payload: null });
    } 
    else {
      dispatch({ type: FETCH_SPACE_CONTRACT_SUCCESS, payload: data.items[0] });
    }
    
  } 
  catch (err) {
    dispatch({
      type: FETCH_SPACE_CONTRACT_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

export const getContractBilling = () => async (dispatch, getState) => {
  try {
    const { id } = getState().contract.contractDetails.get('data');
    dispatch({ type: FETCH_CONTRACT_BILLING_REQUEST });
    const { data } = await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/contracts/${id}/billing/`, {
      __silentFor: [ { status: 404 } ]
    });
    dispatch({ type: FETCH_CONTRACT_BILLING_SUCCESS, payload: data });
  }
  catch (err) {
    dispatch({
      type: FETCH_CONTRACT_BILLING_ERROR,
      payload: err.response ? err.response.data.detail : err.message
    });
  }
};

export const getContractEvents = () => async (dispatch, getState) => {
  try {
    const { id } = getState().contract.contractDetails.get('data');
    dispatch({ type: FETCH_CONTRACT_EVENTS_REQUEST });
    const { data } = await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/contracts/${id}/events/`);
    dispatch({ type: FETCH_CONTRACT_EVENTS_SUCCESS, payload: data });
  }
  catch (err) {
    dispatch({
      type: FETCH_CONTRACT_EVENTS_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

