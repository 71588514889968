import styled from 'styled-components';
import { FlexCenter } from './GlobalStyles';

export const CreateContactContainer = styled(FlexCenter)`
  margin-top: 100px;
`;

export const CreateContractBox = styled(FlexCenter)`
  flex-direction: column;
  text-align: center;
`;

export const CreateContractTitle = styled.p`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes.md};
  color: #585858;
`;