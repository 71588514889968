import styled from 'styled-components';

export const Pover = styled.div`
  position: absolute;
  z-index: 1000;
  width: 360px;
  height: 214px;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background-color: ${({ theme }) => theme.colors.background.white};
  border-radius: 0px 0px 6px 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;

  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 8px;
  border-right: solid 12px ${({ theme }) => theme.colors.white};
  border-top: solid 16px ${({ theme }) => theme.colors.white};
  border-bottom: solid 16px ${({ theme }) => theme.colors.white};

  ::-webkit-scrollbar {
    width: 8px;
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.grey1_5};
  line-height: 18.23px;
`;

export const PoverContent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

export const PoverContentEl = styled.label`
  display: flex;
  align-items: center;
  gap: 13px;
  height: 19px;
  margin: 10px 0 10px 10px;
  cursor: pointer !important;
`;

export const FilterName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.blackFocus};
  line-height: 18.23px;
  max-width: 268px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;