import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ActiveFiltersLabel, FilterItem, ClearFiltersButton } from '../../styles/styled-components/ActiveFilters';
import { paramsAreEmpty } from '../../utils/users';

const ActiveSearchFilter = () => {
  const [ searchFilter, setSearchFilter ] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.has('search')) {
        const searchValue = params.get('search');
        setSearchFilter(searchValue || "");
      } 
    } 
    else {
      setSearchFilter("");
    }
  }, [ location ]);

  const handleClearFilter = () => {
    const params = new URLSearchParams(location.search);
    params.delete('search');
    setSearchFilter('');

    if (paramsAreEmpty(params)) {
      history.push(location.pathname);
    }
    else {
      history.push(`${location.pathname}?${params.toString()}`);
    }
  };

  if (!searchFilter) {
    return null;
  }

  return (
    <div className='px-3 py-2'>
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <div className='d-flex align-items-center'>
          <ActiveFiltersLabel className='mr-3'>
            {t('active_search_filter.active_search_filter')}:
          </ActiveFiltersLabel>
          <FilterItem>{searchFilter}</FilterItem>
        </div>
        <ClearFiltersButton onClick={handleClearFilter}>
          {t('active_search_filter.clear_search_filter')}
        </ClearFiltersButton>
      </div>
      <hr className='w-100' />
    </div>
  );
};

export default ActiveSearchFilter;
