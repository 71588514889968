import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import calendarIcon from '../../../assets/svg/settlements/calendar.svg';
import { CalendarTitle, CalendarWidgetContainer, Icon } from '../../styles/styled-components/CalendarWidget';

const CalendarWidget = ({ onChange, schema }) => {
  const [ startDate, setStartDate ] = useState(schema.default ? parseISO(schema.default) : null);

  useEffect(() => {
    setStartDate(schema.default ? parseISO(schema.default) : null);
  }, [ schema.default ]);

  const handleDateChange = (date) => {
    setStartDate(date);
    onChange(date ? format(date, 'yyyy-MM-dd') : '');
  };

  return (
    <div>
      <CalendarTitle>{schema.title} *</CalendarTitle>
      <CalendarWidgetContainer>
        <DatePicker
          type="data"
          selected={startDate}
          onChange={handleDateChange}
          dateFormat="dd.MM.yyyy"
          placeholderText={schema.title}
          className="form-control"
        />
        <Icon src={calendarIcon} alt="CalendarIcon" />
      </CalendarWidgetContainer>
    </div>
  );
};

export default CalendarWidget;

CalendarWidget.propTypes = {
  onChange: PropTypes.func,
  schema: PropTypes.object
};