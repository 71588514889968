import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from '../styles/styled-components/IconButton';

const IconButton = ({ icon, children, withBorder=true, onClick, ...props }) => (
  <StyledButton onClick={onClick} icon={icon} withBorder={withBorder} {...props} >
    <div className='icon-wrapper'>
      <div className='icon' />
    </div>
    <div className='text'>{children}</div>
  </StyledButton>
);

IconButton.propTypes = {
  icon: PropTypes.oneOf([ 'cancel', 'minus', 'plus', 'check', 'settings','trash' ]),
  onClick: PropTypes.func,
  children: PropTypes.any,
  withBorder: PropTypes.bool
};

export default IconButton;