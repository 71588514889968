import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

export const ModalAction = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-width: 24rem;
  margin: 0 auto;
`;

export const ModalBodyAction = styled(ModalBody)`
  padding: 2rem 2rem 1.75rem 2rem;
`;

export const UsersActionModalHeader = styled.h1`
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.darkGrey};
  word-break: break-all;
`;

export const UsersActionModalText = styled.p`
  width: 90%;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.grey2};;
`;

export const HiglightedText = styled.span`
  color: ${({ theme }) => theme.colors.link};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;