import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Loading from '../../../main/components/loading';
import { canAccess } from '../../utils/rolesUtils';

const ProtectedRoute = ({ component: Component, allowedRoles, forbiddenRoles = [], path, ...props }) => {
  const ownRolesLoading = useSelector((state) => state.spaces.ownRoles.get('isLoading'));
  const ownRoles = useSelector((state) => state.spaces.ownRoles.get('data'));
  const location = useLocation();

  if (ownRolesLoading && !ownRoles) {
    return <Loading />;
  }

  return (
    <Route
      path={path}
      {...props}
      render={(props) => {
        // eslint-disable-next-line
        const spaceId = props.match.params.id;
        const rolesInSpace = ownRoles?.filter(({ space_id }) => space_id === spaceId).map(({ role }) => role) || [];

        if (canAccess(allowedRoles, rolesInSpace) && !canAccess(forbiddenRoles, rolesInSpace)) {
          return <Component {...props} />;
        }
        else {
          return <Redirect to={{
            pathname: `/spaces/${spaceId}/`,
            state: { PermissionDenied: true, view: location?.pathname?.split('/')[3] }
          }} />;
        }
      }} 
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  forbiddenRoles: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
