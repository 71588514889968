import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Title } from '../../styles/styled-components/OTPWidget';
import info from '../../../assets/svg/common/widgetInfo.svg';
import { RoundedInput } from '../../styles/styled-components/GlobalStyles';

const OTPWidget = ({ resendButton, label, onBlur, onChange, onFocus, value, required, uiSchema }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const onChangeRef = useRef(onChange);//onChange is redefined on every render so we need to store it in a ref

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setTimeout(() => {
      onChangeRef.current(params.get('otp'));
    }, 0); //for some reason onChange function does not work right away, so we add its execution to the callstack
  }, [ location ]);

  return (
    <>
      <Title>
        <p style={{ margin: "0" }}>{label}{required}</p>
        <div id={"iconId"}>
          <img src={info} alt='ikona informacji'/>
        </div>
        <UncontrolledTooltip
          placement="top-start"
          target="iconId"
        >
          {uiSchema["ui:customHelp"]}
        </UncontrolledTooltip>
      </Title>
      <RoundedInput
        radius="6px"
        text
        style={{ height: "48px", marginBottom: "20px" }}
        type="text"
        onBlur={onBlur}
        onChange={(event) => onChange(event.target.value)}
        onFocus={onFocus}
        placeholder={t('process_form.enter_OTP')}
        value={value ?? ''}
      />
      {resendButton}
    </>
  );
};

OTPWidget.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  resendButton: PropTypes.object,
  uiSchema: PropTypes.shape({
    "ui:customHelp": PropTypes.string
  })
};

export default OTPWidget;
