import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

import { 
  Username, 
  Email, 
  CheckBoxCell, 
  DeleteButtonCell, 
  UserInfoCell,
  TableRow 
} from '../styles/styled-components/UsersTableRow';
import UserAvatar from './UserAvatar';
import { UserRole } from '../constants/roles';
import UsersActionModal from './UsersActionModal';
import { HiglightedText } from '../styles/styled-components/UsersActionModal';
import { changeUsersRoles, removeUsersFromSpace } from '../actions/usersActions';
import UserDetailsModal from './UserDetailsModal';
import Checkbox from './Checkbox';
import keyIcon from '../../assets/svg/users/key.svg';
import useCurrentSpace from '../hooks/useCurrentSpace';
import IconButton from './IconButton';

const UsersTableRow = ({ user, checked, toggle, disabled }) => {
  const [ changeRoleModalOpen, setChangeRoleModalOpen ] = useState(false);
  const [ requestData, setRequestData ] = useState(null);
  const [ userDetailsModalOpen, setUserDetailsModalOpen ] = useState(false);
  const [ deleteUserModalOpen, setDeleteUserModalOpen ] = useState(false);

  const match = useRouteMatch();
  const { roles } = useCurrentSpace(match);

  const { id } = useParams();
  
  const dispatch = useDispatch();

  const handleEndUserChange = (e) => {
    setChangeRoleModalOpen(true);
    setRequestData({
      method: e.target.checked ? 'POST' : 'DELETE',
      role: UserRole.END_USER
    });
  };

  const handleFinancialAdminChange = (e) => {
    setChangeRoleModalOpen(true);
    setRequestData({
      method: e.target.checked ? 'POST' : 'DELETE',
      role: UserRole.FINANCIAL_ADMIN
    });
  };

  const handleUserAdminChange = (e) => {
    setChangeRoleModalOpen(true);
    setRequestData({
      method: e.target.checked ? 'POST' : 'DELETE',
      role: UserRole.USER_ADMIN
    });
  };

  const onChageRoleModalToggle = (result) => {
    if (result) {
      dispatch(changeUsersRoles({
        userIds: [ user.id ],
        spaceId: id,
        roles: [ requestData.role ],
      }));
    }

    setChangeRoleModalOpen(false);
  };

  const onDeleteUserModalToggle = (result) => {
    if (result) {
      dispatch(removeUsersFromSpace({
        spaceId: id,
        userIds:[ user.id ]
      }));
    }

    setDeleteUserModalOpen(false);
  };

  const amIOwner = roles.includes(UserRole.OWNER);
  const amIUserAdmin = roles.includes(UserRole.USER_ADMIN);

  const rowBackgroundColor = () => {
    if (disabled) {
      return 'disabled';
    }
    else if (checked) {
      return 'selected';
    }
    else {
      return 'normal';
    }
  };
  const { t } = useTranslation();
  const userDisplayName = `${user?.first_name} ${user?.surname} (${user?.email})`;
  return (
    <>
      {changeRoleModalOpen && (
        <UsersActionModal
          isOpen={changeRoleModalOpen}
          toggle={onChageRoleModalToggle}
          bodyText={t('users_actions_modals.body_text')}
          cancelButtonText={t('users_actions_modals.cancel_button')}
          confirmButtonText={requestData.method === 'POST' ? 
            t('users_actions_modals.grant_role_button') :
            t('users_actions_modals.revoke_role_button')
          }
          confirmButtonColor={requestData.method === 'POST' ? 'blue' : 'red'}
        >
          <Trans i18nKey='users_actions_modals.change_role_body_text'>
            Are you sure you want 
            {{ action: requestData.method === 'POST' ? t('grant') : t('revoke') }} role 
            {{ role: t(`roles.${requestData.role.toLowerCase()}`) }} from user 
            <HiglightedText>{{ username: userDisplayName }}</HiglightedText>?
          </Trans>
        </UsersActionModal>
      )}
      <UsersActionModal
        isOpen={deleteUserModalOpen}
        toggle={onDeleteUserModalToggle}
        bodyText={t('users_actions_modals.body_text')}
        cancelButtonText={t('users_actions_modals.cancel_button')}
        confirmButtonText={t('users_actions_modals.delete_user_button')}
        confirmButtonColor='red'
      >
        <Trans i18nKey='users_actions_modals.delete_user_text'>
            Are you sure you want delete user 
          <HiglightedText>{{ username: userDisplayName }}</HiglightedText>?
        </Trans>
      </UsersActionModal>
      <UserDetailsModal
        isOpen={userDetailsModalOpen}
        toggle={() => setUserDetailsModalOpen(prev => !prev)}
        user={user}
      />
      <TableRow color={rowBackgroundColor()} data-testid='user-row'>
        <UserInfoCell>
          <Row className='align-items-center w-100'>
            <Col md='2'>
              <label className='m-0'>
                <Checkbox
                  outline
                  checked={checked}
                  onChange={() => toggle(user.id)}
                  data-testid='select-user-checkbox'
                  disabled={disabled || !amIUserAdmin}
                />
              </label>
            </Col>
            <Col md='1' className="px-0">
              {
                user.roles.includes(UserRole.OWNER) &&
                <img src={keyIcon} alt='owner icon' className="ml-1"/>
              }
            </Col>
            <Col md='2' className="px-0">
              <UserAvatar 
                username={`${user.first_name} ${user.surname}`} 
                onClick={() => setUserDetailsModalOpen(true)}
              />
            </Col>
            <Col md='7' className="px-0">
              <div className='d-flex flex-column'>
                <Username>{user.first_name}{' '}{user.surname}</Username>
                <Email>{user.email}</Email>
              </div>
            </Col>
          </Row>
        </UserInfoCell>
        <CheckBoxCell>
          <label className='m-0'>
            <Checkbox 
              outline
              onChange={handleEndUserChange}
              checked={user.roles.includes(UserRole.END_USER)}
              data-testid='end-user-checkbox'
              disabled={disabled || !amIUserAdmin}
            />
          </label>
        </CheckBoxCell>
        <CheckBoxCell>
          <label className='m-0'>
            <Checkbox
              outline 
              onChange={handleUserAdminChange}
              checked={user.roles.includes(UserRole.USER_ADMIN)}
              data-testid='user-admin-checkbox' 
              disabled={disabled || !amIOwner}
            />
          </label>
        </CheckBoxCell>
        <CheckBoxCell>
          <label className='m-0'>
            <Checkbox 
              outline
              onChange={handleFinancialAdminChange}
              checked={user.roles.includes(UserRole.FINANCIAL_ADMIN)}
              data-testid='financial-admin-checkbox' 
              disabled={disabled || !amIOwner}
            />
          </label>
        </CheckBoxCell>
        {amIUserAdmin &&
          <DeleteButtonCell>
            {!user?.roles.includes('OWNER') &&
              <div className='d-flex justify-content-center'>
                <IconButton 
                  onClick={() => setDeleteUserModalOpen(true)} 
                  icon='trash'
                  withBorder={false}
                  disabled={disabled || !amIUserAdmin}>
                  {t('delete')}
                </IconButton>
              </div>
            }
          </DeleteButtonCell>
        }
      </TableRow>
    </>
  );
};

UsersTableRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default UsersTableRow;