import styled from 'styled-components';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export const ContractDropdownButton = styled(DropdownToggle)(
  ({ theme, isOpen }) =>`
    background-color: ${theme.colors.white}!important;
    color: ${theme.colors.text.darkGrey};
    border: 1px solid ${theme.colors.lineGrey};
    border-radius: 8px;
    font-size: ${theme.fontSizes.sm};
    display: flex;
    gap: .8rem;
    justify-content: space-between;
    align-items: center;

    &:hover, &:focus, &:active {
      color: ${theme.colors.text.darkGrey}!important;
      border: 1px solid ${isOpen ? theme.colors.secondaryHover : theme.colors.lineGrey};
      box-shadow: none!important;
    }

    &:hover {
      border: 1px solid ${theme.colors.secondaryHover}!important;
      path[fill] {
        fill: ${theme.colors.secondaryHover};
      }
      path[stroke] {
        stroke: ${theme.colors.secondaryHover};
      }
    }
    
    &:disabled {
      border-color: ${theme.colors.text.grey4}!important;
      color: ${theme.colors.text.grey4}!important;
      path[fill] {
        fill: ${theme.colors.text.grey4};
      }
      path[stroke] {
        stroke: ${theme.colors.text.grey4};
      }
    }

    img {
      transition: all .5s;
      transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    path[fill] {
      fill: ${isOpen? theme.colors.secondaryHover : theme.colors.text.lessDarkGrey};
    }
    path[stroke] {
      stroke: ${isOpen? theme.colors.secondaryHover : theme.colors.text.lessDarkGrey};
    }
  `
);

export const ContractDropdownMenu = styled(DropdownMenu)`
  width: 200px;
  margin-top: .5rem;
  box-shadow: 0px 2px 8px rgba(127, 135, 141, 0.08), 0px 20px 32px rgba(72, 79, 86, 0.1);
  border-radius: 6px;
  padding: 6px;
`;

export const ContractDropdownItem = styled(DropdownItem)`
  width: 100%;
  padding: .5rem;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.text.darkGrey};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.blue};
    color: ${({ theme }) =>  theme.colors.secondaryActive} !important;
    a {
      color: ${({ theme }) =>  theme.colors.secondaryActive} !important;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.text.darkGrey};

    &:hover {
      text-decoration: none;
    }
  }
`;