import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  LandingArea,
  HighRow,
  LeftCol,
  RightCol,
  LeftCenteredContent,
  RightCenteredContent,
  Ribbon,
  PlaceholderImg,
  PracelabLogoImg
} from '../styles/styled-components/LandingPage';
import PlaceholderImgURL from '../../assets/svg/landing/placeholder.svg';
import PracelabLogoImgURL from './../../assets/svg/landing/prace-lab_logo.svg';
import FEImgURL from '../../assets/svg/landing/FE.svg';
import EUImgURL from '../../assets/svg/landing/EU.svg';
import PLImgURL from '../../assets/svg/landing/PL.svg';
import InstitutionLogo from '../containers/InstitutionLogo';

const LandingPage = () => {
  const { t } = useTranslation();
  return (
    <LandingArea>
      <HighRow>
        <LeftCol>
          <LeftCenteredContent>
            <InstitutionLogo withName={true}/>
            <h1>{t('landing_page.main_header')}</h1>
            <h2>{t('landing_page.main_subheader')}</h2>
          </LeftCenteredContent>
          <Ribbon>
            <img src={FEImgURL} alt="Fundusze Europejskie - Inteligentny Rozwój"/>
            <img src={PLImgURL} alt="Rzeczpospolita Polska"/>
            <img src={EUImgURL} alt="Unia Europejska - Europejski Fundusz Rozwoju Regionalnego"/>
          </Ribbon>
        </LeftCol>
        <RightCol>
          <RightCenteredContent>
            <PlaceholderImg src={PlaceholderImgURL} alt="placeholder"/>
          </RightCenteredContent>
          <PracelabLogoImg src={PracelabLogoImgURL} alt="prace-lab logo"/>
        </RightCol>
      </HighRow>
    </LandingArea>
  );
};

export default LandingPage;
