import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UndecoratedLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :hover {
    text-decoration: none;
    color: inherit;
 }
`;
