import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Card } from 'reactstrap';

import Service from '../../components/Service';

const ServiceRow = ({ id, name, offerData, offer_id, state, preview=false, inactive=false }) => {
  const { t } = useTranslation();
  if (offer_id) {
    return (
      <Service
        id={id}
        state={state}
        offerData={offerData}
        preview={preview}
        name={name}
        inactive={inactive}
      />
    );
  }
  else {
    return (
      <Col key={id} lg='3' md='6' className='my-3'>
        <Card className='p-4'>
          <div className="text-center py-3 my-4">
            <h5>
              {t('offers.unknown_offer')}
            </h5>
          </div>
          <div className="text-center py-3">
            <div className='font-md'>
              {id}
            </div>
          </div>
        </Card>
      </Col>
    );
  }
};

ServiceRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  offer_id: PropTypes.number,
  offerData: PropTypes.arrayOf(PropTypes.object),
  favorites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    space: PropTypes.string
  })),
  favoritesUpdate: PropTypes.func,
  state: PropTypes.string,
  preview: PropTypes.bool,
  inactive: PropTypes.bool
};

export default ServiceRow;