import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../components/UserAvatar';
import usePopover from '../hooks/usePopover';
import {
  Popover,
  Username,
  Email,
  MenuLabel,
  MenuItem,
  LogoutButton
} from '../styles/styled-components/UserProfilePopover';

const UserProfilePopover = ({ 
  coords, 
  entered, 
  username, 
  email, 
  onClose, 
  onUpdateCoords, 
  onLogout, 
  profileRedirect 
}) => {
  const { t } = useTranslation();
  const popoverRef = useRef();
  usePopover({
    ref: popoverRef,
    onClose,
    entered,
    onUpdateCoords
  });

  useEffect(() => {
    const element = popoverRef.current;

    const clickListener = (e) => {
      if (e.target.tagName && e.target.tagName.toUpperCase() === 'A') {
        onClose();
      }
    };

    element.addEventListener('click', clickListener);

    return () => {
      element.removeEventListener('click', clickListener);
    };
  }, [ onClose ]);

  return (
    <Popover top={coords.top} right={coords.right} className='shadow' ref={popoverRef}>
      <div className="p-3">
        <div className="d-flex flex-column align-items-center pb-2 px-2">
          <div className="mb-2">
            <UserAvatar username={username} disabledTooltip/>
          </div>
          <Username>{username}</Username>
          <Email>{email}</Email>
        </div>
        <hr className='my-2' />
        <div className='d-flex flex-column px-2'>
          <MenuLabel>{t('user_profile_popover.account')}</MenuLabel>
          <ul>
            <MenuItem>
              <a href={profileRedirect}>
                {t('user_profile_popover.edit_profile')}
              </a>
            </MenuItem>
            <MenuItem>
              <Link to='/affiliations'>
                {t('user_profile_popover.affiliations')}
              </Link>
            </MenuItem>
          </ul>
        </div>
        <hr className='my-2' />
        <div className="px-2">
          <LogoutButton onClick={onLogout}>
            {t('user_profile_popover.logout_button')}
          </LogoutButton>
        </div>
      </div>
    </Popover>
  );
};

UserProfilePopover.propTypes = {
  coords: PropTypes.object.isRequired,
  entered: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateCoords: PropTypes.func.isRequired,
  profileRedirect: PropTypes.string,
  onLogout: PropTypes.func.isRequired
};

export default UserProfilePopover;