import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import {
  LineRow,
  Label,
} from '../../styles/styled-components/SettlementDetails';
import { ReactComponent as AttachIcon } from '../../../assets/svg/common/attach.svg';

const RaportDetails = ({ report }) => {
  const { t } = useTranslation();

  if (!report) {
    return null;
  }

  return (
    <Card className="p-4">
      <h6 className="font-lg fontWeight-medium">
        {t("settlements_details.report_details")}
      </h6>
      <span>
        <b>{t("reports.synopsis")}:</b>
      </span>
      <div className="py-2">{report.summary?.text}</div>
      <Container>
        <Row className="bg-light mt-2 p-4 rounded">
          <Col className="align-self-center text-muted">
            {report.summary?.file_reference ? (
              <p className="m-0 font-sm fontWeight-medium text-truncate">
                <AttachIcon className="mr-2" />
                {report.summary?.file_reference?.split("/").slice(-1)}
              </p>
            ) : (
              t("settlements_details.no_attachment")
            )}
          </Col>
        </Row>
      </Container>

      <h6 className="pt-4 font-lg fontWeight-medium">
        {t("reports.publications")}
      </h6>
      <Container>
        {report.publications?.map((publication, index) => (
          <LineRow
            key={index}
            className="mt-2 pt-2 font-sm fontWeight-medium text-truncate py-4"
          >
            <Col className="align-self-center p-0">
              {publication.doi ? (
                <Link to={publication.doi}>{publication.doi}</Link>
              ) : (
                <Label>({t("settlements_details.no_adress")})</Label>
              )}
            </Col>
            <Col className="align-self-center float-right p-0">
              <div className="float-right">
                {publication.file_reference ? (
                  <p className="m-0 text-truncate">
                    <AttachIcon className="mr-2" />
                    {publication.file_reference?.split("/").slice(-1)}
                  </p>
                ) : (
                  <Label>({t("settlements_details.no_attachment")})</Label>
                )}
              </div>
            </Col>
          </LineRow>
        ))}
      </Container>
    </Card>
  );
};

RaportDetails.propTypes = {
  report: PropTypes.shape({
    summary: PropTypes.shape({
      text: PropTypes.string,
      file_reference: PropTypes.string,
    }),
    publications: PropTypes.arrayOf(
      PropTypes.shape({
        doi: PropTypes.string,
        file_reference: PropTypes.string,
      })
    ),
  }),
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(RaportDetails);
