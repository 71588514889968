import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
  DateContainer,
  Icon,
  InputContainer,
  InputDate,
  VerticalLine
} from '../../styles/styled-components/Settlements.js';
import { calendarIcon } from '../../../assets/svg/settlements/icons.js';

export const Calendar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  
  const searchParams = new URLSearchParams(location.search);
  const getEndDate = searchParams.get("endDate");
  const getStartDate = searchParams.get("startDate");

  const formatDateToLocalISOString = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - (offset * 60000));
    
    return localDate.toISOString().split('T')[0];
  };
  
  const handelData = (key, value) => {
    searchParams.set(key, formatDateToLocalISOString(value));
   
    history.push({ search: searchParams.toString() });
  };

  return (
    <DateContainer>
      <VerticalLine />
      <Col>
        <Row className="p-0">
          <Col xs="6" className="px-2">
            <InputContainer style={{ width: "136.5px" }}>
              <InputDate
                type="date"
                selected={getStartDate ? new Date(getStartDate) : ""}
                onChange={date => handelData("startDate", new Date(date))}
                min={new Date().toISOString().slice(0, 10)}
                className="ml-auto"
                placeholderText={t('settlements.calendar_from')}
                maxDate={getEndDate && new Date(getEndDate)}
              />
              <Icon right src={calendarIcon} alt="CalendarIcon" />
            </InputContainer>
          </Col>
          <Col xs="6" className="px-2">
            <InputContainer style={{ width: "136.5px" }}>
              <InputDate
                type="date"
                selected={getEndDate ? new Date(getEndDate) : ""}
                onChange={date => handelData("endDate", date)}
                className="ml-auto"
                placeholderText={t('settlements.calendar_to')}
                minDate={getStartDate && new Date(getStartDate)}
              />
              <Icon right src={calendarIcon} alt="CalendarIcon" />
            </InputContainer>
          </Col>
        </Row>
      </Col>
    </DateContainer>
  );
};

export default Calendar;