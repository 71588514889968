import React, { useState } from 'react';
import  PropTypes  from 'prop-types';
import { Tooltip } from 'reactstrap';

import { TileCardLink } from '../../styles/styled-components/TileCard';
import { Header } from '../../styles/styled-components/Breadcrumbs';

const Breadcrumbs = ({ dataLocations }) => {
  const [ isOpenTooltipId, setIsOpenTooltipId ] = useState(null);
  const [ isTextOverflow, setIsTextOverflow ] = useState(false);

  const toggle = (id) => setIsOpenTooltipId(isOpenTooltipId === id ? null : id);

  const checkTextOverflow = (element) => {
    setIsTextOverflow(element.offsetWidth < element.scrollWidth);
  };

  return (
    <Header isTextOverflow={isTextOverflow}>
      {dataLocations?.map((el, index) => (
        el && (
          <>
            <TileCardLink 
              id={`title-${index}`} 
              to={el?.location} 
              style={{ fontSize: "1.25rem" }} 
              onMouseEnter={(event) => {
                checkTextOverflow(event.target);
                toggle(`title-${index}`);
              }} 
              onMouseLeave={() => toggle(null)}
            >
              {el.title}
            </TileCardLink>
            {index + 1 !== dataLocations.length && ">"}
            {isTextOverflow && 
            <Tooltip
              isOpen={isOpenTooltipId === `title-${index}`}
              toggle={() => toggle(null)}
              placement="top"
              target={`title-${index}`}
            >
              {el?.title}
            </Tooltip>}
          </>
        )
      ))}
    </Header>
  );
};

Breadcrumbs.propTypes = {
  dataLocations: PropTypes.array
};

export default Breadcrumbs ;