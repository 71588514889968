import { toastr } from 'react-redux-toastr';
import iconError from '../../assets/svg/common/notify/error.svg';
import iconSuccess from '../../assets/svg/common/notify/success.svg';
import iconWarning from '../../assets/svg/common/notify/warning.svg';
import iconInfo from '../../assets/svg/common/notify/info.svg';

const toastrErrorOptions = {
  removeOnHover: false,
  icon: (<img src={iconError} alt="Error icon." width="20px"/>)
};

export const clean = () => {
  toastr.clean();
};

export const error = (title, message) => {
  toastr.error(title, message, toastrErrorOptions);
};

const toastrSuccessOptions = {
  timeOut: 5000,
  removeOnHover: true,
  icon: (<img src={iconSuccess} alt="Success icon." width="22px"/>)
};

export const success = (title, message) => {
  toastr.success(title, message, toastrSuccessOptions);
};

const toastrWarningOptions = {
  timeOut: 6000,
  removeOnHover: true,
  icon: (<img src={iconWarning} alt="Warning icon." width="20px"/>)
};

export const warning = (title, message) => {
  toastr.warning(title, message, toastrWarningOptions);
};

const toastrInfoOptions = {
  timeOut: 5000,
  removeOnHover: true,
  icon: (<img src={iconInfo} alt="Info icon." width="22x"/>)
};

export const info = (title, message) => {
  toastr.info(title, message, toastrInfoOptions);
};
