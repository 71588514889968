import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../components/Checkbox';
import { SelectAllLabel } from '../../styles/styled-components/SpaceUsers';
import { selectAllUsers, unselectAllUsers } from '../../actions/usersActions';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import { UserRole } from '../../constants/roles';

const SelectAllUsersCheckbox = () => {
  const [ allUsersSelected, setAllUsersSelected ] = useState(false);
  const selectedUsersCount = useSelector((state) => state.users.get('selectedUsers').size);
  const allUsersCount = useSelector((state) => state.users.get('allUsers').get('data').items.length);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const match = useRouteMatch();
  const { roles } = useCurrentSpace(match);
  const amIUserAdmin = roles.includes(UserRole.USER_ADMIN);

  useEffect(() => {
    setAllUsersSelected(selectedUsersCount === allUsersCount);
  }, [ selectedUsersCount, allUsersCount ]);

  const handleChange = (e) => {
    setAllUsersSelected(prev => !prev);

    if (e.target.checked) {
      dispatch(selectAllUsers());
    }
    else {
      dispatch(unselectAllUsers());
    }
  };

  return (
    <div className='d-flex py-3 pl-3'>
      <label className='mb-0 mr-4'>
        <Checkbox
          id='select-all'
          name='select-all'
          checked={allUsersSelected}
          onChange={handleChange}
          disabled={!amIUserAdmin}
        />
      </label>
      <SelectAllLabel htmlFor='select-all'>{t('space_users.select_all')}</SelectAllLabel>
    </div>
  );
};

export default SelectAllUsersCheckbox;
