import React from 'react';
import PropTypes from 'prop-types';

import { openFile } from '../../utils/downloadFilesFromIBIS';
import Checkbox from '../Checkbox';

const CheckboxWithLinkWidget = ({
  options,
  value,
  onChange,
  schema
}) => {
  const { linkDisplayText, linkAddress } = options;

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    onChange(newValue);
  };

  return (
    <div className="d-flex align-items-center">
      <label className='m-0 mr-2'>
        <Checkbox
          required={true}
          outline 
          checked={value}
          onChange={handleCheckboxChange}
        />
      </label>
      <label className='m-0 font-sm'>
        <span>
          {schema?.title}{" "}
          <a 
            href="/"
            onClick={(e) => { e.preventDefault(); openFile(linkAddress); }} 
            rel="noopener noreferrer"
          >
            {linkDisplayText}
          </a>
        </span>
      </label>
    </div>
  );
};

CheckboxWithLinkWidget.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    linkAddress: PropTypes.string,
    linkDisplayText: PropTypes.string,
  }).isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  schema: PropTypes.object
};

export default CheckboxWithLinkWidget;