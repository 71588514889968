import { combineReducers } from 'redux';
import { Map } from 'immutable';
import {
  FETCH_SERVICES_LIST_REQUEST,
  FETCH_SERVICES_LIST_ERROR,
  FETCH_SERVICES_LIST_SUCCESS
} from '../actions/servicesActions';

const initialState = Map({
  isLoading: false,
  data: [],
  error: null,
});

const servicesListReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SERVICES_LIST_REQUEST:
    return state.set("isLoading", true);
  case FETCH_SERVICES_LIST_SUCCESS:
    return state.set("isLoading", false).set("data", action.payload);
  case FETCH_SERVICES_LIST_ERROR:
    return state.set("isLoading", false).set("error", action.payload);
  default:
    return state;
  }
};

export const servicesReducers = combineReducers({
  servicesList: servicesListReducer
});