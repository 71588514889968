import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { __env } from '../../../../envloader';
import axios from '../../../../main/utils/axios/axiosInstance';
import Checkbox from '../../Checkbox';
import Loading from '../../../../main/components/loading';
import { BtnTextBlue, BtnTextRed } from '../../../styles/styled-components/Button';
const UserRow = ({ user, checked, onCheckboxChange, refetch, invitationData }) => {
  const [ isSending, setIsSending ] = useState(false);
  const { t } = useTranslation();

  const sendRequest = (decision) => {
    setIsSending(true);
    axios.put(
      `${__env.BPM_API_URL}spaces/invitations/public/handling/${user.invitation_id}/${decision}?secret=${invitationData.secret}`,
      {
        user: user.triggering_user_userId,
      },
    ).then(() => {
      refetch();
    }).finally(() => {
      setIsSending(false);
    });
  };
  return (
    <Row className='mb-3 align-items-center'>
      <Col>
        <div className="d-flex align-items-center">
          <label className='m-0 mr-2'>
            <Checkbox
              outline 
              checked={checked}
              onChange={() => onCheckboxChange(user.invitation_id)}
            />
          </label>
          <label className='m-0 font-sm'>{user.triggering_user_name}</label>
        </div>
      </Col>
      <Col>
        <label className='m-0 font-sm'>{user.triggering_user_email}</label>
      </Col>
      <Col>
        <div className="d-flex justify-content-end align-items-center">
          {isSending ? <Loading/> : 
            <div className='d-flex justify-content-around'>
              <BtnTextBlue onClick={() => sendRequest('accept')} className='p-0 m-0'>
                {t('public_invitations.accept')}
              </BtnTextBlue>
              <BtnTextRed $fweight="500" onClick={() => sendRequest('reject')} className='p-0 ml-4'>
                {t('public_invitations.reject')}
              </BtnTextRed>
            </div>}
        </div>
      </Col>
    </Row>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    invitation_id: PropTypes.string,
    triggering_user_userId: PropTypes.string,
    triggering_user_name: PropTypes.string,
    triggering_user_email: PropTypes.string,
  }),
  invitationData: PropTypes.shape({
    secret: PropTypes.string.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  onCheckboxChange: PropTypes.func
};

export default UserRow;