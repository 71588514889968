import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Card, Collapse } from 'reactstrap';
import { withTheme } from '@rjsf/core';
import styled from 'styled-components';

import backendTranslation from '../utils/backendTranslation';
import Loading from '../../main/components/loading';
import { Button, AngleDown } from '../styles/styled-components/ServiceUsageBar';
import { Title } from '../styles/styled-components/ServiceDetails';

const ServiceDetailsForm = ({ serviceData, offerData, isLoadingOffert }) => {
  const { t } = useTranslation();

  const [ isOpen, setIsOpen ] = useState(false);
  const jsonSchema = offerData?.json_schema;

  const formData = useMemo(() => {
    const parameters = serviceData?.parameters;
    return parameters && Object.keys(parameters).reduce((obj, key) => {
      return {
        ...obj,
        [key]: parameters[key]?.value,
      };
    }, {});
  }, [ serviceData ]);

  const checkCustomSelect = (value) => {
    if (value === "true") {
      return t('active_filters.yes');
    } 
    if (value === "false") {
      return t('active_filters.no');
    } 
    return value;
  };

  const CustomText = ({ value }) => (
    <span>
      <b>{checkCustomSelect(value)}</b>
    </span>
  );
  
  const CustomSelect = ({ label, value }) => {
    return (value && label) ? <span><b>{checkCustomSelect(value)}</b></span> : null;
  };

  CustomText.propTypes = {
    value: PropTypes.number
  };
  
  CustomSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
  };

  const FormWithTheme = withTheme({
    widgets: {
      text: CustomText,
      select: CustomSelect
    }
  });

  const StyledFormWithTheme = styled(FormWithTheme)` 
    #root {
      width: 100%;
    }

    .form-group {
      padding-right: 2rem;
    }

    .form-group label {
      word-wrap: break-word;
    }

    .control-label::after {
      content: ":";
      margin: 0 .25rem 0 .1rem;
    }

    .form-group span b {
      word-break: break-all;
    }
  `;

  if (isLoadingOffert){
    return <Loading />;
  }

  if (!offerData?.json_schema){
    return (
      <Alert color="danger">
        {t('services.offer_data_loading_error', { id: serviceData?.parameters?.bpm_params_offer_id?.value })}
      </Alert>
    );
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Button className="mb-3 pl-0 border-0 bg-transparent d-flex align-items-center" type="button" onClick={() => setIsOpen(!isOpen)}>
          <Title>{t('services_details.service_parameters')}</Title>
          <AngleDown className="ml-2" reverse={isOpen ? 1 : 0} />
        </Button>
      </div>
      <Collapse isOpen={isOpen} >
        { jsonSchema && Object.keys(jsonSchema).length > 0 && Object.keys(backendTranslation(jsonSchema)?.properties).length > 0 ?
          <Card>
            <StyledFormWithTheme
              schema={backendTranslation(jsonSchema, {})}
              formData={formData}
              className="serviceParameters"
              disabled
            >
              <div/>
            </StyledFormWithTheme>
          </Card>
          : 
          t('no_parameters')
        }
      </Collapse>   
    </div>
  );
};

ServiceDetailsForm.propTypes = {
  serviceData: PropTypes.object,
  offerData: PropTypes.object,
  isLoadingOffert: PropTypes.bool,
};

export default ServiceDetailsForm;
