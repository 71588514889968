import styled from 'styled-components';

const getColorValue = (color) => {
  switch (color) {
  case 'disabled':
    return '#F1F2F2';
  case 'selected':
    return 'rgba(15, 161, 255, 0.1)';
  default: 
    return '#fff';
  };
};

const Cell = styled.td`
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.text.grey5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.grey5};
`;

export const TableRow = styled.tr`
  background-color: ${({ color }) => getColorValue(color)};
`;

export const UserInfoCell = styled(Cell)`
  width: 46%;
  border-left: 0;
`;

export const CheckBoxCell = styled(Cell)`
  width: 13.5%;
  text-align: center;
`;

export const DeleteButtonCell = styled(Cell)`
  width: 13.5%;
  border-right: 0;
`;

export const Username = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Email = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;