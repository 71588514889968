import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

export const Button = styled.button`
  border: none;
  background-color: white;
`;

export const AngleDown = styled(FaAngleDown)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  transition: transform .5s ease;
  transform: ${(props) => props.reverse ? 'rotate(180deg)' : 'rotate(0)'};
`;

export const TableTh = styled.th`
  border-top: 0!important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.greyDivider}!important;
  color: ${({ theme }) => theme.colors.text.grey2};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const TextBold = styled.p`
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TextBlue = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.link};
`;

export const TextGrey = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.text.grey1_5};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const UserRow = styled.div`
  display: flex;
  margin: 1rem 3rem;
  text-align: left;
`;

export const UserContainer = styled.div`
  display: flex;
  min-width: 240px;
`;

export const UserImage = styled.div`
  height: 24px;
  width: 24px;
  background-color: #3f3f3f;
  border-radius: 50%;
`;