import { useSelector } from 'react-redux';

const useContractState = () => {
  const contractDetails = useSelector((state) => state.contract.contractDetails.get('data'));
  const contractDetailsLoading = useSelector((state) => state.contract.contractDetails.get('isLoading'));
  const contractBilling = useSelector((state) => state.contract.billingDetails.get('data'));
  const contractBillingLoading = useSelector((state) => state.contract.billingDetails.get('isLoading'));
  const contractEvents = useSelector((state) => state.contract.contractEvents.get('data'));
  const contractEventsLoading = useSelector((state) => state.contract.contractEvents.get('isLoading'));

  return {
    contractDetails,
    contractDetailsLoading,
    contractBilling,
    contractBillingLoading,
    contractEvents,
    contractEventsLoading
  };
};

export default useContractState;