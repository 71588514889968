import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { Table, Th } from '../styles/styled-components/Affiliations';
import { HiglightedText } from '../styles/styled-components/UsersActionModal';
import axios from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';
import i18n from '../../main/utils/i18n';
import * as notify from '../../main/utils/notify';
import UsersActionModal from './UsersActionModal';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { compose } from 'redux';
import Affiliation from './Affiliation';

const AffiliationsTable = ({ affiliations, refresh }) => {

  const { t } = useTranslation();

  const [ loading, setLoading ] = useState(false);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ selectedAffiliation, setSelectedAffiliation ] = useState();

  const modalService = (accepted) => {
    if (accepted) {
      deleteAffiliation(selectedAffiliation);
      setSelectedAffiliation(null);
    }
    setIsModalOpen(false);
  };

  const deleteAffiliation = (id) => {
    setLoading(true);
    axios.delete(`${__env.BPM_API_URL}affiliations/${id}`).then(() => {
      refresh().then(() => {
        notify.success(
          i18n.t('affiliations.notifications.affiliation_deleted_success_title'),
          i18n.t('affiliations.notifications.affiliation_deleted_success_body')
        );
      }).finnaly(() => {
        setLoading(false);
      });
    }).catch(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <UsersActionModal 
        isOpen={isModalOpen} 
        cancelButtonText={t('affiliations_table.modal.cancel')} 
        confirmButtonText={t('affiliations_table.modal.accept')}
        confirmButtonColor="red"
        bodyText={
          <Trans i18nKey="affiliations_table.modal.body">Remember that when you terminate an affiliation <b>you won't be able</b> to undo the action.</Trans>
        }
        toggle={modalService}
      >
        <Trans i18nKey="affiliations_table.modal.title">
          Are you sure you want to<br /> <HiglightedText>terminate the affiliation</HiglightedText>?
        </Trans>
      </UsersActionModal>
      <Table>
        <thead>
          <tr>
            <Th>{t('affiliations_table.table_header.institution')}</Th>
            <Th>{t('affiliations_table.table_header.type')}</Th>
            <Th>{t('affiliations_table.table_header.updated')}</Th>
            <Th>{t('affiliations_table.table_header.expires')}</Th>
            <Th>{t('affiliations_table.table_header.actions')}</Th>
          </tr>
        </thead>
        <tbody>
          {affiliations.map(item => (
            <Affiliation 
              item={item} 
              loading={loading} 
              isModalOpen={isModalOpen} 
              setIsModalOpen={setIsModalOpen}
              setSelectedAffiliation={setSelectedAffiliation} 
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

AffiliationsTable.propTypes = {
  affiliations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.string,
    updated: PropTypes.string,
    expires: PropTypes.string,
    affiliation_type: PropTypes.string,
    index_number: PropTypes.string,
    affiliation_institution: PropTypes.string
  })).isRequired,
  refresh: PropTypes.func.isRequired
};
export default compose(
  ErrorBoundary((props) => props.t('affiliations_page.error_boundary'))
)(AffiliationsTable);
