import React from 'react';
import { useRouteMatch } from 'react-router';
import { Container, Item } from '../styles/styled-components/ServicesList';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const ServicesList = () => {
  const location = useLocation();
  const match = useRouteMatch("/spaces/:id/");
  const services = useSelector(state => state.services.servicesList.get('data'));
  const activeServices = services?.filter(item => ![ "TERMINATED", "EXPIRED" ].includes(item.state));
  
  return (
    <>
      {activeServices &&
        <Container>
          {activeServices.map((service) => (
            <Item
              key={service.id}
              to={`/spaces/${match.params.id}/services/${service.id}`}
              active={location.pathname.includes(service.id)}
              lengthItem={service?.name.length}
            >
              {service?.name}
            </Item>
          ))}
        </Container>
      }
    </>
  );
};

export default ServicesList;
