import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { __env } from '../../envloader';
import * as notify from '../../main/utils/notify';
import axios from '../../main/utils/axios/axiosInstance';
import { StyledCard } from '../styles/styled-components/AffiliationCategory';
import backendTranslation from '../utils/backendTranslation';
import { multiLangType } from '../types';
import { TileParentCol, TileTitile, ImgContainer } from '../styles/styled-components/TileCard';
import { BtnBlue } from '../styles/styled-components/Button';
import CollapsableTextField from './CollapsableTextField';

const AffiliationCategory = ({ affiliation }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onAffiliationChoose = affiliation_type => () => {
    axios.post(
      __env.BPM_API_URL + 'affiliations',
      { affiliation_type }
    )
      .then((response) => {
        notify.info(
          t('affiliation_category.notifications.process_initiated_success_title'),
          t('affiliation_category.notifications.process_initiated_success_body')
        );
        history.push('/affiliations/new_affiliation_form/' + response.data.processName);
      });
  };

  return (
    <TileParentCol data-testid='affiliation-category'>
      <StyledCard className='px-4 py-3'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <TileTitile>{backendTranslation(affiliation.affiliation_name)}</TileTitile>
          <ImgContainer>
            <img src={affiliation.tile_icon} alt='affiliation icon' className="h-100 p-2" />
          </ImgContainer>
          <BtnBlue
            color='primary'
            className='mb-3 px-5 py-1'
            onClick={onAffiliationChoose(affiliation.reference_id)}
          >
            {t('affiliation_category.add')}
          </BtnBlue>
        </div>
        <hr />
        <div className='mt-3 mb-3'>
          <div className='font-weight-bold'>{t('affiliation_category.for_who')}:</div>
          <CollapsableTextField numOfLines={2}>{backendTranslation(affiliation.target_group)}</CollapsableTextField>
        </div>
        <div className='mb-3'>
          <div className="font-weight-bold">{t('affiliation_category.let_you_conclude')}:</div>
          <CollapsableTextField numOfLines={2}>{backendTranslation(affiliation.title)}</CollapsableTextField>
        </div>
      </StyledCard>
    </TileParentCol>
  );
};

AffiliationCategory.propTypes = {
  affiliation: PropTypes.shape({
    reference_id: PropTypes.string.isRequired,
    affiliation_name: PropTypes.shape(multiLangType).isRequired,
    target_group: PropTypes.shape(multiLangType).isRequired,
    title: PropTypes.shape(multiLangType).isRequired,
    tile_icon: PropTypes.string.isRequired
  }).isRequired
};

export default AffiliationCategory;