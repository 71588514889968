import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const LowerCaseRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest}
      render={props => 
        props.location.pathname === props.location.pathname.toLowerCase() 
          ? <Component {...props} /> 
          : <Redirect to={props.location.pathname.toLowerCase()} />
      }
    />
  );
};

LowerCaseRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default LowerCaseRoute;