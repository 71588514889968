import styled from 'styled-components';

export const AccessibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  justify-content: center;
  margin: 1.5rem auto;
`;

export const MainTitle = styled.h1`
  ${({ theme }) => `   
      font-size: ${theme.fontSizes.xl3};
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.points.blue4};
  `}
  letter-spacing: .7px;
`;

export const CustomLink = styled.a`
  color: ${({ theme }) => theme.colors.link};
  &:hover { color: ${({ theme }) => theme.colors.text.blueMore}; };
  &:active { color: ${({ theme }) => theme.colors.text.blueMost}; } 
  &:disabled { color: ${({ theme }) => theme.colors.secondaryDisabled}; } 
`;

export const StyledHr = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.text.grey3};
  margin: .5rem 0 2rem 0;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.xl1};
    font-weight: ${theme.fontWeights.bold};
  `}
  line-height: 1.3em;
  letter-spacing: .7px;
  padding-bottom: .6rem;
  margin-top: 2rem;
`;