import styled from 'styled-components';
import { DropdownToggle, DropdownMenu } from 'reactstrap';
import { FiChevronDown } from 'react-icons/fi';

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm_md};
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const TextBold = styled.p`
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TextIdService = styled.p`
  margin-bottom: .5rem;
  color: ${({ theme }) => theme.colors.text.darkGrey};
`;

export const TextGrey = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.text.grey1_5};
`;

export const TextBlue = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.link};
`;

export const TextLink = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`; 

export const StatusPoint = styled.div`
  border-radius: 50%;
  height: 8px;
  width: 8px; 
  background-color: ${({ theme }) => theme.colors.status.green};
  margin-right: .5rem;
`;

export const DescriptionPoint = styled.div`
  border-radius: 50%;
  height: 8px;
  width: 8px; 
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-right: 1rem;
  margin-top: .5rem;
`;

export const EditButton = styled.button`
  background-color: #FFF;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ primary, theme }) => primary ? theme.colors.link : theme.colors.text.lessDarkGrey };
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

// Status dropdown

export const StatusChooser = styled(DropdownToggle)`
  background-color: #FFF;
  color: ${({ theme, disabled }) => disabled ? theme.colors.secondaryDisabled : theme.colors.text.darkGrey};
  border-radius: 8px;
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.secondaryDisabled : theme.colors.secondary};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  &:hover {
    ${props => !props.disabled && `
      background-color: #FFF;
      color: ${({ theme }) => theme.colors.text.darkGrey}; 
    `}
  }

  span {
    margin: 0 16px;
    padding: 9px 0;
    font-size: 14px;
    width: 70px;
    text-align: left;
  }

  div {
    border-left: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.secondaryDisabled : theme.colors.secondary};
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.white : theme.colors.background.blue};
    border-radius: 0 8px 8px 0;
    width: 38px;
    height: 100%;
    padding: 7px 0;
  }

`;

export const ChevronDownIcon = styled(FiChevronDown)`
  color: ${({ theme }) => theme.colors.text.lessDarkGrey};
  width: 22px;
  height: 22px;
`;

export const ChooseStatusMenu = styled(DropdownMenu)`
  box-shadow: 0px 2px 8px rgba(127, 135, 141, 0.08), 0px 20px 32px rgba(72, 79, 86, 0.1);
  border-radius: 6px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  padding: 1rem;
`;

export const StatusMenuOption = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: .5rem .5rem .5rem .8rem;
  border-radius: 6px;
  margin-bottom: .5rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.darkGrey};
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.blue};
    color: ${({ theme }) => theme.colors.secondaryHover};
  }
`;

export const StatusMenuTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
  margin-bottom: 2rem;
`;

export const StatusMenuTitleBlue = styled.span`
  color: ${({ theme }) => theme.colors.link};
`;