import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const DefaultTooltip = ({ target, placement, children }) => {

  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  return (
    <Tooltip 
      className="tooltip-normal"
      target={target} 
      placement={placement}
      isOpen={tooltipOpen}
      toggle={() => setTooltipOpen(!tooltipOpen)}
    >
      {children}
    </Tooltip>
  );
};

DefaultTooltip.propTypes = {
  target: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.node
};

export default DefaultTooltip;