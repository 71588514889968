import styled, { keyframes } from 'styled-components';

import { InputDateLocal } from '../../utils/componentsLocal';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const expandAnimation = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: auto;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid #B0B0B0;
  height: 36px;
  width: 0%;
  margin: 0 1rem 0 1rem;
`;

export const Icon = styled.img`
  ${({ right }) => right ? "right: 12px" : "left: 12px"};
  position: absolute;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 36px;
`;

export const InputDate = styled(InputDateLocal)`
  border-radius: 6px;
  width: 136.5px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.text.grey1_5};
  padding: .3rem 1rem;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text.blackFocus};
    outline: none;
    box-shadow: none;
  }
`;

export const DateContainer = styled.div`
  display: flex;
`;

export const OverDueDate = styled.span`
  color: #D20724;
`;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  max-height: 500px;
  overflow: hidden;
  animation: ${expandAnimation} 1s ease-out forwards;
`;

export const SpaceFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActiveFilters = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ActiveFiltersTitle = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 0 15px 16px 0;
  height: 20px;

  color: ${({ theme }) => theme.colors.text.veryDarkGray};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 18px 16px 0;
  height: 18px;
  gap: 4px;
  cursor: pointer;
  animation: ${fadeIn} .5s ease-in-out forwards;

  color: ${({ theme }) => theme.colors.text.darkGrey};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 18.23px;
`;

export const ClearAllButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  max-height: 18px;
  min-width: 113px;
  margin-bottom: 16px;
  animation: ${fadeIn} .5s ease-in-out forwards;

  color: ${({ theme }) => theme.colors.link};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 18.23px;
`;