import { __env } from '../../envloader';
import { mergeUsersWithRoles } from '../utils/users';
import axios from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import i18n from '../../main/utils/i18nConfigProvider';
import { getOwnRoles } from './spacesActions';
import backendTranslation from '../utils/backendTranslation';

const namespace = '@PRACELAB_USERS_TABLE/';

export const FETCH_USERS_REQUEST = namespace + 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = namespace + 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = namespace + 'FETCH_USERS_ERROR';
export const SELECT_USER = namespace + 'SELECT_USER';
export const UNSELECT_USER = namespace + 'UNSELECT_USER';
export const SELECT_ALL_USERS = namespace + 'SELECT_ALL_USERS';
export const UNSELECT_ALL_USERS = namespace + 'UNSELECT_ALL_USERS';

const sortByUserName = (list) => 
  list.map(({ roles, ...rest }) => rest).sort((a, b) => (a.user_name ?? '').localeCompare(b.user_name ?? ''));

export const getUsersWithRoles = (spaceId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USERS_REQUEST });

    const { data: users } =
      await axios
        .get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${spaceId}/users`);

    const { data: roles } =
      await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${spaceId}/user_roles`);

    const payload = {
      matching_count: users.matching_count,
      items: mergeUsersWithRoles(roles, users)
    };


    dispatch({ type: FETCH_USERS_SUCCESS, payload });
  }
  catch (err) {
    dispatch({
      type: FETCH_USERS_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

export const refreshUsers = (spaceId) => (dispatch, getState) => {
  const currentUsers = getState().users.get('allUsers').get('data');

  Promise.all([
    axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${spaceId}/users`),
    axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${spaceId}/user_roles`)
  ]).then(([ usersResponse, rolesResponse ]) => {
    if (JSON.stringify(sortByUserName((currentUsers?.items))) !== JSON.stringify(usersResponse.data?.items)) {
      dispatch({ type: FETCH_USERS_REQUEST });
      setTimeout(() => {
        const payload = {
          matching_count: usersResponse.data.matching_count,
          items: mergeUsersWithRoles(rolesResponse.data, usersResponse.data)
        };
        dispatch({ type: FETCH_USERS_SUCCESS, payload });
      }, 500);
    }
  }).catch(err => {
    dispatch({
      type: FETCH_USERS_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  });
};

//If no roles are provided, this function will delete all of the specified users' roles
//If roles are provided, this function will toggle the provided roles for all of the specified users
export const changeUsersRoles = ({ spaceId, userIds, roles }) => (dispatch, getState) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  const users = getState().users.get('allUsers').get('data').items.filter(({ id }) => userIds.includes(id));
  Promise.all(users.map(async (user) => {
    await Promise.all((roles ?? user.roles).map(async (role) => {
      await axios.post(
        `${__env.BPM_API_URL}spaces/${user.roles.includes(role) ? 'delete' : 'add'}_role`,
        {
          space_id: spaceId,
          user_id: user.id,
          role: role
        },
        {
          __silentFor: 'all',
        }
      );
    }));
  })).then(() => {
    dispatch(getOwnRoles(true));
  }).then(() => {
    notify.success(
      i18n.t('users_actions.notfications.success_title'),
      i18n.t('users_actions.notfications.change_roles_success_body')
    );
  }).catch((res) => {
    const errMsg = res.response.data?.message
      ? backendTranslation(res.response.data.message)
      : i18n.t('users_actions.notfications.change_roles_error_body');

    notify.error(
      i18n.t('users_actions.notfications.error_title'),
      errMsg
    );
  }).finally(() => {
    dispatch(getUsersWithRoles(spaceId));
  });
};

export const removeUsersFromSpace = ({ spaceId, userIds }) => (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  Promise.all(userIds.map(async (userId) => {
    await axios.delete(
      `${__env.BPM_API_URL}spaces/${spaceId}/${userId}`,
      {
        __silentFor: 'all',
      }
    );
  })).then(() => {
    dispatch(getOwnRoles(true));
  }).then(() => {
    notify.success(
      i18n.t('users_actions.notfications.success_title'),
      i18n.t('users_actions.notfications.remove_users_success_body')
    );
  }).catch((res) => {
    const errMsg = res.response.data?.message
      ? backendTranslation(res.response.data.message)
      : i18n.t('users_actions.notfications.remove_users_error_body');

    notify.error(
      i18n.t('users_actions.notfications.error_title'),
      errMsg
    );
  }).finally(() => {
    dispatch(getUsersWithRoles(spaceId));
  });
};

export const selectUser = (userId) => ({
  type: SELECT_USER,
  payload: userId
});

export const unselectUser = (userId) => ({
  type: UNSELECT_USER,
  payload: userId
});

export const selectAllUsers = () => ({
  type: SELECT_ALL_USERS
});

export const unselectAllUsers = () => ({
  type: UNSELECT_ALL_USERS
});