import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Dropdown } from 'reactstrap';

import {
  getContractBilling,
  getContractEvents,
} from '../../actions/contractActions';
// import Loading from '../../../main/components/loading';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
// import ContractBilling from '../../components/ContractBilling';
// import ContractEvents from '../../components/ContractEvents';
import ContractDetails from '../../components/ContractDetails';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import useContractState from '../../hooks/useContractState';
import Affiliations from '../Affiliations';
import { canAccess } from '../../utils/rolesUtils';
import { UserRole } from '../../constants/roles';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import { __env } from '../../../envloader';
import { ContractDropdownButton, ContractDropdownMenu, ContractDropdownItem } from '../../styles/styled-components/SpaceContract';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/common/download.svg';
import ChevronDownIcon from '../../../assets/svg/common/chevron-down.svg';
import Breadcrumbs  from './Breadcrumbs';
import { openFile } from '../../utils/downloadFilesFromIBIS';

const SpaceContract = ({ match }) => {

  const [ contractsDropdown, setContractsDropdown ] = useState(false);
  const [ disabledFilesList, setDisabledFilesList ] = useState(true);
  const [ listOfFiles, setListOfFiles ] = useState();

  const spaceId = match.params.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { space, roles } = useCurrentSpace(match);
  const {
    contractDetails,
    // contractBilling,
    // contractBillingLoading,
    // contractEvents,
    // contractEventsLoading
  } = useContractState();
  const contractId = contractDetails?.id;

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('contract') }
  ]), [ space?.name, space?.id, t ]);

  useEffect(() => {
    if (canAccess([ UserRole.OWNER ], roles)) {
      if (contractDetails) {
        dispatch(getContractBilling());
        dispatch(getContractEvents());
      }
    }
  }, [ dispatch, contractDetails, roles ]);

  const toggleDropdown = () => setContractsDropdown(!contractsDropdown);

  // const renderContractEvents = () => {
  //   if (!contractEvents || contractEvents.matching_count === 0) {
  //     return <p>{t('space_contract.no_changes_in_contract')}</p>;
  //   }
  //   else {
  //     return <ContractEvents events={contractEvents} />;
  //   }
  // };

  // const renderContractBilling = () => {
  //   if (canAccess([ UserRole.OWNER, UserRole.FINANCIAL_ADMIN ], roles))
  //     if (!contractBilling) {
  //       return <p>{t('space_contract.no_contract_settlements')}</p>;
  //     }
  //     else {
  //       return <ContractBilling billing={contractBilling} />;
  //     }
  // };

  useEffect(() => {
    const link = `${__env.IBIS_TENANT_URL}spaces/${spaceId}/contracts/`;
    axiosInstance.get(link).then(({ data: { items } }) => {
      if (items.length > 0) {
        setListOfFiles(items.map(item => ({ link: (link + item.name), lng: item.name.slice(-6, -4) })));
        setDisabledFilesList(false);
      }
    });
  }, [ spaceId ]);

  const renderAffilations = () => {
    if (canAccess([ UserRole.OWNER, UserRole.FINANCIAL_ADMIN ], roles)) {
      return (
        <>
          <div className='py-4 mt-3'>
            <h4 className='font-lg fontWeight-medium'>{t('space_contract.affiliations')}</h4>
            <p>
              <Trans i18nKey='space_contract.affiliations_description'>
                Lista poświadczeń przypisanych do danej przestrzeni. &nbsp;
                <b>Przedłuż/wygaś</b> dane poświadczenie.
              </Trans>
            </p>
          </div>
          <div>
            <Affiliations
              contractAffiliations
              contractId={contractId}
              affiliationsNotFoundMessage={t('space_contract.empty_list_of_affiliations')}
              hiddenAddNew={true}
            />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations}/>
      <Helmet>
        {space && <title>{t('page_titles.spaces.contract', { space: space.name })}</title>}
      </Helmet>
      <section className='mt-3'>
        <div className="d-flex justify-content-end">
          <Dropdown isOpen={contractsDropdown} toggle={toggleDropdown} disbaled={disabledFilesList.toString()}>
            <ContractDropdownButton>
              <DownloadIcon stroke={contractsDropdown ? "#0D59C7" : "#585858"} />
              <span>{t('space_contract.download')}</span>
              <img src={ChevronDownIcon} alt="Chevron down" />
            </ContractDropdownButton>
            { listOfFiles &&
              <ContractDropdownMenu>
                {
                  listOfFiles?.map((contract => (
                    <ContractDropdownItem key={`contract-${contract.lng}`}>
                      <a 
                        href="/" 
                        onClick={(e) => { e.preventDefault(); openFile(contract.link); }} 
                        rel="noreferrer"
                      >
                        {t(`space_contract.download_options.${contract.lng}`)}
                      </a>
                    </ContractDropdownItem>
                  )))
                }
              </ContractDropdownMenu>
            }
          </Dropdown>
        </div>
        <ContractDetails spaceId={spaceId}/>
        {/* <div className='mt-5'>
          <h4 className='font-lg fontWeight-medium'>
            {t('space_contract.financial_settlements')}
          </h4>
          {contractBillingLoading ? (
            <Loading className='text-center' />
          ) : (
            renderContractBilling()
          )}
        </div> */}
        <div className='mt-4 pt-4'>
          {/* <div className='d-flex justify-content-between align-items-center'>
            <h4 className='font-lg fontWeight-medium'>{t('space_contract.change_history')}</h4>
          </div>
          {contractEventsLoading ? (
            <Loading className='text-center' />
          ) : (
            renderContractEvents()
          )} */}
        </div>
        {renderAffilations()}
      </section>
    </>
  );
};

SpaceContract.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t('space_contract.space_contract'))
)(SpaceContract);
