import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

import SpaceStatistics from './SpaceStatistics';
import SpaceContract from './SpaceContract';
import SpaceInvitations from '../invitations/SpaceInvitations';
import SpaceUsers from './SpaceUsers';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import ProtectedRoute from '../routing/ProtectedRoute';
import { UserRole } from '../../constants/roles';
import Services from './Services';
import Offers from './Offers';
import OfferDetails from '../../components/OfferDetails';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import { Container } from '../../styles/styled-components/GlobalStyles';
import OfferProcessForm from '../../components/OfferProcessForm';
import ServiceDetails from '../../components/ServiceDetails';
import ServiceDetailsHistory from '../../components/ServiceDetailsHistory';
import SpacePermissions from './SpacePermissions';
import SpaceSettlements from './SpaceSettlements';
import SettlementsDetails from './SettlementsDetails';
import { getServices } from '../../actions/servicesActions';
import Regimens from './Regimens';

const SpaceContainer = () => {
  const match = useRouteMatch('/spaces/:id/');
  const spaceId = match.params.id;
  const dispatch = useDispatch();

  const spaceListLoading = useSelector((state) => state.spaces.spaceList.get('isLoading'));
  const { space } = useCurrentSpace(match);

  useEffect(() => {
    dispatch(getServices(spaceId));
  }, [ dispatch, spaceId ]);

  if (!spaceListLoading && !space) {
    return <Redirect to={{
      pathname: '/',
      state: { fromSpaceNotFound: true, spaceId }
    }} />;
  }

  return (
    <> 
      <Container>
        <Switch>
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/' 
            component={SpaceStatistics} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/services/' 
            component={Services} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/services/:service_id' 
            component={ServiceDetails} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/services/:service_id/history/:resource' 
            component={ServiceDetailsHistory} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/offers' 
            component={Offers} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/offers/:offer_id' 
            component={OfferDetails} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/regimens' 
            component={Regimens} 
          />
          <ProtectedRoute
            exact
            path='/spaces/:id/invitations'
            allowedRoles={[ UserRole.USER_ADMIN ]}
            component={SpaceInvitations}
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/offers/purchase_form/:purchase_process_id' 
            component={OfferProcessForm} 
          />
          <ProtectedRoute 
            allowedRoles={[ UserRole.EVERYONE ]}
            exact 
            path='/spaces/:id/users' 
            component={SpaceUsers} 
          />
          <ProtectedRoute
            exact
            path='/spaces/:id/settlements'
            allowedRoles={[ UserRole.FINANCIAL_ADMIN, UserRole.OWNER ]}
            component={SpaceSettlements}
          />
          <ProtectedRoute
            exact
            path='/spaces/:id/settlements/:serviceId/:settlementId'
            allowedRoles={[ UserRole.FINANCIAL_ADMIN, UserRole.OWNER ]}
            component={SettlementsDetails}
          />
          <ProtectedRoute
            exact
            path='/spaces/:id/permissions'
            allowedRoles={[ UserRole.END_USER ]}
            forbiddenRoles={[ UserRole.FINANCIAL_ADMIN, UserRole.USER_ADMIN, UserRole.OWNER ]}
            component={SpacePermissions}
          />
          <ProtectedRoute
            exact 
            path='/spaces/:id/contract' 
            allowedRoles={[ UserRole.FINANCIAL_ADMIN, UserRole.OWNER ]} 
            component={SpaceContract} 
          />
        </Switch>
      </Container>
    </>
  );
};

export default compose(
  ErrorBoundary((props) => props.t('space_container'))
)(SpaceContainer);
