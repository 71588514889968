import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavItem, NavLink, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { CSSTransition } from 'react-transition-group';

import { checkIfLoginIsPossible, getProfileRedirect } from '../utils/authUtils';
import { logIn, logOut } from '../../oidc-middleware/actions/login';
import Loading from '../components/loading';
import UserAvatar from '../../users-ui/components/UserAvatar';
import Portal from '../../users-ui/components/Portal';
import UserProfilePopover from '../../users-ui/containers/UserProfilePopover';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  }),
  dispatch => {
    return bindActionCreators({
      logIn: logIn,
      logOut: logOut,
    }, dispatch);
  }
)
export default class Login extends Component {
  state = {
    profileRedirect: null,
    profilePopoverOpen: false,
    profilePopoverEntered: false,
    coords: {},
    dropdownOpen: false,
  };
  _isMounted= false;

  constructor(props) {
    super(props);
    this.btnRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (checkIfLoginIsPossible())
      getProfileRedirect().then(value => {
        if (this._isMounted)
          this.setState({ profileRedirect: value });
      });
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  getCoords = () => {
    const rect = this.btnRef.current.getBoundingClientRect();
    return {
      right: window.innerWidth - rect.right - 5,
      top: rect.y + rect.height + 30
    };
  };

  onUpdateCoords = () => {
    const { right, top } = this.getCoords();
    this.setState({
      ...this.state,
      coords: { top, right }
    });
  };

  handlePopoverOpen = () => {
    const { right, top } = this.getCoords();
    this.setState({
      ...this.state,
      profilePopoverOpen: true,
      coords: { top, right }
    });
  };

  togglePopoverEntered = () => {
    this.setState({
      ...this.state,
      profilePopoverEntered: !this.state.profilePopoverEntered
    });
  };

  onCloseProfilePopover = () => {
    this.setState({
      ...this.state,
      profilePopoverOpen: false
    });
  };

  renderLoginBar() {
    const { t } = this.props;
    const throttledLogin = _.throttle(this.props.logIn, 1000);

    if (!checkIfLoginIsPossible()) {
      //Login is impossible
      return null;
    }

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading />;
    }

    if (!this.props.loginStore) {
      //user is not logged in
      return (
        <NavItem>
          <NavLink
            id='login'
            href="#"
            onClick={() => throttledLogin()}
            tag={Button}
            className="px-4"
          >
            {t('common:login')}
          </NavLink>
        </NavItem>
      );
    }
    //user is logged in
    return (
      <>
        <UserAvatar 
          ref={this.btnRef}
          onClick={this.handlePopoverOpen} 
          username={this.props.loginStore.get('name')} 
          width='2.25rem' 
          height='2.25rem' 
        />
        <Portal>
          <CSSTransition
            in={this.state.profilePopoverOpen}
            timeout={200}
            classNames='filters-popover'
            onEntered={this.togglePopoverEntered}
            onExited={this.togglePopoverEntered}
            mountOnEnter
            unmountOnExit
          >
            <UserProfilePopover 
              coords={this.state.coords}
              entered={this.state.profilePopoverEntered}
              profileRedirect={this.state.profileRedirect}
              username={this.props.loginStore.get('name')}
              email={this.props.loginStore.get('email')}
              onClose={this.onCloseProfilePopover}
              onLogout={this.props.logOut}
              onUpdateCoords={this.onUpdateCoords}
            />
          </CSSTransition>
        </Portal>
      </>
    );
  }

  render() {
    return (
      this.renderLoginBar()
    );
  }
}

Login.propTypes = {
  logIn: PropTypes.func, //HOC
  logOut: PropTypes.func, //HOC
  location: PropTypes.object,
  t: PropTypes.func, //HOC,
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
