import styled from 'styled-components';
import { Alert } from 'reactstrap';

const getBorderColor = (theme, color) => {
  switch (color) {
  case "red":
    return "#FFA7A0";
  case "blue":
    return "#A7C5FD";
  case "grey":
    return "#B0B0B0";
  default:
    return theme.colors.text.greyDivider;
  }
};

const getBackgroundColor = (theme, colorType, highlighted) => {
  switch (colorType) {
  case "red":
    return "#FFEDED";
  case "blue":
    return "#EDF5FF";
  case "grey":
    return "#F8F8F8";
  default: 
    if (highlighted) {
      return theme.colors.background.blue; 
    }
    else {
      return theme.colors.background.white;
    };
  }
};


export const InfoBoxAlert = styled(Alert)`
  border-color: ${(props) => getBorderColor(props.theme, props.color)};
  background-color: ${(props) => getBackgroundColor(props.theme, props.color, props.highlighted)};
`;

export const InfoText = styled.p`
  color: ${({ theme, color }) => color === "grey" ? "#434343" : theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin: 0;
`;
