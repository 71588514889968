import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import backendTranslation from '../../utils/backendTranslation';
import offerIcon from '../../../assets/svg/offers/offer-1.svg';
import RaportDetails from './RaportDetails';
import Badge from '../../components/settlements/Badge';
import { Label } from '../../styles/styled-components/SettlementDetails';
import useAxios from '../../hooks/useAxios';
import { __env } from '../../../envloader';
import Breadcrumbs  from './Breadcrumbs';

const SettlementsDetails = ({ match }) => {
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);

  const { isLoading, data } = useAxios(
    `
      ${__env.SERVICE_DATA_API_URL}api/billings/${match.params.settlementId}
    `
  );
  const spaceLocation= `/spaces/${space?.id}`;

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: spaceLocation },
    { title: t('nav_items.settlements'), location: `${spaceLocation}/settlements` },
    { title: t('nav_items.settlements_details') }
  ]), [ space?.name, spaceLocation, t ]);
  const settlement = data?.item;

  const silent = useMemo(
    () => ({
      __silentFor: [ { status: 404 } ],
    }),
    []
  );

  const { isLoading: isLoadingBilling, data: billing } = useAxios(
    `${__env.BPM_API_URL}payment/${settlement?.id}/`,
    {
      sendOnInit: false,
      otherOptions: silent,
    }
  );

  if (!space || isLoading || isLoadingBilling) {
    return <Loading />;
  }

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Helmet>
        <title>
          {t("page_titles.spaces.settlements", { space: space.name })}
        </title>
      </Helmet>
      <Card className="p-4 mb-3 mt-5">
        <Row className="m-0 mb-5">
          <Col sm={1} className="p-0 m-0">
            <img src={offerIcon} alt="offer icon w-100" />
          </Col>
          <Col>
            <h1>{backendTranslation(settlement?.name)}</h1>
          </Col>
          <Col className="p-0 m-0">
            <div className="float-right">
              <Label>{t("settlements_details.status")}</Label>
              <b>
                <Badge
                  state={settlement.state}
                  billingStatus={billing?.status}
                />
              </b>
            </div>
          </Col>
        </Row>
        <div>
          <Label>{t("settlements_details.for_period")}:</Label>
          <div className="float-right font-sm fontWeight-medium">
            {settlement?.creation_date} {t("to")} {settlement?.due_date}
          </div>
        </div>
        <hr />
        <div>
          <Label>{t("settlements_details.created_date")}:</Label>
          <div className="float-right font-sm fontWeight-medium">
            {settlement?.creation_date}
          </div>
        </div>
        <hr />
        <div>
          <Label>{t("settlements_details.due_date")}:</Label>
          <div className="float-right font-sm fontWeight-medium">
            {settlement?.due_date}
          </div>
        </div>
      </Card>
      <RaportDetails report={billing?.report} />
    </>
  );
};

SettlementsDetails.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(SettlementsDetails);
