import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import { useHistory, useLocation } from 'react-router';

import { mapFiltersToParams, paramsAreEmpty } from '../../utils/users';
import { InitialFilters, FilterValue, Roles } from '../../constants/usersFilters';
import RadioButton from '../../components/RadioButton';
import { 
  Popover, 
  Buttons,
  PopoverContent, 
  HeaderLabel,
  RoleLabel,
} from '../../styles/styled-components/UsersFiltersPopover';
import usePopover from '../../hooks/usePopover';
import { BtnTextGrey, BtnTextBlue } from '../../styles/styled-components/Button';

const FiltersPopover = ({ onClose, coords, updatePopoverCoords, entered }) => {
  const [ filters, setFilters ] = useState(InitialFilters);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const popoverRef = useRef();

  usePopover({
    ref: popoverRef,
    onClose,
    entered,
    onUpdateCoords: updatePopoverCoords
  });

  const handleChange = (e) => {
    const role = e.target.name;
    const value = e.target.value;

    setFilters(prev => ({
      ...prev,
      [role]: value
    }));
  };  

  const handleApplyFilters = () => {
    const filterParams = mapFiltersToParams(filters); 
    const params = new URLSearchParams(location.search);
    Object.keys(InitialFilters).forEach(role => params.delete(role));
    history.push(`${location.pathname}?${filterParams}${!paramsAreEmpty(params) ? `&${params.toString()}` : ''}`);
    onClose();
  };

  const handleClearFilters = () => setFilters(InitialFilters);

  return (
    <Popover 
      ref={popoverRef} 
      className='shadow' 
      top={coords.top} 
      left={coords.left} 
      data-testid='filters-popover'
    >
      <PopoverContent>
        <Container fluid className='px-0'>
          <Row className='pb-3 no-gutters'>
            <Col xs={4}>
              <HeaderLabel>{t('users_filters_popover.role')}</HeaderLabel>
            </Col>
            <Col className='text-center'>
              <HeaderLabel>{t('users_filters_popover.have')}</HeaderLabel>
            </Col>
            <Col className='text-center'>
              <HeaderLabel>{t('users_filters_popover.dont_have')}</HeaderLabel>
            </Col>
            <Col className='text-center'>
              <HeaderLabel>{t('users_filters_popover.any')}</HeaderLabel>
            </Col>
          </Row>
          {Roles.map((name) => (
            <Row key={name} className='pb-3 no-gutters'>
              <Col xs={4}>
                <RoleLabel color={name === "user_admin" ? 1 : 0} >{t(`roles.${name}`)}</RoleLabel>
              </Col>
              <Col>
                <div className='d-flex justify-content-center'>
                  <label className='m-0'>
                    <RadioButton 
                      value={FilterValue.YES}
                      name={name}
                      checked={filters[name] === FilterValue.YES}
                      onChange={handleChange}
                      data-testid={`${name}-${FilterValue.YES}`}
                    />
                  </label>
                </div>
              </Col>
              <Col>
                <div className='d-flex justify-content-center'>
                  <label className='m-0'>
                    <RadioButton 
                      value={FilterValue.NO}
                      name={name}
                      checked={filters[name] === FilterValue.NO}
                      onChange={handleChange}
                      data-testid={`${name}-${FilterValue.NO}`}
                    />
                  </label>
                </div>
              </Col>
              <Col>
                <div className='d-flex justify-content-center'>
                  <label className='m-0'>
                    <RadioButton 
                      value={FilterValue.ANY}
                      name={name}
                      checked={filters[name] === FilterValue.ANY}
                      onChange={handleChange}
                      data-testid={`${name}-${FilterValue.ANY}`}
                    />
                  </label>
                </div>
              </Col>
            </Row>
          ))}
        </Container>
        <hr className='w-100' />
        <div className='d-flex align-items-center justify-content-between'>
          <BtnTextGrey 
            onClick={handleClearFilters}
            $fweight="500" 
          >
            {t('users_filters_popover.clear_filters_button')}
          </BtnTextGrey>
          <Buttons>
            <BtnTextGrey 
              onClick={onClose} 
              $fweight="500" 
            >
              {t('users_filters_popover.cancel_button')}
            </BtnTextGrey>
            <BtnTextBlue 
              onClick={handleApplyFilters} 
              $fweight="500" 
            >
              {t('users_filters_popover.apply_button')}
            </BtnTextBlue>
          </Buttons>
        </div>
      </PopoverContent>
    </Popover>
  );
};

FiltersPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  coords: PropTypes.object.isRequired,
  updatePopoverCoords: PropTypes.func.isRequired,
  entered: PropTypes.bool.isRequired
};

export default FiltersPopover;
