import styled from 'styled-components';

export const Popover = styled.div`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  width: 500px;
  height: 250px;
  background-color: ${({ theme }) => theme.colors.background.white};
  z-index: 1000;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const PopoverContent = styled.div`
  padding: 15px 0 0 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 27px;
  padding-right: 15px;
`;

export const HeaderLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text.grey3};
`;

export const RoleLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ color, theme }) => color ? theme.colors.secondary : theme.colors.text.darkGrey};
`;
