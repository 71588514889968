import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

const theme = {
  colors: {
    primary: '#0FA1FF',
    blueActive: '#0096F6',
    secondary: '#106BEF',
    link: '#0078D4',
    linkHover: '#006FC4',
    secondaryHover: '#0D59C7',
    secondaryActive: '#0049B1',
    secondaryDisabled: '#777676',
    grey: '#676767',
    lineGrey: '#B0B0B0',
    lightGrey: '#E0E0E0',
    error: '#BA001B', 
    white: "#fff",
    orangeMore: '#F29201',
    background: { 
      white: '#FFFFFF',
      lightGrey: '#F8F8F8',
      greyHover: '#7776761A',
      greyActive: '#77767626',
      blue: '#EDF5FF',
    },
    text: {
      black: '#000000',
      blackFocus: '#121212',
      darkGrey: '#333',
      lessDarkGrey: '#585858',
      grey0: '#5E5E5E',
      grey1: '#676767',
      grey1_5: '#777676',
      grey2: '#808080',
      grey3: '#979797',
      grey4: '#B7B7B7',
      grey5: '#D3D3D3',
      grey6: '#F2F2F2',
      greyHover: '#434343',
      greyDivider: '#DCD6D6',
      veryDarkGray: '#4F4F4F',
      greyTitle: '#848484',
      blue: '#030303',
      blueMore: '#006FC4',
      blueMost: '#0265B0',
      blueLink: `#0069a6`  
    },
    alerts: {
      green: '#42033',
      red: '#D20724',
      redHover: '#C8001D',
      orange: '#FFA216',
      blue: '#0FA1FF'
    },
    notifications: {
      stroke: '#FFA7A0',
      fill: '#FFEDED',
    },
    status: {
      green: '#529F37'
    },
    points: {
      blue0: '#0FA1FF',
      blue1: '#106BEF',
      blue2: '#0D59C7',
      blue3: '#0049B1',
      blue4: '#0067a4'
    },
    button: {
      blue: "#0fa1ff",
      darkBlue: "#006FC4",
      darkGrey: "#4f4f4f",
      danger: "#D20724",
      disabled: "#979797"
    }
  },
  fontSizes: {
    xxxs: '0.438rem',// 7px
    xxs: '0.625rem', //10px
    xs: '0.75rem',// 12px
    sm: '0.875rem', // 14px
    sm_md: '0.938rem', //15px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xl1: '1.375rem', // 22px
    xl2: '1.5rem', // 24px
    xl3: '1.875rem',
    xl4: '2rem',
    xl5: '2.5rem' // 40px
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.any.isRequired
};

export default Theme;