import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

let portalRoot = document.getElementById('portal-root');

// Creating `portalRoot` manually for testing enviroment
if (!portalRoot) {
  portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', 'portal-root');
  document.body.appendChild(portalRoot);
}

const Portal = ({ children }) => {
  const el = useMemo(() => document.createElement('div'), []);
  
  useEffect(() => {
    portalRoot.appendChild(el);
    
    return () => portalRoot.removeChild(el);
  }, [ el ]);
  
  return createPortal(children, el);
};

export default Portal;