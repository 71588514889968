import React from 'react';
import Select, { createFilter } from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#0fa1ff'
  }
});


const SelectWidget = (props) => {
  const { options, id, label, onChange, disabled, placeholder, required, schema } = props;
  const { t } = useTranslation();
  const isInvalid = props.rawErrors && props.rawErrors.length > 0;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '20px',
      ...(isInvalid && { borderColor: '#dc3545' })
    })
  };

  return (
    <div>
      <label htmlFor={id} className='mb-2'>
        {label}
        {required && " *"}
      </label>
      <Select 
        inputId={id}
        options={options.enumOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        onChange={({ value }) => onChange(value)}
        defaultValue={options?.enumOptions?.find(option => option.value === schema?.default)}
        noOptionsMessage={() => t('select_widget.no_options_message')}
        filterOption={createFilter({ ignoreAccents: false })}
        theme={theme}
        styles={styles}
      />
    </div>
  );
};

SelectWidget.propTypes = {
  options: PropTypes.shape({
    enumOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    }))
  }),
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  uiSchema: PropTypes.object,
  placeholder: PropTypes.string,
  rawErrors: PropTypes.array,
  schema: PropTypes.object
};

export default SelectWidget;