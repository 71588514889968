import { useSelector } from 'react-redux';

import { selectSpaceById, selectRolesBySpaceId } from '../selectors';

const useCurrentSpace = (match) => {
  const spaceId = match.params.id;
  const space = useSelector((state) => selectSpaceById(state, spaceId));
  const roles = useSelector((state) => selectRolesBySpaceId(state, spaceId));
  
  return {
    space,
    roles
  };
};

export default useCurrentSpace;