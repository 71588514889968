import styled from 'styled-components';
import { Card, Nav } from 'reactstrap';

export const StyledNav = styled(Nav)`
  .nav-item {
    flex: 1;  
  }

  .nav-link {
    font-size: ${({ theme }) => theme.fontSizes.md};
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.grey4};
    cursor: pointer;
    transition: border-bottom .1s ease-out;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.text.black};
  }

  .nav-link.active {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border-bottom: 2px solid ${({ theme }) => theme.colors.blueActive};
  }
`;

export const CardWarning = styled(Card)`
  border: 1px solid #DCD6D6;
  background-color: #FFEDED;
`;
