import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Table } from 'reactstrap';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import backendTranslation from '../utils/backendTranslation';

import { AngleDown, Button, TableTh, TextBold, TextBlue, TextGrey, UserRow, UserImage, UserContainer } from '../styles/styled-components/ServiceHistoryTable';
import circleWarningIcon from '../../assets/svg/common/circle-warning.svg';
import { MomentLocale } from '../utils/componentsLocal';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { formatBasedOnValue } from '../utils/formatBasedOnValue';

const ServiceHistoryTable = ({ data, type, showChart }) => {
  const { t } = useTranslation();

  const [ chartData, setChartData ] = useState([]);

  const [ showUsers, setShowUsers ] = useState([]);

  const reversedData = data !== null && [ ...data?.items ].reverse();
  const unit = backendTranslation(data?.items[0]?.resource_usage_data?.resources?.find(ele => ele?.name === type)?.unit);

  useEffect(() => {
    if (data !== null) {
      // Set users
      setShowUsers(Array.from({ length: data.items }, () => 0));

      // Set chart
      let tempFullData = [];

      for (let i = 0; i < data.items.length; i++) {
        const subperiods = data.items[i].resource_usage_data.subperiods;
        if (subperiods !== undefined) {
          let tempPeriodData = [];
          for (let j = 0; j < subperiods.length; j++) {
            const periodDate = new Date(subperiods[j].name);
            const resource = subperiods[j].resources.find(resource => resource.name === type);
            tempPeriodData.push({
              name: periodDate.getDate().toString(),
              [unit]: Math.floor(resource?.value).toString()
            });
          }
          tempFullData.push(tempPeriodData);
        }
        
      }
      setChartData(tempFullData.reverse());
    }
  }, [ data, type, unit ]);

  const toggleRow = idx => {
    let tempArray = [ ...showUsers ];
    tempArray[idx] = !showUsers[idx] ? 1 : 0;
    setShowUsers(tempArray);
  };

  return (
    <Table className="mt-4">
      <thead>
        <tr>
          <TableTh>{t('services_details.billing_period')}</TableTh>
          <TableTh>{t('services_details.resource_consumption')}</TableTh>
          <TableTh>{t('services_details.consumption')}</TableTh>
          <TableTh></TableTh>
        </tr>
      </thead>
      <tbody>
        {
          reversedData[0] && reversedData.map((item, idx) => {
            const elementData = item.resource_usage_data.resources.find(ele => ele.name === type);
            const isDetails = !!(elementData?.details && elementData?.details[0]);
            return (
              <React.Fragment key={item.id}>
                <tr>
                  <td>
                    <TextBold>{backendTranslation(item?.period_name)}</TextBold> 
                    (<MomentLocale format="DD.MM.YYYY" date={item.date_from} /> - <MomentLocale format="DD.MM.YYYY" date={item.date_to} />)
                  </td>
                  <td>
                    {formatBasedOnValue(elementData?.value)}
                    {' '}
                    {backendTranslation(elementData?.unit)}
                  </td>
                  <td>{elementData?.limit ?
                    `${formatBasedOnValue(elementData?.value / elementData?.limit * 100)}%`
                    :
                    t('services_details.unlimited')
                  }</td>
                  <td>
                    {
                      isDetails &&
                        <Button type="button" onClick={() => toggleRow(idx)}>
                          <AngleDown reverse={showUsers[idx]} />
                        </Button>
                    }
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} style={{ padding: 0 }}>
                    { isDetails &&
                      <Collapse isOpen={!!showUsers[idx]}>
                        { (chartData && showChart) &&
                            <ResponsiveContainer width={'99%'} height={200} className="py-4">
                              <BarChart
                                data={chartData[idx]}
                                margin={{
                                  top: 5,
                                  right: 0,
                                  left: 15,
                                  bottom: 5
                                }}
                              >
                                <CartesianGrid vertical={false} />
                                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                                <YAxis 
                                  axisLine={false} 
                                  tickLine={false} 
                                  unit={backendTranslation(elementData?.unit)}
                                />
                                <Tooltip />
                                <Bar dataKey={backendTranslation(elementData?.unit)} fill="#B3D5FF" radius={[ 3, 3, 0, 0 ]} />
                              </BarChart>
                            </ResponsiveContainer>
                        }
                        <div className="d-flex align-items-center pt-4 pb-2 px-4">
                          {
                            Math.round(elementData?.value / elementData?.limit * 100) >= 95 &&
                              <img className="mr-2" src={circleWarningIcon} alt="" />
                          }
                          <div>
                            <div className="d-flex">
                              <p className="mr-1 mb-0">{t('services_details.user_list')}</p>
                              <TextBlue>
                                {elementData?.value ? Math.round(elementData?.value / elementData?.limit * 100) : 0}%
                                {' '}
                                {t('services_details.resources')}
                              </TextBlue>
                            </div>
                            <TextGrey>{t('services_details.resources_users')}</TextGrey>
                          </div>
                        </div>
                        {
                          isDetails && elementData.details.map(ele => (
                            <UserRow key={ele?.name}>
                              <UserContainer>
                                <UserImage />
                                <p className="mb-0 ml-2">{ele?.name}</p>
                              </UserContainer>
                              <p className="mb-0">
                                {Math.floor(ele?.value)}
                                {' '}
                                {backendTranslation(elementData?.unit)}
                                {' '}
                                ({((ele?.value / elementData?.limit) * 100)?.toFixed(2)}%)
                              </p>
                            </UserRow>
                          ))
                        }
                      </Collapse>
                    }
                  </td>
                </tr>
              </React.Fragment>
            );
          })
        }
      </tbody>
    </Table>
  );
};

ServiceHistoryTable.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  showChart: PropTypes.bool
};

export default compose(
  ErrorBoundary((props) => props.t('error_boundary_components.ServiceHistoryTable'))
)(ServiceHistoryTable);
