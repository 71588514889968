import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;

  > :last-child {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: 700;
    cursor: auto;
  }
`;