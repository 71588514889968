import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../styles/styled-components/GlobalStyles';
import { Tooltip } from 'reactstrap';
import ConditionalWrapper from './ConditionalWrapper';
import Avatar from '@mui/material/Avatar';
import { stringToColor } from '../utils/avatars';

const UserAvatar = React.forwardRef(({ user, username, width, height, fontSize = '1rem', fontWeight, onClick, spaceID, number, disabledTooltip }, ref) => {
  const { t } = useTranslation(); 
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  return (
    <>
      <ConditionalWrapper
        condition={spaceID && user}
        wrapper={children => 
          <StyledLink to={`spaces/${spaceID}/users`} $unstyled>
            {children}
          </StyledLink>
        }
      >
        <Avatar 
          id={ user && spaceID && 
          `tooltip-${spaceID}_${user.id}_user`}
          ref={ref}
          onClick={onClick}
          title={ !number && !disabledTooltip ? t('user_avatar.see_user_details') : "" }
          sx={{ 
            bgcolor: number ? "#106bef" : () => stringToColor(username),
            width: { width },
            height: { height },
            fontWeight: { fontWeight },
            fontSize: { fontSize },
            cursor: disabledTooltip ? "default" : "pointer",
            color: "white" }}
        >
          {
            number ?
              (user?.user_name ?? username)
              : (user?.user_name ?? username).charAt(0).toUpperCase()
          }
        </Avatar>
      </ConditionalWrapper>
     
      { user && spaceID &&
        <Tooltip
          placement='top-start' 
          isOpen={tooltipOpen} 
          target={`tooltip-${spaceID}_${user.id}_user`} 
          toggle={() => setTooltipOpen(prev => !prev)}
        >
          {user.first_name} {user.surname}
        </Tooltip>
      }
    </>
  );
});

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
  }),
  username: PropTypes.string,
  spaceID: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  number: PropTypes.bool,
  onClick: PropTypes.func,
  disabledTooltip: PropTypes.bool
};

export default UserAvatar;