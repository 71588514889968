import styled from 'styled-components';
import { InputDateLocal } from '../../utils/componentsLocal';
import Select from 'react-select';

export const SelectYear = styled(Select)`
  width: 100%;
`;

export const InputDate = styled(InputDateLocal)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.text.grey1_5};
  padding: .3rem 1rem;
`;

export const StraightLine = styled.div`
  height: 1.5rem;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.lineGrey};
  margin: 0 1rem;
`;