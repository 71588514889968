import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AccessibilityContainer, CustomLink, Main, MainTitle, StyledHr, Title } from '../styles/styled-components/Accessibility';

const Accessibility = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t('accessibility.accessibility')} - PRACELAB`;
  }, [ t ]);

  return (
    <AccessibilityContainer>
      <MainTitle id="a11y-deklaracja">{t('accessibility.accessibility')}</MainTitle>  
      <StyledHr />
      <Main>
        <p id="a11y-wstep" style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.accessibility_text" values={{ organization: "Poznańskie Centrum Superkomputerowo-Sieciowe" }}>
            <p/>
            <span id="a11y-podmiot"/>
            <p/>
            <CustomLink id="a11y-url" href="https://pracelab.paas.psnc.pl/">{3}</CustomLink>
            <p/>
          </Trans>
        </p>
        &nbsp;
        <p style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.accessibility_date1">
            <p/>
            <span id="a11y-data-publikacja"/>
          </Trans>
        </p>
        <p style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.accessibility_date2">
            <p/>
            <span id="a11y-data-aktualizacja"/>
          </Trans>
        </p>

        <Title>{t('accessibility.accessibility_status')}</Title>
        <p id="a11y-status" style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.accessibility_status_text">
            <p/>
            <strong/>
            <p/>
          </Trans>
        </p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>{t('accessibility.accessibility_status_point1')}</li>
          <li>{t('accessibility.accessibility_status_point2')}</li>
          <li>{t('accessibility.accessibility_status_point3')}</li>
          <li>{t('accessibility.accessibility_status_point4')}</li>
          <li>{t('accessibility.accessibility_status_point5')}</li>
        </ul>

        <Title>{t('accessibility.accessibility_statement')}</Title>
        <p style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.accessibility_statement_date">
            <p/>
            <span id="a11y-data-sporzadzenie"/>
          </Trans>
        </p>
        <p>{t('accessibility.accessibility_statement_text')}</p>

        <Title>{t('accessibility.keyboard_shortcuts')}</Title>
        <p>{t('accessibility.keyboard_shortcuts_text')}</p>
        
        <Title id="a11y-kontakt">{t('accessibility.feedback')}</Title>
        <p style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.feedback_text1">
            <p/> 
            <strong/> 
            <p/> 
            <strong><span/></strong> 
            <p/> 
            <CustomLink id="a11y-email" href="mailto:office@man.poznan.pl">{5}</CustomLink>
            <p/>  
            <span id="a11y-telefon"/>
            <p/> 
          </Trans>
        </p>
        <div id="a11y-procedura">
          <p>{t('accessibility.feedback_text2')}</p>
          <p>{t('accessibility.feedback_text3')}</p>
          <p>{t('accessibility.feedback_text4')}</p>
          <p style={{ display: "inline" }}>
            <Trans i18nKey="accessibility.feedback_text5">
              <p/>
              <CustomLink href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich">Rzecznika Praw Obywatelskich</CustomLink>
              <p/>
            </Trans>
          </p>
        </div>
        <Title id="a11y-architektura">{t('accessibility.architectural_accessibility')}</Title>
        <p style={{ display: "inline" }}>
          <Trans i18nKey="accessibility.architectural_accessibility_text">
            <p/>
            <CustomLink href="https://www.pcss.pl/deklaracja-dostepnosci-architektonicznej/" title="Deklaracja dostępności architektonicznej">
              {1}
            </CustomLink>
          </Trans>
        </p>
      </Main>
    </AccessibilityContainer>
  );
};
export default Accessibility;