import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ImAttachment } from 'react-icons/im';
import PropTypes from 'prop-types';
import { Button, Collapse, Tooltip, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Linkify from 'linkify-react';

import backendTranslation from '../utils/backendTranslation';
import { ChevronIcon } from '../styles/styled-components/Accordion';
import { BasicButton, FlexCenter } from '../styles/styled-components/GlobalStyles';
import { 
  AttachmentBadge,
  NotificationRow, 
  BlueCircle, 
  CheckIcon, 
  NotificationStatus, 
  NotificationDate,
  NotficationTitle,
  NotficationSender,
  CheckboxCol,
  LogoCol,
  TextCol,
  ButtonCol,
  ChevronCol,
  LinkButton
} from '../styles/styled-components/Notifications';
import Checkbox from '../components/Checkbox';
import pracelabIcon from '../../assets/svg/notifactions/pracelab.svg';
import NotificationStateValues from '../constants/notifications';
import { notificationType } from '../types/';
import IconButton from './IconButton';
import { openFile } from './../utils/downloadFilesFromIBIS';

const Notification = ({ 
  notification, 
  checked, 
  onSelectionChage, 
  isOpen, 
  onCollapseToggle,
  onDelete 
}) => {
  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ modalRedirect, setModalRedirect ] = useState("#");
  const { t } = useTranslation();
  const date = new Date(notification.created);

  const linkifyLink = ({ attributes, content }) => {
    const domainName =  window.location.hostname;
    const { href, ...props } = attributes;
    // Link without protocols
    const clearLink = href.replace("https://", "").replace("http://", "");
    // Domain from link
    const linkDomain = clearLink?.split("/")[0];
    // Link for "Link" component
    const readyRedirect = domainName === linkDomain ? clearLink.replace(domainName, "") : "#";
    // If link redirects outside the domain
    if (domainName !== linkDomain) {
      return (
        <LinkButton onClick={() => {setIsModalOpen(true); setModalRedirect(href);}}>{content}</LinkButton>
      );
    }
    else {
      return (
        <Link to={readyRedirect} {...props}>{content}</Link>
      );
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <li>
      <Modal isOpen={isModalOpen} toggle={toggleModal} size='md'>
        <ModalBody>
          <p className="p-2 m-0">{t('notifications.link_outside')}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline className="mr-auto" onClick={toggleModal}>
            {t('notifications.cancel')}
          </Button>
          <Button href={modalRedirect} tag="a" target='_blank' rel="noreferrer" onClick={toggleModal}>
            {t('notifications.go')}
          </Button>
        </ModalFooter>
      </Modal>
      <NotificationRow 
        className='align-items-center border-top border-bottom m-0 p-4'
        status={checked ? 'CHECKED' : notification.state}
      >
        <CheckboxCol>
          <label htmlFor={`notification-${notification.id}`}>
            <Checkbox 
              checked={checked}
              id={`notification-${notification.id}`}
              name={`notification-${notification.id}`}
              onChange={e => onSelectionChage(e, notification.id)}
              outline
            />
          </label>
        </CheckboxCol>
        <LogoCol>
          <img src={pracelabIcon} alt='pracelab logo' />
        </LogoCol>
        <TextCol onClick={() => onCollapseToggle(notification.id, notification.state)}>
          <NotficationTitle id={`tooltip-${notification.id}`}>{backendTranslation(notification.content.subject)}</NotficationTitle>
          <NotficationSender id={`tooltip-${notification.id}`}>{notification.sender}</NotficationSender>
          <NotificationDate>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</NotificationDate>
          <Tooltip 
            placement='top-start' 
            isOpen={tooltipOpen} 
            target={`tooltip-${notification.id}`} 
            toggle={() => setTooltipOpen(prev => !prev)}
          >
            {notification.sender}
          </Tooltip>
        </TextCol>
        <ButtonCol>
          <FlexCenter>
            {notification.state === NotificationStateValues.READ && 
            <>
              <CheckIcon className='mr-2' />
              <NotificationStatus>{t('notifications.read')}</NotificationStatus>
            </>}
            {notification.state === NotificationStateValues.UNREAD && 
            <>
              <BlueCircle size='sm' className='mr-2' />
              <NotificationStatus>{t('notifications.unread')}</NotificationStatus>
            </>}
          </FlexCenter>
        </ButtonCol>
        <ButtonCol>
          <FlexCenter>
            <IconButton 
              onClick={() => onDelete(notification.id)} 
              icon='trash'
              className='mr-2'
              withBorder={false}>
              {t('notifications.delete_notification')}
            </IconButton>
          </FlexCenter>
        </ButtonCol>
        <ChevronCol>
          <FlexCenter>
            <BasicButton onClick={() => onCollapseToggle(notification.id, notification.state)}>
              <ChevronIcon open={isOpen} color='grey' />
            </BasicButton>
          </FlexCenter>
        </ChevronCol>
      </NotificationRow>
      <Collapse isOpen={isOpen}>
        <div className='py-3 px-5 border-bottom'>
          <p>
            <Linkify options={{ render: linkifyLink }}>{backendTranslation(notification.content.message)}</Linkify>
          </p>
          {notification.content && notification.content.attachments && notification.content.attachments.map(attachment => 
            <a 
              href="/" 
              onClick={(e) => { e.preventDefault(); openFile(backendTranslation(attachment)); }} 
              key={uuidv4()}
              rel="noreferrer"
            >
              <AttachmentBadge pill>
                <ImAttachment size={15} />
                {'  '}
                {backendTranslation(attachment)?.split('/').pop()}
              </AttachmentBadge>  
            </a>
          )}
        </div>
      </Collapse>
    </li>
  );
};

Notification.propTypes = {
  notification: notificationType.isRequired,
  checked: PropTypes.bool.isRequired,
  onSelectionChage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCollapseToggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Notification;