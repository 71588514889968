import styled from 'styled-components';
import { Nav } from 'reactstrap';

export const FixedSidebar = styled.aside`
  position: sticky;
  top: 5rem;
  z-index: 100;
  height: calc(100vh - 5rem);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 5px;
  border-right: solid 6px ${({ theme }) => theme.colors.white};
  border-bottom: solid 20px ${({ theme }) => theme.colors.white};

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 5rem;
  }
`;

export const InnerNav = styled(Nav)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const BlueCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const Spacename = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text.darkGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 17px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
