import styled from 'styled-components';
import { Card } from 'reactstrap';

export const UsersCard = styled(Card)`
  position: relative;
`;

export const UsersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const Th = styled.th`
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.text.grey3};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const UsersCount = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SelectAllLabel = styled.label`
  color: ${({ theme }) => theme.colors.text.darkGrey};
  margin-bottom: 0;
`;
