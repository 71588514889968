import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useCurrentSpace from '../hooks/useCurrentSpace';
import { __env } from '../../envloader';
import axios from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import { canAccess } from '../utils/rolesUtils';
import { UserRole } from '../constants/roles';

import Loading from '../../main/components/loading';
import backendTranslation from '../utils/backendTranslation';
import FavoriteButton from './FavoriteButton';
import { ActiveButton, CardOffer, Info, OfferName, Title, Text } from '../styles/styled-components/Offer';
import InfoBox from './InfoBox';
import ExclamationIcon from '../../assets/svg/permissions/exclamation.svg';
import { offerPriceList, offerIcon, offerInfo } from '../../assets/svg/offers/icons';
import useAxios from '../hooks/useAxios';
import { ClampedTitle } from '../styles/styled-components/ClampedTextField';
import SanitizedHTML from './SanitizedHTML';
import { openFile } from '../utils/downloadFilesFromIBIS';
import Breadcrumbs  from '../containers/spaces/Breadcrumbs';


const OfferDetails = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { space, roles } = useCurrentSpace(match);
  const history = useHistory();
  
  const offer_id = match.params.offer_id;
  const space_id = match.params.id;
  const { data: getData } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${space_id}/user_offers_current`);

  const [ offerData, setOfferData ] = useState(null);
  const [ hasError, setHasError ] = useState(false);

  const icons = getData?.items.map(el =>  [ el.offer__name, el.offer__tile_icon || offerIcon ]);
  const icon = icons?.find( el => el[0].pl === offerData?.offer__name.pl )?.[1];
  const spaceLocation = `/spaces/${space?.id}`;

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: spaceLocation },
    { title: t('nav_items.offers'), location: `${spaceLocation}/offers` },
    { title: backendTranslation(offerData?.offer__name) }
  ]), [ space?.name, spaceLocation, t, offerData?.offer__name ]);


  useEffect(() => {
    axios.get(`${__env.SERVICE_DATA_API_URL}api/user_offers_current/${offer_id}`, 
      {
        __silentFor: { status: "all" }
      }
    ).then(({ data: { item } }) => {
      setOfferData(item);
    }).catch(function (error) {
      setHasError(true);
      notify.error("", t('offers.notifications.error_offer_details'));
    }); 
  }, [ offer_id, t ]);

  const onOfferChoose = offerId => () => {
    axios.post(
      __env.BPM_API_URL + 'offers',
      { offerId, spaceId: space.id },
    )
      .then(response => {
        notify.success(
          t('offers.notifications.process_initiated_success_title'),
          t('offers.notifications.process_initiated_success_body')
        );
        history.push('/spaces/' + space.id + '/offers/purchase_form/' + response.data.processName);
      });
  };

  if (hasError) {
    return null;
  }

  if (offerData && space) {
    const short_description = backendTranslation(offerData.short_description);
    const price_list_description = backendTranslation(offerData.price_list_description);
    const description = backendTranslation(offerData.description);
    return (
      <>
        <Breadcrumbs  dataLocations={dataLocations} />
        {!canAccess([ UserRole.FINANCIAL_ADMIN ], roles) && 
        <InfoBox iconPath={ExclamationIcon} iconAlt="bad_role" color="blue">
          <Trans i18nKey="offers.info_no_permission_for_activate_offer" />
        </InfoBox>
        }
        <CardOffer className='mt-5'>
          <div className='d-flex m-2'>
            <div className='mr-2'>
              <img src={icon} height="78px" width="78px" alt='offer icon' />
            </div>
            <OfferName>
              <ClampedTitle className='font-xl fontWeight-bold'>{backendTranslation(offerData.offer__name)}</ClampedTitle>
              <div className='d-flex'>
                <img src={offerInfo} alt='star' />
                <Info>{t('recommended_offers')}</Info>
              </div>
            </OfferName>
            <div className='d-flex justify-content-end align-items-start'>
              <FavoriteButton 
                spaceId={space?.id} 
                id={offer_id?.toString()} 
                type="offers"
              />
            </div>
          </div>
          {short_description &&
            <>
              <hr />
              <div className='my-2'>
                <Title>{t('offers.title_short_description')}</Title>
                <Text>{short_description}</Text>
              </div>
            </>
          }
          {price_list_description &&
            <>
              <hr />
              <div className='my-2'>
                <Title>
                  <img src={offerPriceList} alt='icons price'/>
                  {t('price_list')}
                </Title>
                <Text>
                  <SanitizedHTML html={price_list_description} />
                </Text>
              </div>
            </>
          }
          {description &&
            <>
              <hr />
              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  <div className='flex-grow-1'>
                    <Title>{t('offers.title_description')}</Title>
                    <Text className='m-0'>
                      <SanitizedHTML html={description} />
                    </Text>
                  </div>
                  { offerData?.regulations?.items?.length > 0 &&
                    <>
                      <div className='flex-grow-1'>
                        <hr />
                        <Title>{offerData?.regulations?.items?.length > 1 ? t('nav_items.regimens') : t('regimens')}</Title>
                        { offerData.regulations.items.map(item => 
                          <div className='d-flex align-items-center'>
                            <Text sm>
                              {backendTranslation(item?.description)}
                              <a 
                                href="/"
                                onClick={(e) => { e.preventDefault(); openFile(item?.url); }}
                                className='pl-1 text-decoration-none text-align-center'
                                rel="noreferrer"
                              > 
                                {t('see_more')}
                              </a>
                            </Text>
                          </div>
                        )}
                      </div>
                    </>
                  }
                </div>
              </div>
            </>
          }
          <ActiveButton 
            className="align-self-end"
            onClick={onOfferChoose(offer_id)}
            disabled={!canAccess([ UserRole.FINANCIAL_ADMIN ], roles)}>
            {t('offers.activate')}
          </ActiveButton>
        </CardOffer>
      </>
    );
  } 
  else {
    return <Loading />;
  }
};


OfferDetails.propTypes = {
  html: PropTypes.string,
  match: PropTypes.object.isRequired
};

export default OfferDetails;