import styled from 'styled-components';
import { Progress } from 'reactstrap';
import { FaAngleDown } from 'react-icons/fa';

export const ProgressBar = styled(Progress)`
  border-radius: 20px;
  height: 9px;
  width: 95%;
`;

export const Button = styled.button`
  border: none;
  background-color: white;
`;

export const AngleDown = styled(FaAngleDown)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  transition: transform .5s ease;
  transform: ${(props) => props.reverse ? 'rotate(180deg)' : 'rotate(0)'};
`;

export const TextUsage = styled.p`
  margin-top: .5rem;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.text.grey1_5};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const TextBold = styled.p`
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TextBlueBold = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.link};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TextLink = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`; 

export const ProgressPoint = styled.div`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: ${({ theme, color }) =>
    color === 0
      ? theme.colors.points.blue0
      : color === 1
        ? theme.colors.points.blue1
        : color === 2
          ? theme.colors.points.blue2
          : theme.colors.points.blue3};
`;