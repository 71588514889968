import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import Loading from '../../main/components/loading';
import { __env } from '../../envloader';
import useAxios from '../hooks/useAxios';
import { AffiliationsHeader } from '../styles/styled-components/Affiliations';
import AffiliationsInProgressTable from '../components/AffiliationsInProgressTable';

const AffiliationsInProgress = () => {
  const { 
    data, 
    isLoading, 
    error 
  } = useAxios(`${__env.BPM_API_URL}affiliations`);
  const { t } = useTranslation();

  return (
    <Card className="mt-4">
      <div className='p-4'>
        <AffiliationsHeader as='h1'>{t('affiliations_in_progress.header')}</AffiliationsHeader>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        error ? (
          <div className='py-2'>
            <Alert color='danger'>{t('affiliations_in_progress.api_error')}</Alert>
          </div>
        ) : (
          data.length === 0 ? (
            <div className='px-4 mb-3'>{t('affiliations_in_progress.empty_list')}</div>
          ) : (
            <AffiliationsInProgressTable affiliations={data.map(item => ({
              id: item.processName,
              started: item.startedAt,
              type: item.affiliationCategory,
            }))} />
          )
        )
      )}
    </Card>

  );
};

export default AffiliationsInProgress;
