import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ModalFooter,
  Form,
  FormGroup,
  Label,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from '../../../main/components/loading';
import axios from '../../../main/utils/axios/axiosInstance';
import {
  StyledModal,
  StyledBody,
  StyledInput,
  StyledButton,
  StyledRequired,
  StyledAdminReason,
  StyledAdminComment,
} from '../../styles/styled-components/ReportModal';
import AddFile from './AddFile';
import { __env } from '../../../envloader';
import { SquareButton } from '../../styles/styled-components/Button';
import { ReactComponent as PlusIcon } from '../../../assets/svg/common/plus-3.svg';
import { ReactComponent as AttachIcon } from '../../../assets/svg/common/attach.svg';
import { useDispatch } from 'react-redux';
import { getServices } from '../../actions/servicesActions';
import FileDeleteModal from './FileDeleteModal';

const states = {
  edit: "edit",
  confirm: "confirm",
  feedback: "feedback",
};

const RaportModal = ({
  feedback,
  modal,
  toggle,
  serviceId,
  initialData,
  refetchIbis,
  billingId,
}) => {
  const DOIRef = useRef(null);

  // const clearAndToggle = () => {
  //   setSynopsis(initialData?.summary?.text ?? "");
  //   setDOI("");
  //   setPublications(
  //     initialData?.publications
  //       ? initialData?.publications.map((publication) => ({
  //         file_reference: publication.file_reference,
  //         doi: publication.doi,
  //         name:
  //             publication.name ??
  //             publication.file_reference?.split("/").slice(-1) ??
  //             "",
  //       }))
  //       : []
  //   );
  //   setSynopsisFile(
  //     initialData?.summary?.file_reference
  //       ? {
  //         file_reference: initialData?.summary?.file_reference,
  //         name:
  //             initialData.summary?.name ??
  //             initialData.summary?.file_reference?.split("/").slice(-1) ??
  //             "",
  //       }
  //       : null
  //   );
  //   setPublicationFile(null);
  //   toggle();
  // };

  const match = useRouteMatch({
    path: "/spaces/:id",
  });
  const spaceId = match.params.id;

  const [ state, setState ] = useState(feedback ? states.feedback : states.edit);
  const [ synopsis, setSynopsis ] = useState(initialData?.summary?.text ?? "");
  const [ loading, setLoading ] = useState(false);
  const [ disableModal, setDisableModal ] = useState(false);
  const [ DOI, setDOI ] = useState("");
  const [ publications, setPublications ] = useState(
    initialData?.publications
      ? initialData?.publications.map((publication) => ({
        file_reference: publication.file_reference,
        doi: publication.doi,
        name:
            publication.name ??
            publication.file_reference?.split("/").slice(-1) ??
            "",
      }))
      : []
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ publicationFile, setPublicationFile ] = useState(null);
  const [ synopsisFile, setSynopsisFile ] = useState(
    initialData?.summary?.file_reference
      ? {
        file_reference: initialData?.summary?.file_reference,
        name:
            initialData.summary?.name ??
            initialData.summary?.file_reference?.split("/").slice(-1) ??
            "",
      }
      : null
  );

  const onSubmit = (e) => {
    const publications = addPublication();
    e.preventDefault();
    if (state === states.edit) return setState(states.confirm);

    setLoading(true);
    axios({
      method: feedback ? "put" : "post",
      url: `${__env.BPM_API_URL}payment/${billingId}`,
      data: {
        summary: {
          text: synopsis,
          file_reference: synopsisFile?.file_reference ?? null,
        },
        publications: publications.map((publication) => ({
          file_reference: publication?.file_reference ?? null,
          doi: publication?.doi ?? null,
        })),
      },
    }).finally(() => {
      setLoading(false);
      toggle();
      refetchIbis();
      dispatch(getServices(spaceId));
    });
  };

  const onSave = () => {
    const publications = addPublication();
    if (state === states.confirm) return setState(states.edit);
    if (state === states.feedback) {
      toggle();
      return;
    }
    setLoading(true);
    axios({
      method: initialData ? "put" : "post",
      url: `${__env.IBIS_TENANT_URL}spaces/${match.params.id}/services/${serviceId}/billing_reports/billing_report_${billingId}.json`,
      data: {
        summary: {
          text: synopsis,
          file_reference: synopsisFile?.file_reference,
          name: synopsisFile?.name,
        },
        publications: publications,
      },
    }).finally(() => {
      setLoading(false);
      toggle();
      refetchIbis();
    });
  };

  const addPublication = () => {
    if (!DOI && !publicationFile) return publications;
    const newPublications = [
      ...publications,
      {
        doi: DOI,
        file_reference: publicationFile?.file_reference ?? null,
        name: publicationFile?.name ?? null,
      },
    ];
    setPublications(newPublications);
    setDOI("");
    setPublicationFile(null);
    return newPublications;
  };

  const deletePublication = (index) => {
    setPublications(publications.filter((_, i) => i !== index));
  };

  const areRequiredFieldsFilled = synopsis && synopsis.length > 0;

  return (
    <StyledModal isOpen={modal} size="xs">
      <ModalHeader className="font-lg fontWeight-medium">
        {t("reports.add_report")}
      </ModalHeader>
      <StyledBody>
        <Form id="report-form" onSubmit={onSubmit}>
          <FormGroup>
            {state === states.feedback && (
              <>
                <p className="font-md fontWeight-medium text-muted m-0">
                  {t("reports.report_status")}
                </p>
                <hr className="my-1 mb-2" />
                <StyledAdminReason>{feedback.refusal_reason}</StyledAdminReason>
                {feedback.refusal_comment && (
                  <>
                    <Label className="font-sm fontWeight-medium py-2">
                      {t("reports.admin_comment")}
                    </Label>
                    <StyledAdminComment className="pb-2 font-sm">
                      {feedback.refusal_comment}
                    </StyledAdminComment>
                  </>
                )}
              </>
            )}
            {state === states.confirm && (
              <div>
                <p className="font-sm fontWeight-medium">
                  {t("reports.confirmation_header")}
                </p>
                <p className="font-sm fontWeight-medium">
                  {t("reports.confirmation_text")}
                </p>
              </div>
            )}
            <p className="font-md fontWeight-medium text-muted m-0">
              {t("reports.report")}
            </p>
            <hr className="my-1 mb-2" />
            <Label className="font-sm fontWeight-medium">
              {t("reports.synopsis")} <StyledRequired>*</StyledRequired>
            </Label>
            <StyledInput
              value={synopsis}
              onChange={(e) => setSynopsis(e.target.value)}
              type="textarea"
              rows="5"
              placeholder={t("reports.synopsis_placeholder")}
              disabled={state === states.confirm}
            />
            <div className="mt-2 d-flex">
              <AddFile
                confirm={state === states.confirm}
                file={synopsisFile}
                url={
                  __env.IBIS_TENANT_URL +
                  "spaces/" +
                  match.params.id +
                  "/services/" +
                  serviceId +
                  `/billing_reports/${billingId}_synopsis`
                }
                setDisableModal={setDisableModal}
                setFile={setSynopsisFile}
              />
              <div></div>
            </div>
          </FormGroup>
          <FormGroup>
            <p className="font-md fontWeight-medium text-muted m-0">
              {t("reports.publications")}
            </p>
            <hr className="my-1" />
            {state !== states.confirm && (
              <>
                <Label className="font-sm fontWeight-medium">
                  {t("reports.doi")}
                </Label>
                <Row>
                  <Col>
                    <StyledInput
                      ref={DOIRef}
                      value={DOI}
                      onChange={(e) => setDOI(e.target.value)}
                    />
                  </Col>
                  <Col
                    className="p-0 pr-3 d-flex justify-content-between"
                    sm={5}
                  >
                    <span className="align-self-center text-truncate">
                      <AddFile
                        url={
                          __env.IBIS_TENANT_URL +
                          "spaces/" +
                          match.params.id +
                          "/services/" +
                          serviceId +
                          `/billing_reports/${billingId}_publications`
                        }
                        file={publicationFile}
                        setDisableModal={setDisableModal}
                        setFile={setPublicationFile}
                      />
                    </span>
                    <SquareButton
                      onClick={() => {
                        addPublication();
                        DOIRef.current.focus();
                      }}
                      className={"p-2 ml-2 float-right"}
                      disabled={!DOI && !publicationFile}
                    >
                      <PlusIcon />
                    </SquareButton>
                  </Col>
                </Row>
              </>
            )}
            {publications.map((publication, index) => (
              <Row key={index} className="mt-2 pt-2">
                {publication.doi && (
                  <Col className="align-self-center" sm={6}>
                    <ins className="m-0 font-sm fontWeight-light text-truncate">
                      {publication.doi}
                    </ins>
                  </Col>
                )}
                <Col className="align-self-center" sm={5}>
                  {publication.file_reference && (
                    <p className="m-0 fontWeight-medium font-sm text-truncate">
                      <AttachIcon className="mr-2" />
                      {publication.name}
                    </p>
                  )}
                </Col>
                {!publication.doi && (
                  <Col className="align-self-center" sm={6}></Col>
                )}
                {state !== states.confirm && (
                  <Col sm={1}>
                    {
                      <span className={"float-right"}>
                        <FileDeleteModal
                          removeFile={() => deletePublication(index)}
                        />
                      </span>
                    }
                  </Col>
                )}
              </Row>
            ))}
          </FormGroup>
        </Form>
      </StyledBody>
      {loading ? (
        <ModalFooter>
          <Loading />
        </ModalFooter>
      ) : (
        <ModalFooter>
          {synopsis ||
          synopsisFile ||
          DOI.length > 0 ||
          publicationFile ||
          publications.length > 0 ? (
              <StyledButton
                color="secondary"
                outline
                onClick={onSave}
                disabled={disableModal}
              >
                {state === states.confirm
                  ? t("reports.go_back_and_edit")
                  : state === states.feedback
                    ? t("reports.close")
                    : t("reports.save_and_close")}
              </StyledButton>
            ) : (
              <StyledButton color="secondary" outline onClick={toggle}>
                {t("reports.cancel")}
              </StyledButton>
            )}
          <StyledButton
            form="report-form"
            type="submit"
            color="primary"
            disabled={disableModal || !areRequiredFieldsFilled}
          >
            {t("reports.send")}
          </StyledButton>
        </ModalFooter>
      )}
    </StyledModal>
  );
};

RaportModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  serviceId: PropTypes.string,
  refetchIbis: PropTypes.func,
  initialData: PropTypes.shape({
    summary: PropTypes.shape({
      text: PropTypes.string,
      file_reference: PropTypes.string,
      name: PropTypes.string,
    }),
    publications: PropTypes.arrayOf(
      PropTypes.shape({
        doi: PropTypes.string,
        file_reference: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  feedback: PropTypes.shape({
    refusal_comment: PropTypes.string,
    refusal_reason: PropTypes.string,
  }),
  billingId: PropTypes.number,
};

export default RaportModal;
