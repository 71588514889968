import styled, { css } from 'styled-components';

export const Title = styled.div`
  ${({ theme }) => `   
    font-size: ${theme.fontSizes.xl};
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const StyledProcessForm = styled.div`
 ${props => props.finalStepError && css`
   .text-muted {
     color: red !important;

     :after {
       content: ' !';
       color: red;
     }
   }
 `}
`;

export const LastStep = styled.div`
    width: 70%;
    max-width: 800px;
`; 

export const LastStepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: #DCD6D6 1px solid;
  background-color: ${props => props.theme.colors.background.white};
  border-radius: 20px;
  padding: 2rem 22%;
  margin-bottom: 2rem;
  text-align: center;
`;

export const LastStepTitle = styled.p`
  font-size: ${props => props.theme.fontSizes.md};
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.text.lessDarkGrey};
  margin-top: 2rem;
`;

export const LastStepTitleText = styled.span`
  color: ${( { procesError, theme: { colors } }) => procesError ? colors.error : colors.secondary};
`;

export const LastStepTitleSubText = styled.p`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.text.grey1_5};
  margin-top: .5rem;
`;

export const LastStepImage = styled.img`
   width: 100%;
   max-width: 400px;
   margin: 0 auto;
   margin-bottom: .5rem;
`;