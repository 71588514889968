import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ComboBox, Menu, MenuItem, MenuItemBody, SearchInput, HiglightedText } from '../styles/styled-components/SearchUsers';
import { useLocation } from 'react-router';

const UsersComboBox = ({ onInputChange, onFocus, onBlur }) => {
  const [ inputItems, setInputItems ] = useState([]);
  const { t } = useTranslation();
  const users = useSelector((state) => state.users.get('allUsers').get('data').items);
  const location = useLocation();
  const activeSearch = location.search ? new URLSearchParams(location.search).get('search') || '' : '';

  useEffect(() => {
    if (users.length > 0) {
      setInputItems(() => users.map((u) => u.user_name));
    }
  }, [ users ]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    inputValue,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      onInputChange(inputValue);
      setInputItems(
        inputValue
          ? users.filter((u) => u.user_name.toLowerCase().startsWith(inputValue.toLowerCase())).map((u) => u.user_name)
          : users.map((u) => u.user_name)
      );
    },
    initialInputValue: activeSearch
  }); 
  
  return (
    <>
      <label {...getLabelProps()} className='sr-only'>{t('find_user')}</label>
      <ComboBox {...getComboboxProps()}>
        <SearchInput 
          {...getInputProps()} 
          data-testid='search-user-input' 
          placeholder={t('find_user')} 
          onFocus={onFocus} 
          onBlur={onBlur} 
        />
      </ComboBox>
      <Menu {...getMenuProps()}>
        {isOpen && (
          inputItems.map((item, index) => (
            <MenuItem
              higlighted={highlightedIndex === index }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              <MenuItemBody>
                <HiglightedText>{item.slice(0, inputValue.length)}</HiglightedText>{item.slice(inputValue.length)}
              </MenuItemBody>
            </MenuItem>
          ))
        )} 
      </Menu>
    </>
  );
};

UsersComboBox.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default UsersComboBox;
