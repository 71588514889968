import React from 'react';

import { LoadingParent, LoadingBox, LoadingImg } from '../styles/styled-components/LoadingFullscreen';
import loadingState0 from '../../assets/svg/common/loading/state0.svg';
import loadingState1 from '../../assets/svg/common/loading/state1.svg';
import loadingState2 from '../../assets/svg/common/loading/state2.svg';
import loadingState3 from '../../assets/svg/common/loading/state3.svg';

const LoadingFullscreen = () => (
  <LoadingParent>
    <LoadingBox>
      <LoadingImg src={loadingState0} alt="loading image of state 0"/>            
      <LoadingImg src={loadingState1} alt="loading image of state 1"/>            
      <LoadingImg src={loadingState2} alt="loading image of state 2"/>            
      <LoadingImg src={loadingState3} alt="loading image of state 3"/>            
    </LoadingBox>
  </LoadingParent>
);

export default LoadingFullscreen;