import axios from '../../main/utils/axios/axiosInstance';

const namespace = '@USEAXIOS/';
export const LOADING = namespace + 'LOADING';
export const SUCCESS = namespace + 'SUCCESS';
export const ERROR = namespace + 'ERROR';

export const sendRequest = (path, axiosConfig) => async (dispatch) => {
  dispatch({ type: LOADING, path: path });
  axios(axiosConfig)
    .then((res) => {
      dispatch({ type: SUCCESS, path: path, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ERROR, path: path, payload: err.response ? err.response.data : err.message });
    });
};
