import { useEffect, useMemo, useReducer, useRef } from 'react';

import axios from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

export const useSpaceBillings = (
  services = [],
) => {
  const initialState = {
    isLoading: false,
    error: null,
    data: [],
  };

  const cancel = useRef(false);

  const [ state, dispatch ] = useReducer((state, action) => {
    switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true };
    case 'SUCCESS':
      return { ...state, isLoading: false, data: action.payload };
    case 'ERROR':
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
    }
  }, initialState);

  const fetchSpaceBillings = useMemo(() => async () => {
    cancel.current = false;
    dispatch({ type: 'LOADING' });
    Promise.all(services.map(({ id, name }) =>
      axios({
        url: `${__env.SERVICE_DATA_API_URL}api/services/${id}/billings/`,
      }).then((res) => Promise.all(res.data.items.map(async (v) => {
        let billing = null;
        try {
          billing = (await axios({ url: `${__env.BPM_API_URL}payment/${v.id}/`, __silentFor: [ { status: 404 } ] })).data;
        }
        catch (err) {
          console.error(err);
        }

        return {
          ...v,
          serviceId: id,
          serviceName: name,
          billing: billing
        };
      }))
      ))).then((values) => {
      if (!cancel.current)
        dispatch({
          type: 'SUCCESS', payload: values.flat(1)
        });
    })
      .catch((err) => {
        if (!cancel.current)
          dispatch({ type: 'ERROR', payload: err.response ? err.response.data : err.message });
      });
  }, [ services ]);

  useEffect(() => {
    if (services && services.length > 0)
      fetchSpaceBillings();

    return () => {
      cancel.current = true;
    };
  }, [ services, fetchSpaceBillings ]);

  return {
    ...state, refetch: () => {

    }
  };
};

export default useSpaceBillings;
