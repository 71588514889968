import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StyledLink, FolderProgressIcon } from '../styles/styled-components/GlobalStyles';
import { StyledCard, StyledHr } from '../styles/styled-components/ContractInProgress';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import backendTranslation from '../utils/backendTranslation';
import IconProgress from './IconProgress';
import { ProgressContainer } from '../styles/styled-components/TileCard';
import axios from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

const ContractInProgress = ({ contract }) => {
  const { contractDescription, processName, contractCategory, step_name, current_step, total_steps } = contract;
  const { t } = useTranslation();

  const spaceImage = useSelector(state => state.spaces.spaceImg.get('data'));
  const destinationUrl = '/process_form/' + processName + '/';
  const [ title, setTitle ] = useState(null);
  
  const getImgIcon = (type) => {
    return spaceImage.find(e => e.reference_id === type)?.folder_icon;
  };

  useEffect(() => {
    if (contractCategory) {
      axios.get(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories/?reference_id=${contractCategory}`)
        .then(response => {
          setTitle(response?.data?.items?.[0]?.title);
        });
    }
  }, [ contractCategory ]);

  return (
    <li className='mb-4' data-testid='contract-in-progress'>
      <StyledCard>
        <Row className='justify-content-md-between align-items-end pl-2'>
          <Col>
            <StyledLink to={destinationUrl} $unstyled>
              <div className='d-flex'>
                <ProgressContainer style={{ padding: "0 16px 0 0" }}>
                  <IconProgress 
                    icon={getImgIcon(contractCategory)}
                    progress={ 100 * current_step / total_steps }  
                    sizeImg={26}
                    sizeProgress={42}
                  />
                </ProgressContainer>
                <div>
                  <p className='font-xs m-0'>{backendTranslation(title)}</p>
                  <h5 className='fontWeight-bold font-md m-0'>{backendTranslation(contractDescription)}</h5>
                </div>
              </div>
            </StyledLink>
          </Col>
          <Col>
            <div className='d-flex justify-content-end align-items-center'>
              <h5 className='font-md'>Status: <span className="font-weight-normal">{t('contract_running')}</span><FolderProgressIcon/></h5>
            </div>
          </Col>
        </Row>
        <StyledHr />
        <Row className='justify-content-md-between align-items-end'>
          <Col md='6'>
            <p className='font-md m-0'>{backendTranslation(step_name)}</p>
          </Col>
          <Col md='2' className='text-right'>
            <Link to={destinationUrl} className='font-sm'>
              {t('show_more')} {'>'}
            </Link>
          </Col>
        </Row>
      </StyledCard>
    </li>
  );
};

ContractInProgress.propTypes = {
  contract: PropTypes.shape({
    processName: PropTypes.string.isRequired,
    contractCategory: PropTypes.string.isRequired,
    startedAt: PropTypes.string.isRequired,
    waitingForUserAction: PropTypes.bool.isRequired,
    step_name: PropTypes.object.isRequired,
    current_step: PropTypes.number.isRequired,
    total_steps: PropTypes.number.isRequired,
    contractDescription: PropTypes.object.isRequired,
  }).isRequired
};

export default compose(
  ErrorBoundary((props) => props.t('contract_in_progress.error_message'))
)(ContractInProgress);
