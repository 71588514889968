import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Steps, Circle, NumberLabel, ArrowIcon } from '../styles/styled-components/Stepper';

const Stepper = ({ currentStep, totalSteps }) => {
  return (
    <Steps>
      {[ ...Array(totalSteps) ].map((_, stepNum) => (
        <Fragment key={stepNum + 1}>
          <Circle type={stepNum + 1 <= currentStep ? 'active' : ''}>
            <div className='d-flex align-items-center justify-content-center w-100 h-100'>
              <NumberLabel>{stepNum + 1}</NumberLabel>
            </div>
          </Circle>
          {stepNum + 1 !== totalSteps && <ArrowIcon type={stepNum + 1 < currentStep ? 'active' : ''} />}
        </Fragment>
      ))}
    </Steps>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

export default Stepper;