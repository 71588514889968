import styled, { keyframes } from 'styled-components';

export const LoadingParent = styled.div`
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(109, 109, 109, 0.7);
    z-index: 10000;
`;

export const LoadingBox = styled.div`
    max-width: 100px;
    max-height: 100px;
    position: relative;
    left: 50%;
    top: 40%;
`;

const rotateImg = keyframes`
  0% {
    opacity: 1;
    transform: rotateX(360deg);
  }

  50% {
    opacity: 0.5;
    transform: rotateX(360deg);
  }

  100% {
    display: none;
    opacity: 0;
    transform: rotateX(360deg);
  }
`;

export const LoadingImg = styled.img`
    animation: ${rotateImg} 800ms infinite;
    position: absolute;
    top: 0;
    left: 0;
    animation-delay: 0ms;

    &:nth-child(1) {
        animation-delay: 100ms;
    }

    &:nth-child(2) {
        animation-delay: 300ms;
    }

    &:nth-child(3) {
        animation-delay: 500ms;
    }

    &:nth-child(4) {
        animation-delay: 70ms;
    }
`;