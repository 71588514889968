import styled from 'styled-components';
import { Input, Modal, ModalBody } from 'reactstrap';

export const StyledModal = styled(Modal)`
  width: 500px;
  height: 474px;
`;

export const StyledBody = styled(ModalBody)`
  background: #f8f8f8;
  border: 1px solid #dcd6d6;
`;

export const StyledInput = styled(Input)`
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.24);
  border-radius: 6px;
  &:focus {
    border-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.text.blackFocus};
    outline: none;
    box-shadow: none;
  }
`;

export const StyledButton = styled.button`
  border: 0px;
  background: ${({ theme, color, disabled }) =>
    (disabled ? "#BBBBBB" : color === "danger" ? theme.colors.alerts.red : color === "primary" ? "#106BEF" : "#FFFFFF")};
  box-shadow: ${({ color, disabled }) =>
    disabled ? "0px" :
      color === "primary"
        ? "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB"
        : "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16)"};
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
  color: ${({ color }) => (color === "danger" ? "white" : color === "primary" ? "#FFFFFF" : "#464F60")};
`;

export const StyledIconButton = styled.button`
  border: 0px;
  background-color: transparent;
`;

export const StyledAdminReason = styled.div`
  border: 1px solid  ${props => props.theme.colors.notifications.stroke};
  background-color: ${props => props.theme.colors.notifications.fill};
  color: ${props => props.theme.colors.text.blackFocus};
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const StyledAdminComment = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const StyledRequired = styled.span`
  color: #106BEF;
`;
