import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import { Modal, ModalBody } from 'reactstrap';

export const BtnSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  width: 460px;
`;

export const CustomBody = styled(ModalBody)`
  min-height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 20px;
`;

export const EditOpen = styled(FiEdit)`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.xl2};
`;

export const ErrorMsg = styled.p(
  ({ theme }) => `
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.error};
    margin: .3rem 0;
  `
);

export const HR = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: .625rem;
  background-color: ${({ theme }) => theme.colors.text.greyDivider };
`;

export const TextEditTitle = styled.p`
  color: ${({ theme }) => theme.colors.text.greyTitle};
`;

export const SubTextEdit = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding-top: .5rem;
`;