import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, CardTitle } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { getUsersWithRoles } from '../../actions/usersActions';
import Loading from '../../../main/components/loading';
import InfoBox from '../../components/InfoBox';
import PermissionsTable from './PermissionsTable';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import UserIcon from '../../../assets/svg/permissions/user.svg';
import ExclamationIcon from '../../../assets/svg/permissions/exclamation.svg';

const SpacePermissions = ({ match }) => {
  const spaceId = match.params.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { space, roles } = useCurrentSpace(match);

  const users = useSelector((state) => state.users.get('allUsers').get('data'));
  const usersLoading = useSelector((state) => state.users.get('allUsers').get('isLoading'));
  const usersError = useSelector((state) => state.users.get('allUsers').get('error'));

  useEffect(() => {
    dispatch(getUsersWithRoles(spaceId));
  }, [ dispatch, spaceId ]);


  if (!space || !roles || !users) {
    return <Loading />;
  }

  return (
    <section>
      <Helmet>
        <title>{t('page_titles.spaces.permissions', { space: space.name })}</title>
      </Helmet>
      <InfoBox iconPath={UserIcon} iconAlt="user"><strong>{t('space_permissions.you_are_end_user')}</strong></InfoBox>
      <InfoBox iconPath={ExclamationIcon} iconAlt="exclamation" highlighted>
        <Trans i18nKey='space_permissions.end_user_permissions_info'>
          As a space <strong>End user</strong> you <strong>don't have permissions</strong>to see other end users data.
          Contact <strong>privileged</strong> user, to get information about other end users.
        </Trans>
      </InfoBox>
      <Card className="p-4">
        <CardTitle tag="h2" className="my-3">{t('space_permissions.permissions_title')}</CardTitle>
        {usersLoading &&
          <Loading />
        }
        {!usersError && <PermissionsTable/>}
      </Card>
    </section>
  );
};

SpacePermissions.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default SpacePermissions;
