import { checkLanguage } from './componentsLocal';

const backendTranslation = (field, ifEmptyValue = '') => {
  const lang = checkLanguage();

  if (field && typeof field === 'object' && field !== null) {
    return field.hasOwnProperty(lang) ? field[lang] : ifEmptyValue;
  }
  return field || ifEmptyValue;
};

export default backendTranslation;