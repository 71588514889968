import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ProcessForm from '../containers/ProcessForm';
import { __env } from '../../envloader';

const OfferProcessForm = props => {
  const { t } = useTranslation();
  return (
    <ProcessForm
      {...props}
      title={t('process_form.offer.title')}
      stepDataURL={__env.BPM_API_URL + 'offers/offer_details/' + props.match.params.purchase_process_id + '/'}
      onProcessSuccessRedirectUrl={"/spaces/" + props.match.params.id + "/services"}
      processType="offer"
    />
  );
};

OfferProcessForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default OfferProcessForm;
