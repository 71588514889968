import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../../main/utils/axios/axiosInstance';
import Loading from '../../../main/components/loading';
import { ReactComponent as AttachIcon } from '../../../assets/svg/common/attach.svg';
import FileDeleteModal from './FileDeleteModal';

const AddFile = ({ url, setFile, file, confirm, setDisableModal }) => {
  const [ loading, setLoading ] = useState(false);

  const { t } = useTranslation();

  const handleFile = (e) => {
    const file = e.target.files[0];
    e.preventDefault();
    if (file) {
      const fileNameArray = file.name.split(".");
      const extension = fileNameArray[fileNameArray.length - 1];
      const fullUrl = url + "_" + uuidv4() + "." + extension;
      let formData = new FormData();
      formData.append("content", file);
      setLoading(true);
      setDisableModal(true);
      axios({
        url: fullUrl,
        method: "POST",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then(() =>
          setFile({ file_reference: fullUrl, name: file.name })
        )
        .finally(() => {
          setLoading(false);
          setDisableModal(false);
        });
    }
  };

  return (
    <div>
      <input
        value={""}
        id={url}
        hidden
        type="file"
        name="file"
        onChange={handleFile}
        accept="application/msword, application/pdf"
      />
      <label
        htmlFor={!file && !loading ? url : undefined}
        className={`fontWeight-medium m-0 font-sm d-flex align-items-center ${
          !file ? "cursor-pointer" : ""
        }`}
      >
        {loading ? (
          <div className="ml-3 mr-3">
            <Loading tiny />
          </div>
        ) : file && !confirm ? (
          <FileDeleteModal removeFile={() => setFile(null)} />
        ) : (
          <AttachIcon className="mr-2" />
        )}
        {loading
          ? t("reports.loading")
          : file
            ? file.name
            : t("reports.add_file")}
      </label>
    </div>
  );
};

AddFile.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func,
  setDisableModal: PropTypes.func,
  url: PropTypes.string,
  confirm: PropTypes.bool,
};

export default AddFile;
