import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

// hide checkbox visually but remain accessible to screen readers.
export const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledRadioButton = styled.div`
  display: inline-block;
  width: ${({ size }) => size === 'sm' ? '22px' : '35px'};
  height: ${({ size }) => size === 'sm' ? '22px' : '35px'};
  background-color: ${({ theme }) => theme.colors.background.white};
  border: 1px solid ${({ theme }) => theme.colors.text.grey4};
  border-radius: 50%;

  ${HiddenRadioButton}:not(:disabled) + &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CircleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled.div`
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background.white};
  width: ${({ size }) => size === 'sm' ? '12px' : '22px'};
  height: ${({ size }) => size === 'sm' ? '12px' : '22px'};

  ${HiddenRadioButton}:checked + ${StyledRadioButton} > div > & {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${HiddenRadioButton}:disabled:checked + ${StyledRadioButton} > div > & {
    background-color: ${({ theme }) => theme.colors.text.grey2}
  }
`;