import { FaUserCircle } from 'react-icons/fa';
import styled from 'styled-components';

export const UserIcon = styled(FaUserCircle)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.xl4};
`;

export const RolesInfo = styled.p`
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin: 0;
`;

export const SpaceName = styled.p`
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin: 0;
`;