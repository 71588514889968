import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { formatDate } from '../utils/date';
import { Table, Th, Td } from '../styles/styled-components/Affiliations';

const AffiliationsInProgressTable = ({ affiliations }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <tr>
          <Th>{t('affiliations_in_progress_table.table_header.type')}</Th>
          <Th>{t('affiliations_in_progress_table.table_header.started')}</Th>
          <Th>{t('affiliations_in_progress_table.table_header.actions')}</Th>
        </tr>
      </thead>
      <tbody>
        {affiliations.map(item => (
          <tr key={item.id}>
            <Td><strong>{t([ `affiliation_types.${item.type}`, item.type ])}</strong></Td>
            <Td>{formatDate(item.started, 'yyyy-MM-dd HH:mm')}</Td>
            <Td>
              <Button tag={Link} to={"/affiliations/new_affiliation_form/" + item.id} color="link" className="px-0 b-0">
                {t('affiliations_in_progress_table.open')}
              </Button>
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

AffiliationsInProgressTable.propTypes = {
  affiliations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    started: PropTypes.string,
    type: PropTypes.string,
  })).isRequired
};

export default AffiliationsInProgressTable;
