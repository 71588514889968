import styled from 'styled-components';

export const InstitutionLogoImg = styled.img`
  ${({ big }) => big
    ? `
      width: 160px;
      height: 160px;
      margin-bottom: 24px;
    `
    : `
      margin-right: 0.7em;
      height: 2rem;
      margin-top: -3px;
    `
};
`;
