import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ProcessForm from '../containers/ProcessForm';
import { __env } from '../../envloader';

const AffiliationProcessForm = props => {
  const { t } = useTranslation();
  return (
    <ProcessForm
      {...props}
      title={t('process_form.affiliation.title')}
      stepDataURL={__env.BPM_API_URL + 'affiliations/affiliation_details/' + props.match.params.id + '/'}
      onProcessSuccessRedirectUrl={"/affiliations/"}
      processType="affiliation"
      refreshType={!!props.refresh}
    />
  );
};

AffiliationProcessForm.propTypes = {
  match: PropTypes.object.isRequired,
  refresh: PropTypes.bool
};

export default AffiliationProcessForm;
