import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

export const Text = styled.div(
  ({ theme }) => `
    font-family: "DM Sans";
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.fontSizes.xl2};
    padding-bottom: 1.5rem;
  `
);

export const HR = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.text.greyDivider};
`;

export const CustomCard = styled(Card)`
  > :last-child {
    padding-bottom: .75rem;
  }
`;

export const CustomCardBody = styled(CardBody)`
  padding: 2rem 2rem 0 2rem;
`;