import { useEffect, useReducer, useRef, useCallback } from 'react';

import axios from '../../main/utils/axios/axiosInstance';

export const useAxios = (
  url,
  {
    method = 'get',
    data = null,
    params = null,
    sendOnInit = true,
    sendOnChange = true,
    otherOptions= null,
  } = {}
) => {
  const initialState = {
    isLoading: sendOnInit,
    error: null,
    data: null
  };

  const cancel = useRef(false);
  const sendOnInitRef = useRef(sendOnInit);

  const fetchData = useCallback(async () => {
    cancel.current = false;
    dispatch({ type: 'LOADING' });
    axios({ url, method, data, params, ...otherOptions })
      .then((res) => {
        if (!cancel.current)
          dispatch({ type: 'SUCCESS', payload: res.data });
      })
      .catch((err) => {
        if (!cancel.current)
          dispatch({ type: 'ERROR', payload: err.response ? err.response.data : err.message });
      });
  }, [ url, method, data, params, otherOptions ]);

  const [ state, dispatch ] = useReducer((state, action) => {
    switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true };
    case 'SUCCESS':
      return { ...state, isLoading: false, data: action.payload };
    case 'ERROR':
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
    }
  }, initialState);

  useEffect(() => {
    if (sendOnChange) {
      if (sendOnInitRef.current)
        fetchData();
      else
        sendOnInitRef.current = true;
    }
    else {
      if (sendOnInitRef.current)
        fetchData();
      else
        sendOnInitRef.current = false;
    }
    return () => {
      cancel.current = true;
    };
  }, [ cancel, fetchData, sendOnChange ]);

  return { ...state, refetch: fetchData };
};

export default useAxios;
